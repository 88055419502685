import React, { useState } from "react";

import { Box, Link, Typography } from "@mui/material";
import ClusteredBarChart from "components/LocationInsights/ClusteredBarChart";

const SkillsRanking = ({ data }) => {

  const [showAll, setShowAll] = useState(false);

  const chartData = data && showAll ? data.map((item) => ({
    label: item.label,
    demand: +item.demand
  })) : data ? data.slice(0, 15).map((item) => ({
    label: item.label,
    demand: +item.demand
  })): [];

  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={4}>
        <Typography variant="h4" fontWeight="bold">
        Skills Ranking
        </Typography>
        {data && (
          <Link sx={{ color: "blue", cursor: "pointer", ml: 4 }} onClick={handleShowAll}>
            {showAll ? "Show less" : "Show all"}
          </Link>
        )}
      </Box>
      {!data ? (
        <Typography variant="body1">No data available</Typography>
      ) : (
        <ClusteredBarChart
          id="skill-ranking"
          labelKey="label"
          data={chartData}
          valueKey="demand"
          valueLabel="Demand"
          height={chartData.length * 30}
        />
      )}
    </Box>
  );
};

export default SkillsRanking;

import React from "react";

import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";

import BlogDetailView from "./BlogDetailView";
import { useGetBlogQuery } from "../../store/slices/blogSlice";

const BlogDetailPage = () => {
  const { blogId } = useParams();

  const { data, isLoading } = useGetBlogQuery({ id: blogId }, { skip: !blogId });

  if (isLoading) {
    return (
      <Box
        height="calc(100vh - 300px)"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <BeatLoader color={"#5C5470"} />
      </Box>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <BlogDetailView
      blog={data}
    />
  );
};

export default BlogDetailPage;

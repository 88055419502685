import React, { useMemo, useRef } from "react";

import { Alert, Box, Card, CardContent, Container, Typography } from "@mui/material";
import {
  ReactFlow,
  ConnectionLineType,
} from "@xyflow/react";
import dagre from "dagre";
import html2canvas from "html2canvas";
import arrow from "img/arrow.png";
import { useSearchParams } from "react-router-dom";
import { useGetDecisionTreeQuery } from "store/slices/decisionTreeSlice";

import { DecisionTreeNode, TrustNode } from "./DecisionTreeNode.js";
import ManagementFilter from "./ManagementFilter.js";
import { getDecisionTree } from "./nodes-edges.js";
import { convertToTitleCase } from "./utils.js";

const nodeWidth = 160;
const nodeHeight = 80;
const nodeTypes = { glint: DecisionTreeNode, trust: TrustNode };

const getLayoutedElements = (nodes, edges, direction = "TB") => {

  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? "left" : "top";
    node.sourcePosition = isHorizontal ? "right" : "bottom";

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return { nodes, edges };
};

const GlintDecisionTree = () => {
  const { data } = useGetDecisionTreeQuery();
  const [currentParams] = useSearchParams();
  const division = currentParams.get("division") || "";
  const outcome = currentParams.get("outcome") || "";
  const downloadRef = useRef(null);

  const divisionOptions = useMemo(() => {
    const availableOptions = data ? data.map(d => d.division) : [];
    return [...new Set(availableOptions)];
  }, [data]);

  const outcomeOptions = useMemo(() => {
    const availableOptions = data ? data.filter(d => d.division === division).map(d => d.outcome) : [];
    return [...new Set(availableOptions)];
  }, [data, division]);

  const decisionTreeData = useMemo(() => {
    if(division && outcome && data) {
      const treeData = data.filter(d => d.division === division && d.outcome === outcome)?.[0]?.model_output;
      if(treeData) {
        return JSON.parse(treeData);
      } else {
        return null;
      }
    } else {
      return null;
    }
  }, [data, division, outcome]);

  const layoutedElements = useMemo(() => {
    if(decisionTreeData) {
      const { nodes: initialNodes, edges: initialEdges } = getDecisionTree([decisionTreeData]);
      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
        initialNodes,
        initialEdges
      );
      return { layoutedNodes, layoutedEdges };
    } else {
      return { layoutedNodes: undefined, layoutedEdges: undefined };
    }
  }, [decisionTreeData]);

  const handleDownload = async () => {
    if(downloadRef.current) {
      const canvas = await html2canvas(downloadRef.current);

      const imgData = canvas.toDataURL("image/jpeg");
      const link = document.createElement("a");
      link.href = imgData;
      link.download = "Autodesk_Career_Goals_DT.jpg";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Box height="calc(100vh - 60px)" backgroundColor="#f6f6f6" pb={4}>
      <Container maxWidth="xl" sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <Typography variant="h1" textAlign="center" py={4} fontWeight="bold">Employee Insights Decision Trees</Typography>
        <Card sx={{ flexGrow: 1 }}>
          <CardContent sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <Box p={4}>
              <ManagementFilter filterOptions={{ outcomeOptions, divisionOptions }} onDownload={handleDownload} />
            </Box>
            <Box pl={8} mb={-12} display="flex">
              <Alert severity="success" icon={false} sx={{ textAlign: "center", width: "280px", "& .MuiAlert-message": { width: "100%" } }}>
                <Box width="100%">
                  <Typography variant="h2" fontWeight="bold" color="black" fontSize="20px" gutterBottom>{convertToTitleCase(outcome)}</Typography>
                  <Typography variant="body1">Decision Tree Analysis</Typography>
                </Box>
              </Alert>
            </Box>
            <Box ref={downloadRef} position="relative" height="100%" display="flex" flexDirection="column">
              <Box flexGrow={1}>
                {layoutedElements.layoutedNodes && layoutedElements.layoutedEdges && (
                  <ReactFlow
                    nodes={layoutedElements.layoutedNodes}
                    edges={layoutedElements.layoutedEdges}
                    connectionLineType={ConnectionLineType.SmoothStep}
                    fitView
                    nodesDraggable={false}
                    zoomOnScroll={false}
                    panOnDrag={false}
                    zoomOnPinch={false}
                    zoomOnDoubleClick={false}
                    nodeTypes={nodeTypes}
                  />
                )}
              </Box>
              {outcome && (
                <Box display="flex" justifyContent="space-between" alignItems="center" px={4}>
                  <Typography variant="body2" color="black" fontSize="20px">Low {convertToTitleCase(outcome)}</Typography>
                  <img style={{ height: "72px", width: "calc(100% - 600px)" }} src={arrow} />
                  <Typography variant="body2" color="black" fontSize="20px">High {convertToTitleCase(outcome)}</Typography>
                </Box>
              )}
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default GlintDecisionTree;

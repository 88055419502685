import React from "react";

import { Box, Typography } from "@mui/material";

import { TeamTbhJobFunctionsChart } from "./TeamTbhJobFunctions";

const LocationTbhJobFunctions = ({ data, location, team }) => {
  return (
    <Box>
      <Typography variant="h4" fontWeight="bold" mb={4}>
        {team || "Team"}-Level TBH Job Functions at {location || "Location"}
      </Typography>
      {!data ? (
        <Typography variant="body1">No data available</Typography>
      ) : (
        <TeamTbhJobFunctionsChart id="location-tbh-job-functions" data={data} />
      )}
    </Box>
  );
};

export default LocationTbhJobFunctions;

import React, { useLayoutEffect, useState, useRef } from "react";

import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import { FiberManualRecord } from "@mui/icons-material";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import dayjs from "dayjs";

import { ChartLoader } from "../../components/ChartLoader";

const FavouribilityChart = ({ data, isLoading }) => {
  const [view, setView] = useState("absolute");
  const chartRef = useRef(null);

  const chartData = Object.entries(data ?? {}).map(([date, value]) => {
    const favorability = value.favorability[0];
    return {
      year: date,
      Favorable: view === "absolute" ? favorability.Favorable : Math.round((favorability.Favorable / value.total_comments) * 100),
      Unfavorable: view === "absolute" ? favorability.Unfavorable : Math.round((favorability.Unfavorable / value.total_comments) * 100),
      Neutral: view === "absolute" ? favorability.Neutral : Math.round((favorability.Neutral / value.total_comments) * 100),
      total: value.total_comments,
    };
  }).filter((item) => item.total > 0);

  chartData.sort((a, b) => dayjs(a.year).diff(b.year));

  useLayoutEffect(() => {
    let root = null;
    if (chartData.length > 0) {
      root = am5.Root.new("chartdiv");
      root._logo.dispose();

      const theme = am5.Theme.new(root);
      theme.rule("ColorSet").setAll({
        colors: [
          am5.color("#94CBB1"),
          am5.color("#DFDFDF"),
          am5.color("#EF99B3"),
        ],
        reuse: true
      });

      root.setThemes([am5themes_Animated.new(root), theme]);

      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {})
      );

      let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
      cursor.lineY.set("visible", false);

      let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });

      xRenderer.setAll({
        cellStartLocation: 0.2,
        cellEndLocation: 0.8,
      });

      xRenderer.labels.template.setAll({
        fill: am5.color("#666666"),
        textAlign: "center",
        fontSize: 12,
      });

      let xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          maxDeviation: 0.3,
          categoryField: "year",
          renderer: xRenderer,
        })
      );

      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          maxDeviation: 0.3,
          renderer: am5xy.AxisRendererY.new(root, {}),
        })
      );

      yAxis.get("renderer").labels.template.setAll({
        fill: am5.color("#666666"),
        textAlign: "center",
        fontSize: 12,
      });

      xAxis.data.setAll(chartData);

      const makeSeries = (name, valueYField) => {
        const series = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            name,
            xAxis,
            yAxis,
            valueYField,
            categoryXField: "year",
          })
        );

        series.columns.template.setAll({
          tooltipText: "{name}: {valueY}",
          width: am5.percent(100),
          cornerRadiusTL: 5,
          cornerRadiusTR: 5
        });

        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationX: 0.5,
            locationY: 1.01,
            sprite: am5.Label.new(root, {
              centerX: am5.p50,
              centerY: am5.p50,
              text: "{valueY}",
              fill: am5.color("#666666"),
              fontSize: 12,
              populateText: true
            })
          });
        });

        series.data.setAll(chartData);
      };
    
      makeSeries("Favorable", "Favorable");
      makeSeries("Unfavorable", "Unfavorable");
      makeSeries("Neutral", "Neutral");

      xAxis.get("renderer").labels.template.adapters.add("text", function (text, target) {
        let dataItem = target.dataItem;
        if (dataItem) {
          let dataContext = dataItem.dataContext;
          return dayjs(dataContext.year).format("MMM 'YY") + "\n" + dataContext.total + " comments";
        }
        return text;
      });

      chartRef.current = chart;
    }

    return () => {
      root?.dispose();
    };
    
  }, [view, chartData.length]);

  if (isLoading && chartData.length === 0) {
    return <ChartLoader />;
  }

  if (!isLoading && chartData.length === 0) {
    return null;
  }

  return <div>
    <div className="flex justify-between items-center">
      <h2 className="text-xl font-extrabold">Favorability trend</h2>
      <ToggleButtonGroup value={view} exclusive size="small">
        <ToggleButton value="absolute" onClick={() => setView("absolute")}>Absolute</ToggleButton>
        <ToggleButton value="percentage" onClick={() => setView("percentage")}>Percentage</ToggleButton>
      </ToggleButtonGroup>
    </div>
    <div className="flex justify-between items-center mt-2">
      <div className="flex items-center">
        <FiberManualRecord sx={{ color: "#94CBB1" }} />
        <p className="text-[#666666] text-xs mr-6">Favorable</p>
        <FiberManualRecord sx={{ color: "#DFDFDF" }} />
        <p className="text-[#666666] text-xs mr-6">Neutral</p>
        <FiberManualRecord sx={{ color: "#EF99B3" }} />
        <p className="text-[#666666] text-xs">Unfavorable</p>
      </div>
      <p className="text-[#666666] text-xs">Excluding ratings from “what-else” question</p>
    </div>
    <div id="chartdiv" style={{ width: "80%", height: "500px", marginTop: "40px", marginLeft: "auto", marginRight: "auto" }}></div>
  </div>;
};

export default FavouribilityChart;

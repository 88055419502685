import React, { useEffect } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Grid, Link, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useLazyGetLocationInsightsQuery } from "store/slices/locationSlice";

import AverageTenure from "./AverageTenure";
import LeadershipDepth from "./LeadershipDepth";
import LeadershipDepthCount from "./LeadershipDepthCount";
import LeadershipDepthLocation from "./LeadershipDepthLocation";
import SiteLeader from "./SiteLeader";
import Question from "../../../components/Question";
import { getLocationNameFromQueryParams } from "../../../utils";

const LeadershipDepthDetail = () => {
  const [currentParams] = useSearchParams();
  const [getLocationDetail] = useLazyGetLocationInsightsQuery();
  const { data, isLoading } = useSelector((state) => state.location);
  const locationName = getLocationNameFromQueryParams(currentParams);

  useEffect(() => {
    const params = Object.fromEntries(currentParams.entries());
    getLocationDetail({ params });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentParams]);

  const siteLeader = data?.metrics?.find((item) => item.metric_name === "site_leader");
  const leadershipDepth = data?.metrics?.find((item) => item.metric_name === "leadership_depth");
  const leadershipDepthTeam = data?.adsk_location_metrics?.find((item) => item.metric_name === "headcount_mgmt_level");
  const leadershipDepthLocation = data?.metrics?.find((item) => item.metric_name === "headcount_mgmt_level");
  const leadershipCounts = data?.metrics?.find((item) => item.metric_name === "div_leadership_distribution");
  const leadershipDepthRatios = data?.metrics?.find((item) => item.metric_name === "leadership_depth_ratios");
  const averageTenureTeam = data?.adsk_location_metrics?.find((item) => item.metric_name === "leadership_avg_tenure");
  const averageTenureLocation = data?.metrics?.find((item) => item.metric_name === "leadership_avg_tenure");

  if(isLoading) {
    return (
      <Box className="flex items-center justify-center w-full">
        <BeatLoader color="#5C5470" />
      </Box>
    );
  }

  return (
    <Box>
      <Question text="What is this location's leadership depth?" />
      <Box display="flex" alignItems="center" mb={8} mt={2}>
        <InfoOutlinedIcon sx={{ mr: 1, color:"#353535" }} />
        <Typography variant="body1" color="#353535">
          The Site Leader information in this tool is reviewed quarterly. Please see the <Link target="_blank" sx={{ color: "blue", fontWeight: "bold" }} href="https://share.autodesk.com/sites/WPT/SitePages/Office-Site-Leaders.aspx">WPT Sharepoint site</Link> for the latest information. 
        </Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item lg={6} xs={12} display="flex" alignItems="center">
          <LeadershipDepth leadershipDepthValue={leadershipDepth?.value} leadershipDepthRatios={leadershipDepthRatios?.value} />
        </Grid>
        <Grid item lg={6} xs={12}>
          <SiteLeader data={siteLeader?.value} />
        </Grid>
        <Grid item lg={6} xs={12}>
          {leadershipDepthTeam && leadershipDepthLocation && (
            <LeadershipDepthLocation teamValue={leadershipDepthTeam?.value} locationValue={leadershipDepthLocation?.value} locationName={locationName}/>
          )}
        </Grid>
        <Grid item lg={6} xs={12}>
          <AverageTenure team={averageTenureTeam?.value} location={averageTenureLocation?.value} />
        </Grid>
        <Grid item lg={12} xs={12}>
          {leadershipCounts && (
            <LeadershipDepthCount locationValue={leadershipCounts.value} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default LeadershipDepthDetail;

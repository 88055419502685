import React, { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Box, Typography } from "@mui/material";
import { ColorTheme2 } from "theme/chart";

const PostingDuration = ({ data }) => {
  if (!data || !data.selectedposition || !data.allpositions) {
    return null;  
  }

  const chartData = Object.entries(data.selectedposition).map(([key, item]) => {
    const allPositionsItem = data.allpositions[key] || {};
    return {
      label: key,
      selectedPosition: +item.ratio || 0,
      selectedPositionCount: +item.count || 0,
      allPositions: +allPositionsItem.ratio || 0,
      allPositionsCount: +allPositionsItem.count || 0
    };
  }).sort((a, b) => a.label.split(" ")[0] - b.label.split(" ")[0]); 

  return (
    <Box>
      <Typography variant="h4" fontWeight="bold" my={4}>
        Posting Duration
      </Typography>
      {!data ? (
        <Typography variant="body1">No data available</Typography>
      ) : (
        <PostingDurationChart
          id="posting-duration"
          data={chartData}
        />
      )}
    </Box>
  );
};

export default PostingDuration;


const PostingDurationChart = ({ data, id, height="400px" }) => {

  // This code will only run one time
  useLayoutEffect(() => {
    const root = am5.Root.new(id);

    root.setThemes([ColorTheme2.new(root)]);
    root._logo.dispose();

    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      paddingLeft:0,
      layout: root.verticalLayout
    }));
    
    
    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    let legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50
    }));
    
    
    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "label",
      renderer: am5xy.AxisRendererY.new(root, {
        inversed: true,
        cellStartLocation: 0.1,
        cellEndLocation: 0.9,
        minorGridEnabled: true,
      })
    }));
    
    yAxis.data.setAll(data);
    
    let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererX.new(root, {
        strokeOpacity: 0.1,
        minGridDistance: 50,
      }),
      numberFormat: "#'%'",
      extraMax: 0.1,
      min: 0
    }));

    // Set padding to control the spacing between bars
    xAxis.get("renderer").setAll({
      paddingInner: 0.05, // Reduces space between bars in the same category
      paddingOuter: 0.1, // Controls the space outside the bars
    });
    
    
    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function createSeries(field, name, countLabel, index) {
      let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: field,
        categoryYField: "label",
        sequencedInterpolation: true,
        tooltip: am5.Tooltip.new(root, {
          labelText: `{name}: {valueX}%\nCount: {${countLabel}}`
        }),
      }));
    
      series.columns.template.setAll({
        height: am5.p50,
        dy: index * 8,
      });
    
    
      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationX: 1,
          locationY: 0.5,
          sprite: am5.Label.new(root, {
            centerY: am5.p50,
            text: "{valueX}%",
            populateText: true
          })
        });
      });
    
      series.data.setAll(data);
    
      return series;
    }
    
    createSeries("allPositions", "All Positions", "allPositionsCount", 1);
    createSeries("selectedPosition", "Selected Position", "selectedPositionCount", -1);
    
    
    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    chart.children.push(am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50
    }));
    
    legend.data.setAll(chart.series.values);
    
    // Add cursor
    chart.set("cursor", am5xy.XYCursor.new(root, {}));

    return () => {
      root.dispose();
    };
  }, [data]);

  return <div id={id} style={{ width: "100%", height }}></div>;
};

import * as React from "react";

import { Box, Tab, Tabs, ToggleButtonGroup, ToggleButton, Divider } from "@mui/material";
import Footer from "components/Footer/Footer";
import Header from "components/Header/Header";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import useMenus from "./useMenus";

const NavBar = () => {
  const items = useMenus();
  const navigate = useNavigate();
  const activeMenu = items.find(item => item.isActive);
  const activeChild = activeMenu?.children ? activeMenu.children.find(child => child.isActive) : null;
  return <Box className="px-8">
    <Tabs value={activeMenu?.to}>
      {
        items.map(item => {
          return <Tab key={item.title} value={item.to} label={item.title} onClick={() => navigate(item.to)}/>;
        })
      }
    </Tabs>

    <Divider />

    {
      activeMenu?.children?.length > 0 ? <ToggleButtonGroup value={activeChild?.to || activeMenu?.to} className="mt-6">
        <ToggleButton key={activeMenu.title} size="medium" value={activeMenu.to} onClick={() => navigate(activeMenu.to)}>Summary</ToggleButton>
        {
          activeMenu.children.map(child => {
            return <ToggleButton key={child.title} size="medium" value={child.to} onClick={() => navigate(child.to)}>
              {child.title}
            </ToggleButton>;
          })
        }
      </ToggleButtonGroup> : null
    }
  </Box>;
};


const LocationInsightsLandingPage = () => {
  const { pathname } = useLocation();
  const containerRef = React.useRef(null);

  React.useEffect(() => {
    if (containerRef.current) containerRef.current.scrollTop = 0;
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Box>
      <Header pageName="Location Insights" />
      <Box className="flex flex-col">
        <NavBar />
        <Box height="calc(100vh - 56px)" overflow="auto" ref={containerRef}>
          <Outlet />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

export default LocationInsightsLandingPage;


import { Box, Card, Typography } from "@mui/material";
import { Handle, Position } from "@xyflow/react";

export const SourceNode = ({ data }) => {
  return (
    <>
      <Handle type="source" style={{ visibility: "hidden" }} position={Position.Right} />
      <Card>
        <Box sx={{ backgroundColor: data.color, color: "white", height: "80px", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Typography variant="body2" textAlign="center" fontSize="20px">{`${data.label} - `} <b>{data.value}</b></Typography>
        </Box>
      </Card>
    </>
  );
};

export const TargetNode = ({ data }) => {
  return (
    <>
      <Handle type="target" style={{ visibility: "hidden" }} position={Position.Left} />
      <Card>
        <Box sx={{ backgroundColor: data.color, color: "white", height: "80px", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Typography variant="body2" textAlign="center" fontSize="20px">{`${data.label} - `} <b>{data.value}</b></Typography>
        </Box>
      </Card>
    </>
  );
};

export const HeaderNode = ({ data }) => {
  return (
    <Box sx={{ backgroundColor: "white", color: "black", height: "80px", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Typography variant="h3" textAlign="center">{data.label}</Typography>
    </Box>
  );
};

import React, { useEffect } from "react";

import { Box, Grid, Typography } from "@mui/material";
import Information from "components/LocationInsights/Information";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useLazyGetExternalLocationInsightsQuery } from "store/slices/locationSlice";

import EducationRanking from "./EducationRanking";
import ExperienceRanking from "./ExpereinceRanking";
import SkillsRanking from "./SkillsRanking";
import TitlesRanking from "./TitlesRanking";
import Question from "../../../components/Question";

const RoleAnalysisDetail = () => {
  const [currentParams] = useSearchParams();
  const [getLocationDetail] = useLazyGetExternalLocationInsightsQuery();
  const { externalData, isLoading } = useSelector((state) => state.location);

  useEffect(() => {
    const params = Object.fromEntries(currentParams.entries());
    getLocationDetail({ params });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentParams]);

  if(isLoading) {
    return (
      <Box className="flex items-center justify-center w-full">
        <BeatLoader color="#5C5470" />
      </Box>
    );
  }

  if(!externalData || externalData.length !== 1) {
    return (
      <Box mt={20}>
        <Typography variant="body1" textAlign="center" fontWeight="bold" fontSize="24px">
          No data available. Try selecting or changing the location and job title filters
        </Typography>
      </Box>
    );
  } 

  const data = externalData[0];
  return (
    <Box>
      <Question text="What qualifications are in demand in this location?" />
      <Information>
        This page shows the skills, job titles, education and experience that are most in-demand for this role, at this location.
      </Information>
      <Grid container spacing={4} mt={2}>
        <Grid item lg={6}>
          <EducationRanking data={data.details_education_ranking} />
        </Grid>
        <Grid item lg={6}>
          <ExperienceRanking data={data.details_experience_ranking} />
        </Grid>
        <Grid item lg={6}>
          <TitlesRanking data={data.details_titles_ranking} />
        </Grid>
        <Grid item lg={6}>
          <SkillsRanking data={data.details_skills_ranking} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default RoleAnalysisDetail;

import React, { useLayoutEffect, useRef } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { ColorTheme1 } from "theme/chart";

const AttritionOverview = ({ data }) => {
  const chartRef = useRef(null);

  useLayoutEffect(() => {
    const root = am5.Root.new(chartRef.current);

    root.setThemes([ColorTheme1.new(root)]);
    root._logo.dispose();

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        layout: root.verticalLayout
      })
    );

    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 30
        })
      })
    );
    xAxis.data.setAll(data);

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        extraMax: 0.03,
        renderer: am5xy.AxisRendererY.new(root, {
        }),
        extraMin: 0.1
      })
    );

    // Hide X-axis grid lines
    xAxis.get("renderer").grid.template.set("visible", false);
    // Hide X-axis tick labels
    xAxis.get("renderer").labels.template.set("visible", false);

    // Hide Y-axis grid lines
    yAxis.get("renderer").grid.template.set("visible", false);
    // Hide Y-axis tick labels
    yAxis.get("renderer").labels.template.set("visible", false);

    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "Series 1",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        categoryXField: "category",
      })
    );

    series.strokes.template.setAll({
      strokeWidth: 3
    });

    // Add circle bullets for data points
    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationX: 0.5,
        sprite: am5.Circle.new(root, {
          radius: 5,
          fill: series.get("fill"),
          stroke: root.interfaceColors.get("background"),
          strokeWidth: 2,
        })
      });
    });

    series.bullets.push(function() {
      return am5.Bullet.new(root, {
        locationX: 0.5,
        locationY: 0.5,
        sprite: am5.Label.new(root, {
          text: "{value.formatNumber('#.0')}%\n({category})",
          centerX: am5.percent(50),
          centerY: am5.percent(0),
          textAlign: "center",
          fontSize: 12,
          populateText: true
        })
      });
    });

    series.data.setAll(data);

    return () => {
      root.dispose();
    };
  }, [data]);

  return <div id="chartdiv" ref={chartRef} style={{ width: "100%", height: "400px" }}></div>;
};

export default AttritionOverview;

import React, { useEffect, useLayoutEffect, useState } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import Information from "components/LocationInsights/Information";
import dayjs from "dayjs";
import { BeatLoader } from "react-spinners";
import { useGetTimezoneDistributionMutation } from "store/slices/locationSlice";
import { ColorTheme10 } from "theme/chart";

const DEFAULT_TIMEZONE = "Pacific Daylight Time";
const START_TIME = "2024-11-14 09:00";
const END_TIME = "2024-11-14 18:00";

const addEntry = (result, category, fromDate, toDate, details, color, isBefore, main = false, fillOpacity = 1) => {
  result.push({
    category: category,
    fromDate: fromDate,
    toDate: toDate,
    total_headcount: details.total_headcount,
    regular_headcount: details.regular_headcount,
    employee_headcount: details.employee_headcount,
    total_minus_osp_assoc_headcount: details.total_minus_osp_assoc_headcount,
    main: main,
    date: main ? dayjs(details.work_hours.start).format("MMMM D, YYYY") : undefined,
    columnSettings: {
      //fill: isBefore ? am5.color(0x2f8b2f) : color,
      fillOpacity: fillOpacity
    }
  });
};

const convertData = (data, color) => {
  let result = [];

  Object.keys(data).forEach((timezone) => {
    const details = data[timezone];
    const category = timezone;
    //hardcoded value
    const isBefore = dayjs(details.work_hours.start).date() === 16;

    // Add main work hours
    addEntry(result, category, details.work_hours.start, details.work_hours.end, details, color, isBefore, true);

    // Pre and Post work hours
    addEntry(result, category, details.extended_work_hours.pre_work_hours, details.work_hours.start, details, color, isBefore, false, 0.6);
    addEntry(result, category, details.work_hours.end, details.extended_work_hours.post_work_hours, details, color, isBefore, false, 0.6);

    // Add next/previous day shift logic
    const dayShift = isBefore ? 1 : -1;

    addEntry(result, category, dayjs(details.work_hours.start).add(dayShift, "day").toDate(), dayjs(details.work_hours.end).add(dayShift, "day").toDate(), details, color, !isBefore, true);
    addEntry(result, category, dayjs(details.extended_work_hours.pre_work_hours).add(dayShift, "day").toDate(), dayjs(details.work_hours.start).add(dayShift, "day").toDate(), details, color, !isBefore, false, 0.6);
    addEntry(result, category, dayjs(details.work_hours.end).add(dayShift, "day").toDate(), dayjs(details.extended_work_hours.post_work_hours).add(dayShift, "day").toDate(), details, color, !isBefore, false, 0.6);
  });

  return result;
};

const getGlobalOverlapHours = (data) => {
  let globalPreWorkStart = null;
  let globalPostWorkEnd = null;

  Object.keys(data).forEach((timezone) => {
    const details = data[timezone];

    const preWorkStart = dayjs(details.extended_work_hours.pre_work_hours);
    const postWorkEnd = dayjs(details.extended_work_hours.post_work_hours);

    // Update global pre-work start time
    if (globalPreWorkStart === null || preWorkStart.isAfter(globalPreWorkStart)) {
      globalPreWorkStart = preWorkStart;
    }

    // Update global post-work end time
    if (globalPostWorkEnd === null || postWorkEnd.isBefore(globalPostWorkEnd)) {
      globalPostWorkEnd = postWorkEnd;
    }
  });

  // Check if there's an actual overlap
  if (globalPreWorkStart.isBefore(globalPostWorkEnd)) {
    return {
      fromDate: globalPreWorkStart.format("YYYY-MM-DD HH:mm:ss"),
      toDate: globalPostWorkEnd.format("YYYY-MM-DD HH:mm:ss")
    };
  } else {
    return null; // No overlapping hours
  }
};

const TimezoneDistribution = ({ timezoneDistribution, baseTimezoneOptions }) => {
  const [baseTimezone, setBaseTimezone] = useState(DEFAULT_TIMEZONE);
  const [selectedTimezoneData, setSelectedTimezoneData] = useState(timezoneDistribution);
  const [getTimezoneDistribution] = useGetTimezoneDistributionMutation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const data = await getTimezoneDistribution({ baseTimezone, data: timezoneDistribution });
        if(data?.data) {
          setSelectedTimezoneData(data.data);
        }
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
  
    if (baseTimezone !== DEFAULT_TIMEZONE) {
      getData();
    } else {
      setSelectedTimezoneData(timezoneDistribution);
    }
  }, [baseTimezone, getTimezoneDistribution, timezoneDistribution]);

  return (
    <>
      <Information>
        <Typography variant="body1">
            Please select a base timezone to view the distribution across different timezones.
        </Typography>
      </Information>
      <Grid container>
        <Grid item xs={12} md={4}>
          <Autocomplete
            id="baseline-timezone"
            value={baseTimezone}
            onChange={(event, newValue) => {
              setBaseTimezone(newValue);
            }}
            options={baseTimezoneOptions}
            renderInput={(params) => (
              <TextField {...params} label="Baseline Timezone" variant="outlined" size="small" />
            )}
            disableClearable
          />
        </Grid>
      </Grid>
      <>
        {isLoading ? (
          <Box display="flex" height="400px" alignItems="center" justifyContent="center">
            <BeatLoader color="#5C5470" />
          </Box>
        ) : (
          <TimezoneDistributionChart data={selectedTimezoneData.value} baseTimezone={baseTimezone} />
        )}
      </>
    </>
  );
};

export default TimezoneDistribution;

const TimezoneDistributionChart = ({ data, baseTimezone }) => {

  const overlap = getGlobalOverlapHours(data);

  useLayoutEffect(() => {
    const root = am5.Root.new("timezone-distribution");


    root.setThemes([ColorTheme10.new(root)]);
    root._logo.dispose();

    root.dateFormatter.setAll({
      dateFormat: "HH:mm",
      dateFields: ["valueX", "openValueX"]
    });

    
    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: false,
      wheelY: false,
      layout: root.verticalLayout,
      paddingLeft: 0,
      paddingBottom: 100
    }));


    chart.set("cursor", am5xy.XYCursor.new(root, {}));
    const colors= chart.get("colors");

    const yRenderer = am5xy.AxisRendererY.new(root, {
      inversed: true,
      minorGridEnabled: true,
      // minGridDistance: 10,
    });
    yRenderer.labels.template.setAll({
      fontSize: 14
    });
    
    let yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(root, {
          themeTags: ["axis"],
          animationDuration: 200
        })
      })
    );

    const tempData = Object.keys(data).sort((key1, key2) => Number(data[key2].total_minus_osp_assoc_headcount) - Number(data[key1].total_minus_osp_assoc_headcount));
    
    yAxis.data.setAll(tempData.map((item) => ({ category: item })));
    
    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: "minute", count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {
          minorGridEnabled: false,
          minGridDistance: 60,
          strokeOpacity: 0.1,  
        }),
        tooltip: am5.Tooltip.new(root, {
          themeTags: ["axis"],
          animationDuration: 200
        }),
        periodChangeDateFormats: {
          hour: "HH:mm",
          minute: "HH:mm",
          
        },
        dateFormats: {
          hour: "HH:mm", 
          minute: "HH:mm",
        },
        //hardcoded value
        min: dayjs("2024-11-14 00:00").toDate().getTime(), // Set minimum value for the x-axis
        max: dayjs("2024-11-15 00:00").toDate().getTime(), // Set maximum value for the x-axis
      })
    );

    // Add label on the left side of X axis
    xAxis.get("renderer").labels.template.setAll({
      centerX: am5.p100, // Align to the left of the axis
      // x: -50 // Adjust the distance of the label from the axis
    });

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series = chart.series.push(am5xy.ColumnSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      openValueXField: "fromDate",
      valueXField: "toDate",
      categoryYField: "category",
      sequencedInterpolation: true
    }));
        
    series.data.processor = am5.DataProcessor.new(root, {
      dateFields: ["fromDate", "toDate"],
      dateFormat: "yyyy-MM-dd HH:mm"
    });

    series.columns.template.setAll({
      templateField: "columnSettings",
      strokeOpacity: 0,
      tooltipText: 
        " Total Headcount: {total_headcount}\n Regular Headcount: {regular_headcount}\n Employee Headcount: {employee_headcount}\n Total Minus OSP Assoc Headcount: {total_minus_osp_assoc_headcount}",
    });

    const chartData = convertData(data, colors.getIndex(0));
    series.data.setAll(chartData);
    chart.plotContainer.children.push(am5.Label.new(root, {
      text: baseTimezone,
      x: am5.percent(50),
      centerX: am5.percent(50),
      y: am5.percent(100),
      dy: 80,
      fontWeight: "bold"
    }));

    /** start and end time bar */
    let startRangeDataItem = xAxis.makeDataItem({
      value: dayjs(START_TIME).toDate().getTime(),
    });
    xAxis.createAxisRange(startRangeDataItem);
    startRangeDataItem.get("grid").setAll({
      strokeOpacity: 1,
      stroke: am5.color(0x000000),
      strokeWidth: 3,
      strokeDasharray: [10, 10],
      layer: 10
    });

    startRangeDataItem.get("tick").setAll({
      stroke: am5.color(0x000000),
      strokeOpacity: 1,
      strokeWidth: 3,
      location: 1,
      visible: true,
      strokeDasharray: [10, 10],
      layer: 0,
      length: 60
    });

    startRangeDataItem.get("label").setAll({
      fill: am5.color(0x000000),
      text: "Start Time(9am)",
      location: 1,
      dy: 30
    });

    let endRangeDataItem = xAxis.makeDataItem({
      value: dayjs(END_TIME).toDate().getTime(),
    });
    xAxis.createAxisRange(endRangeDataItem);
    endRangeDataItem.get("grid").setAll({
      strokeOpacity: 1,
      stroke: am5.color(0x000000),
      strokeDasharray: [10, 10],
      strokeWidth: 3,
      layer: 10
    });

    endRangeDataItem.get("tick").setAll({
      stroke: am5.color(0x000000),
      strokeDasharray: [10, 10],
      strokeOpacity: 1,
      strokeWidth: 3,
      location: 1,
      visible: true,
      layer: 0,
      length: 60
    });

    endRangeDataItem.get("label").setAll({
      fill: am5.color(0x000000),
      text: "End Time(6pm)",
      location: 1,
      dy: 30
    });
    /** -------------- */

    /** overlapping style */

    if(overlap) {
      const rangeDataItem = xAxis.makeDataItem({
        value: dayjs(overlap.fromDate).toDate().getTime(),
        endValue: dayjs(overlap.toDate).toDate().getTime()
      });
      const range = series.createAxisRange(rangeDataItem);
  
      range.columns.template.setAll({
        fill: am5.color(0xff621f),
        stroke: am5.color(0xff621f),
        fillOpacity: 1,
        // layer: 9
      });
      
      rangeDataItem.get("axisFill").setAll({
        fill: am5.color(0xff621f),
        stroke: am5.color(0xff621f),
        fillOpacity: 0.2,
        visible: true,
        // layer: 11
      });
    } 

    /** ------------- */

    let legend = chart.children.push(am5.Legend.new(root, {
      nameField: "name",
      fillField: "color",
      strokeField: "color",
      centerX: am5.percent(50),
      x: am5.percent(50),
      y: am5.percent(100),
      dy: 50,
    }));
    
    legend.data.setAll([{
      name: "Regular work hours (9am-6pm)",
      color: colors.getIndex(0),
    }, {
      name: "Extended work hours (7am-9am & 6pm-9pm)",
      color: colors.getIndex(0).toCSS(0.6),
    }]);

    return () => {
      root.dispose();
    };
  }, [data, overlap, baseTimezone]);

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" mt={4} pr={2}>
        <Box display="flex">
          <InfoOutlinedIcon sx={{ mr: 1, color:"#353535", fontSize: 20 }} />
          <Box>
            <Typography variant="body1" color="#353535">
            These time zone overlaps may change due to Daylight Savings Time
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <AccessTimeOutlinedIcon sx={{ mr: 1, color:"#353535", fontSize: 20 }}/>
          {overlap 
            ? <Typography variant="body1" color="#353535" textAlign="right">The overlap hours are from <b>{dayjs(overlap.fromDate).format("h:mm A")}</b> to <b>{dayjs(overlap.toDate).format("h:mm A")} {baseTimezone}</b> across all timezones.</Typography> 
            : <Typography variant="body1" color="#353535" textAlign="right">There are no overlapping hours across all timezones.</Typography>}
        </Box>
      </Box>
      <div id="timezone-distribution" style={{ width: "100%", height: `${Object.keys(data).length * 50+ 200}px` }}></div>
    </Box>
  );
};


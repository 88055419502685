import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Box, IconButton } from "@mui/material";
// import RichTextFormField from "components/FormComponents/RichTextFormField";
import SelectFormField from "components/FormComponents/SelectFormField";
import TextFormField from "components/FormComponents/TextFormField";
import { useForm } from "react-hook-form";

const UpcomingResearchEditor = ({ researchTypeFilter, upcomingResearch, onClose, open, onCreate, onUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, control, formState: { errors, isValid } } = useForm({
    values: { ...upcomingResearch },
    mode: "all"
  });

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      if(upcomingResearch) {
        await onUpdate({
          id: upcomingResearch.id,
          data: values
        });
      } else {
        await onCreate({
          data: values
        });
      }
      onClose();
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog 
      open={open}
      onClose={onClose}
      maxWidth="lg"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Upcoming Research Editor</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box py={4}>
            <Grid container spacing={6}>
              <Grid item md={6}>
                <TextFormField
                  name="research"
                  label="Research"
                  type="text"
                  fullWidth
                  control={control}
                  error={errors.research}
                  rules={{
                    required: "Research is required"
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <TextFormField
                  name="lead"
                  label="Lead"
                  type="text"
                  fullWidth
                  control={control}
                  error={errors.lead}
                  rules={{
                    required: "Lead is required"
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <SelectFormField
                  name="research_type"
                  label="Research Type"
                  fullWidth
                  control={control}
                  options={researchTypeFilter}
                  error={errors.research_type}
                  rules={{
                    required: "Research type is required"
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <TextFormField
                  name="timeline"
                  label="Timeline"
                  type="text"
                  fullWidth
                  control={control}
                  error={errors.timeline}
                  rules={{
                    required: "Timeline is required"
                  }}
                />
              </Grid>

              <Grid item md={12}>
                <TextFormField
                  name="description"
                  label="Description"
                  type="text"
                  fullWidth
                  control={control}
                  error={errors.description}
                  multiline
                  minRows={10}
                  rules={{
                    required: "Description is required"
                  }}
                />
              </Grid>
              {/* <Grid item md={12}>
                <RichTextFormField 
                  name="description"
                  label="Description"
                  control={control}
                  error={errors.content}
                />
              </Grid> */}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3 }}>
          <Button type="button" onClick={onClose}>Cancel</Button>
          <LoadingButton color="primary" variant="contained" type="submit" disabled={!isValid} loading={isLoading}>
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpcomingResearchEditor;

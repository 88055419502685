import React from "react";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Box, Button } from "@mui/material";
import TableauViz from "components/TableauViz";
import { useNavigate, useSearchParams } from "react-router-dom";

const Tableau = () => {
  const [currentParams] = useSearchParams();
  const url = currentParams.get("url");
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box bgcolor="#f6f6f6">
      <Button sx={{ mx:4, my: 4 }} size="small" onClick={handleBack} startIcon={<KeyboardBackspaceIcon />}>Back</Button>
      {url && (
        <TableauViz val={url} />
      )}
    </Box>
  );
};

export default Tableau;

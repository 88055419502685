import React, { useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useSearchParams } from "react-router-dom";

const OrgSelectOptions = ({ data }) => {
  const [division, setDivision] = useState("");
  const [l2org, setL2org] = useState("all");
  const [l3org, setL3org] = useState("all");
  const [l4org, setL4org] = useState("all");
  const [currentParams, setCurrentParams] = useSearchParams();

  const divisionParam = currentParams.get("division");
  const l2orgParam = currentParams.get("l2_org");
  const l3orgParam = currentParams.get("l3_org");
  const l4orgParam = currentParams.get("l4_org");

  useEffect(() => {
    if (divisionParam) {
      setDivision(divisionParam);
    }
    if (l2orgParam) {
      setL2org(l2orgParam);
    }
    if (l3orgParam) {
      setL3org(l3orgParam);
    }
    if (l4orgParam) {
      setL4org(l4orgParam);
    }
  }, [divisionParam, l2orgParam, l3orgParam, l4orgParam]);

  const handleChange = (event) => {
    currentParams.set("division", event.target.value);
    currentParams.set("l2_org", "all");
    currentParams.set("l3_org", "all");
    currentParams.set("l4_org", "all");
    setCurrentParams(currentParams);
    setDivision(event.target.value);
    setL2org("all");
  };

  const handleChangeL2org = (event) => {
    currentParams.set("l2_org", event.target.value);
    currentParams.set("l3_org", "all");
    currentParams.set("l4_org", "all");
    setCurrentParams(currentParams);
    setL2org(event.target.value);
  };

  const handleChangeL3org = (event) => {
    currentParams.set("l3_org", event.target.value);
    currentParams.set("l4_org", "all");
    setCurrentParams(currentParams);
    setL3org(event.target.value);
  };

  const handleChangeL4org = (event) => {
    if(event.target.value === "all") {
      currentParams.set("l4_org", "all");
    } else {
      currentParams.set("l4_org", event.target.value);
    }
    setCurrentParams(currentParams);
    setL4org(event.target.value);
  };
  

  const l2Options = data?.["l2 orgs"]?.[division] || [];
  const l3Options = data?.["l3 orgs"]?.[l2org] || [];
  const l4Options = data?.["l4 orgs"]?.[l3org] || [];

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h6" fontWeight={800} marginBottom={4}>
        Division and organisation levels
      </Typography>
      <FormControl sx={{ maxWidth: "400px", marginBottom: 2 }}>
        <InputLabel id="demo-simple-select-label">Division</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={division}
          label="Division"
          onChange={handleChange}
        >
          {data.division.map(item => (
            <MenuItem key={item} value={item}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ maxWidth: "400px", marginBottom: 2 }}>
        <InputLabel id="demo-simple-select-label">L2 Organization</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={l2org}
          disabled={l2Options.length === 0}
          label="L2 Organization"
          onChange={handleChangeL2org}
          defaultValue="all"
        >
          <MenuItem value="all">All</MenuItem>
          {l2Options.map(item => (
            <MenuItem key={item} value={item}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ maxWidth: "400px", marginBottom: 2 }}>
        <InputLabel id="demo-simple-select-label">L3 Organization</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={l3org}
          label="L3 Organization"
          onChange={handleChangeL3org}
          disabled={l3Options.length === 0}
          defaultValue="all"
        >
          <MenuItem value="all">All</MenuItem>
          {l3Options.map(item => (
            <MenuItem key={item} value={item}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ maxWidth: "400px" }}>
        <InputLabel id="demo-simple-select-label">L4 Organization</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={l4org}
          label="L4 Organization"
          disabled={l4Options.length === 0}
          onChange={handleChangeL4org}
          defaultValue="all"
        >
          <MenuItem value="all">All</MenuItem>
          {l4Options.map(item => (
            <MenuItem key={item} value={item}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default OrgSelectOptions;

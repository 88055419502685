import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

import { insightApi } from "../../apis/api";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const userApi = insightApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: () => ({
        url: "/user-admin",
        params: {
          token: Cookies.get("storedAccessToken")
        },
      }),
      providesTags: ["Users"]
    }),
    getUser: builder.query({
      query: ({ id }) => ({
        url: "/user-admin",
        params: {
          token: Cookies.get("storedAccessToken"),
          id
        },
      }),
    }),
    postUser: builder.mutation({
      query: ({ data }) => ({
        url: "/user-admin",
        params: {
          token: Cookies.get("storedAccessToken"),
        },
        data,
        method: "POST"
      }),
      invalidatesTags: ["Users"]
    }),
    updateUser: builder.mutation({
      query: ({ data, email_address }) => ({
        url: "/user-admin",
        params: {
          token: Cookies.get("storedAccessToken"),
          email_address
        },
        data,
        method: "PUT"
      }),
      invalidatesTags: ["Users"]
    }),
    deleteUser: builder.mutation({
      query: ({ id }) => ({
        url: "/user-admin",
        params: {
          token: Cookies.get("storedAccessToken"),
          id,
        },
        method: "DELETE"
      }),
      invalidatesTags: ["Users"]
    }),
  }),
});

export const userSlice = createSlice({
  name: "user",
  initialState,
});

export const { 
  useUpdateUserMutation, 
  usePostUserMutation, 
  useDeleteUserMutation,
  useGetAllUsersQuery,
  useGetUserQuery,
  useLazyGetUserQuery
} = userApi;

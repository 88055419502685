import { useState } from "react";

import { Close, Search } from "@mui/icons-material";
import { Drawer, FormControlLabel, IconButton, InputAdornment, Pagination, Switch, TextField } from "@mui/material";
import { CustomChip } from "components/CustomChip";
import { useDebounceValue } from "usehooks-ts";
import { getPageCount, getCurrentPageData } from "utils/pagination";

import Comment from "./Comment";
import DownloadExcel from "../../components/DownloadExcel";
import { getCommentsDownloadData } from "../../utils";

const favorabilityOptions = ["All", "Favorable", "Unfavorable", "Neutral", "N/A"];

const ITEM_PER_PAGE = 20;

export const CommentDrawerWrapper = ({ comments, theme, component, favouriteComments = [], allComments = [] }) => {
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [commentFilters, setCommentFilters] = useState({
    favoribilty: "All",
    bookmarks: false,
  });
  const [searchQuery, setSearchQuery] = useDebounceValue("", 500);
  const drawerComments = comments;

  const favorabilityCounts = comments.reduce((acc, comment) => {
    if (["Favorable", "Unfavorable", "Neutral"].includes(comment.favorability)) {
      acc[comment.favorability] = (acc[comment.favorability] || 0) + 1;
    } else {
      acc["N/A"] = (acc["N/A"] || 0) + 1;
    }
    return acc;
  }, {});

  favorabilityCounts["All"] = comments.length;

  const filteredComments = drawerComments.filter((comment) => {
    return (commentFilters.favoribilty === "All" || (commentFilters.favoribilty === "N/A" && !["Favorable", "Unfavorable", "Neutral"].includes(comment.favorability)) || comment.favorability === commentFilters.favoribilty) 
        && (!commentFilters.bookmarks || comment.bookmarked) 
        && (comment.comment.includes(searchQuery));
  });

  const pageComments = getCurrentPageData(filteredComments, ITEM_PER_PAGE, page);

  const handleDrawerClose = (e, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      setOpen(false);
    }
  };

  return <div className="h-full">
    <div onClick={() => setOpen(true)} className="h-full">{component}</div>
    <Drawer anchor="right" open={open} onClose={handleDrawerClose}>
      <div className="w-[800px] px-8 pb-8" role="presentation">
        <div className="sticky top-0 bg-white z-10 pt-8">
          <div className="flex justify-between items-center mb-4">
            <div>
              <h2 className="text-lg font-bold">{theme ? "Relevant" : "All"} comments</h2>
              {theme ? <p>{theme}</p> : null}
            </div>
            <div>
              <DownloadExcel data={getCommentsDownloadData(allComments)} label="All comments" />
              <IconButton onClick={() => setOpen(false)}>
                <Close />
              </IconButton>
            </div>
          </div>
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center gap-2">
              {favorabilityOptions.map((option) => {
                return <CustomChip
                  key={option}
                  size="small"
                  label={`${option} - ${favorabilityCounts[option] || 0}`}
                  count={favorabilityCounts[option]}
                  isActive={commentFilters.favoribilty === option}
                  onClick={() => setCommentFilters({ ...commentFilters, favoribilty: option })}
                />;
              })}
            </div>
            <FormControlLabel control={<Switch value={commentFilters.bookmarks} onChange={(e) => {
              setCommentFilters({ ...commentFilters, bookmarks: Boolean(e.target.checked) });
            }} />} label="Bookmarks only" />
          </div>
          <TextField
            type="text"
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search for a keyword or a phrase"
            sx={{ width: "100%" }}
            InputProps={{
              sx: {
                borderRadius: "5px",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </div>
        {pageComments.map((comment) => {
          return <Comment key={comment.comment_id} comment={comment} highlight={searchQuery} favorite={favouriteComments.includes(comment.comment_id)} />;
        })}
        {!commentFilters.bookmarks && filteredComments.length > 0 ? (
          <div className="mt-4 flex justify-center">
            <Pagination
              count={getPageCount(filteredComments.length, ITEM_PER_PAGE)} 
              variant="outlined" 
              shape="rounded" 
              onChange={(e, page) => setPage(page)}
            />
          </div>
        ): null}
      </div>
    </Drawer>
  </div>;
};

import * as am5 from "@amcharts/amcharts5";

export const labels = [
  {
    category: "1",
    value: 50,
    label: "Low",
    columnSettings: {
      fill: am5.color(0x88A943)
    }
  },
  {
    category: "2",
    value: 50,
    label: "Low",
    columnSettings: {
      fill: am5.color(0x88A943)
    }
  },
  {
    category: "3",
    value: 50,
    label: "Low",
    columnSettings: {
      fill: am5.color(0x88A943)
    }
  },
  {
    category: "4",
    value: 50,
    label: "Medium",
    columnSettings: {
      fill: am5.color(0xdbae03)
    }
  },
  {
    category: "5",
    value: 50,
    label: "Medium",
    columnSettings: {
      fill: am5.color(0xdbae03)
    }
  },
  {
    category: "6",
    value: 50,
    label: "Medium",
    columnSettings: {
      fill: am5.color(0xdbae03)
    }
  }, 
  {
    category: "7",
    value: 50,
    label: "High",
    columnSettings: {
      fill: am5.color(0xba413a)
    }
  }, 
  {
    category: "8",
    value: 50,
    label: "High",
    columnSettings: {
      fill: am5.color(0xba413a)
    }
  }, 
  {
    category: "9",
    value: 50,
    label: "High",
    columnSettings: {
      fill: am5.color(0xba413a)
    }
  }, 
  {
    category: "10",
    value: 50,
    label: "High",
    columnSettings: {
      fill: am5.color(0xba413a)
    }
  }, 
  
];

export const axisLabels = [
  {
    category: "1",
    text: "Low",
  },
  {
    category: "10",
    text: "High",
  }
];

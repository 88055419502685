import { ENV } from "constants/env";

import { createSlice } from "@reduxjs/toolkit";

import { insightApi } from "../../apis/api";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
  pages: [],
};

export const userApi = insightApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query({
      query: ({ type, code }) => ({
        url: "",
        params: {
          stage: ENV,
          type: type,
          code: code,
        },
      }),
    }),
  }),
});

export const currentUserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setPages: (state, action) => {
      state.pages = action.payload;
    }
  },
});

export const { setPages } = currentUserSlice.actions;

export const { useLazyGetCurrentUserQuery } = userApi;

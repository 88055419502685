import React, { useEffect } from "react";

import { Box, Grid, Link, Typography } from "@mui/material";
import Information from "components/LocationInsights/Information";
import MoreInformationPopover from "components/MoreInformationPopover/MoreInformationPopover";
import { useSelector } from "react-redux";
import { useParams, useSearchParams, Link as RouterLink } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useLazyGetLocationInsightsQuery } from "store/slices/locationSlice";

import AttritionByTenure from "./AttritionByTenure";
import AttritionGlobalBenchmark from "./AttritionGlobalBenchmark";
import AttritionSummary from "./AttritionSummary";
import AttritionTrends from "./AttritionTrends";
import Question from "../../../components/Question";
import helpText from "../../../helpText";
import { getTeamFromQueryParams } from "../../../utils";

const AttritionDetail = () => {
  const [currentParams] = useSearchParams();
  const [getLocationDetail] = useLazyGetLocationInsightsQuery();
  const { data, isLoading } = useSelector((state) => state.location);
  const { id } = useParams();
  const teamName = getTeamFromQueryParams(currentParams);

  useEffect(() => {
    const params = Object.fromEntries(currentParams.entries());
    getLocationDetail({ params });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentParams]);

  const attritionClassification = data?.metrics?.find((item) => item.metric_name === "attrition_classification");
  const attritionTrends = data?.metrics?.find((item) => item.metric_name === "attrition_rates");
  const locationAttritionRate = data?.metrics?.find((item) => item.metric_name === "attrition_year");
  const teamAttritionRate = data?.adsk_location_metrics?.find((item) => item.metric_name === "attrition_year");
  const attritionByTenure = data?.metrics?.find((item) => item.metric_name === "attrition_by_tenure");
  const attritionGlobalBenchmark = data?.metrics?.find((item) => item.metric_name === "attrition_ext_global_benchmark");

  if(isLoading) {
    return (
      <Box className="flex items-center justify-center w-full">
        <BeatLoader color="#5C5470" />
      </Box>
    );
  }

  // https://people-insights-tableau.autodesk.com/views/AttritionDashboard-New/Summary-New

  return (
    <Box>
      <Question text="What are the attrition patterns at this location?" />
      <Typography my={2}>
        This page is intended to show a subset of insights. For a deeper-dive, please review the {" "}
        <Link 
          component={RouterLink} 
          sx={{ color: "blue", cursor: "pointer" }} 
          to={`/dashboard/${id}/location-insights/tableau?url=https://people-insights-tableau.autodesk.com/views/AttritionDashboard-New/Summary-New`}>
          attrition dashboard 
        </Link>
            &nbsp;(requires Corporate VPN connection).
      </Typography>      
      <Information>
        <MoreInformationPopover helpText={helpText.attrition}/>        
      </Information>

      <Grid container spacing={4} mt={4}>      
        
        <Grid item lg={4} xs={12}>         
        
          <Box width="50%" display="flex" height="210px" justifyContent="center" flexDirection="column" border="1px solid grey" mr={2}>
            <Typography variant="h1" textAlign="center" fontSize="32px" mb={2} fontWeight="bold">
              {attritionClassification?.value}
            </Typography>
            <Typography variant="h6" textAlign="center" fontWeight="bold" mt={2}>
            Attrition Classification for {teamName}
            </Typography>
          </Box>          
        </Grid>      
        
        <Grid item lg={8} xs={12}>
          <AttritionGlobalBenchmark data={attritionGlobalBenchmark?.value} />
        </Grid>
    
        <Grid item lg={4} xs={12}>
          <AttritionSummary 
            locationAttrition={locationAttritionRate?.value} 
            teamAttrition={teamAttritionRate?.value}
          />
        </Grid>
        <Grid item lg={8} xs={12}>
          <AttritionTrends 
            data={attritionTrends?.value}
          />
        </Grid>
        <Grid item lg={12} xs={12}>
          <AttritionByTenure data={attritionByTenure?.value} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AttritionDetail;

import { useState } from "react";

import * as am5 from "@amcharts/amcharts5";
import { InfoOutlined } from "@mui/icons-material";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import RatingBarChart from "components/LocationInsights/RatingBarChart";

const labels = [
  {
    category: "1",
    label: "Low",
    value: 50,
    columnSettings: {
      fill: am5.color(0x88A943)
    }
  }, 
  {
    category: "2",
    label: "Low",
    value: 50,
    columnSettings: {
      fill: am5.color(0x88A943)
    }
  }, 
  {
    category: "3",
    label: "Low",
    value: 50,
    columnSettings: {
      fill: am5.color(0x88A943)
    }
  }, 
  {
    category: "4",
    label: "Medium",
    value: 50,
    columnSettings: {
      fill: am5.color(0xdbae03)
    }
  }, 
  {
    category: "5",
    label: "Medium",
    value: 50,
    columnSettings: {
      fill: am5.color(0xdbae03)
    }
  }, 
  {
    category: "6",
    label: "Medium",
    value: 50,
    columnSettings: {
      fill: am5.color(0xdbae03)
    }
  }, 
  {
    category: "7",
    label: "High",
    value: 50,
    columnSettings: {
      fill: am5.color(0xba413a)
    }
  },
  {
    category: "8",
    label: "High",
    value: 50,
    columnSettings: {
      fill: am5.color(0xba413a)
    }
  },
  {
    category: "9",
    label: "High",
    value: 50,
    columnSettings: {
      fill: am5.color(0xba413a)
    }
  },
  {
    category: "10",
    label: "High",
    value: 50,
    columnSettings: {
      fill: am5.color(0xba413a)
    }
  }
];

const axisLabels = [
  { category: "1", text: "Low" },
  { category: "10", text: "High" }
];

const RcCatgories = {
  "relative_compensation": "Overall",
  "relative_compensation_general": "General",
  "relative_compensation_technical": "Technical",
  "relative_compensation_sales": "Sales"
};

const RelativeCompensation = ({ data }) => {
  const rcData = Object.keys(RcCatgories).reduce((acc, key) => {
    if (data.value[key]) {
      return {
        ...acc,
        [key]: {
          key,
          label: RcCatgories[key],
          data: data.value[key]
        }
      };
    }
  }, {});

  console.log(rcData);

  const [rcCategory, setRcCategory] = useState(() => Object.keys(rcData)[0]);
  return (<>
    <p className="font-medium">{rcData[rcCategory]?.data?.chart_header}</p>
    <p className="flex items-start my-2 text-sm">
      <InfoOutlined />
      <span className="ml-2">{rcData[rcCategory]?.data?.description}</span>
    </p>
    <ToggleButtonGroup value={rcCategory} exclusive onChange={(e, value) => setRcCategory(value)} size="small">
      {
        Object.keys(rcData).map(key => {
          console.log(key, rcData[key]);
          return <ToggleButton key={key} value={key}>{rcData[key].label}</ToggleButton>;
        })
      }
    </ToggleButtonGroup>
    <RatingBarChart data={rcData[rcCategory].data?.chart_data} labels={labels} axisLabels={axisLabels} />
  </>
  );
};

export default RelativeCompensation;

import React, { useMemo, useState } from "react";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { MaterialReactTable, MRT_ToggleFullScreenButton, useMaterialReactTable } from "material-react-table";
import { useUpdateEmployeeListeningOrderMutation } from "store/slices/employeeListeningSlice";

import EmployeeListeningCreate from "./EmployeeListeningCreate";

const LifecycleSurveysManagement = ({ data, isLoading = false, onEdit, onRemove, onCreateNew }) => {

  const [lifecycleSurveys, setLifecycleSurveys] = useState(() => data.sort((a, b) => a.display_order - b.display_order));
  const [updateEmployeeListeningOrder] = useUpdateEmployeeListeningOrderMutation();

  const columns = useMemo(() => {
    return [
      {
        accessorKey: "actions",
        header: "Actions",
        type: "actions",
        size: 120,
        enableGlobalFilter: false, 
        Cell: ({ row }) => (
          <>
            <IconButton onClick={() => onEdit(row.original)}>
              <EditOutlinedIcon />
            </IconButton>
            <IconButton onClick={() => onRemove(row.original.id)}>
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </>
        ),
      },
      { accessorKey: "id", header: "ID", size: 90, 
        enableGlobalFilter: false, },
      {
        accessorKey: "pageName",
        header: "Page Name",
        size: 150,
      },
      {
        accessorKey: "title",
        header: "Title",
        size: 200,
      },
      {
        accessorKey: "description",
        header: "Description",
        description: "This column has a value getter and is not sortable.",
        size: 300,
      },
      {
        accessorKey: "coming_soon",
        header: "Coming Soon",
        size: 100,
        enableGlobalFilter: false, 
        accessorFn: (dataRow) => dataRow.coming_soon ? "YES" : "NO"
      },
      {
        accessorKey: "score",
        header: "Score",
        size: 100,
        enableGlobalFilter: false, 
      },
    ];
  }, [onEdit, onRemove]);

  const table = useMaterialReactTable({
    columns,
    data: lifecycleSurveys || [],
    state: {
      isLoading: isLoading,
      showProgressBars: isLoading,
    },
    muiSkeletonProps: {
      animation: "wave"
    },
    initialState: { 
      showGlobalFilter: true
    },
    enableColumnActions: false,
    enableGlobalFilterModes: true,
    positionGlobalFilter: "left",
    muiSearchTextFieldProps: {
      placeholder: "Search all employee experience listening",
      sx: { minWidth: "300px" },
      variant: "outlined",
    },
    enablePagination: false,
    enableColumnFilters: false,
    renderToolbarInternalActions: ({ table }) => (
      <Box display="flex" alignItems="center">
        <EmployeeListeningCreate onCreateNew={onCreateNew} />
        <MRT_ToggleFullScreenButton table={table} />
      </Box>
    ),
    enableSorting: false,
    enableRowOrdering: true,
    autoResetPageIndex: false,
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          lifecycleSurveys.splice(
            hoveredRow.index,
            0,
            lifecycleSurveys.splice(draggingRow.index, 1)[0],
          );
          setLifecycleSurveys([...lifecycleSurveys]);
          updateEmployeeListeningOrder({ data: lifecycleSurveys.map((item, index) => ({ ...item, display_order: index })) });
        }
      },
    }),
  });

  return (
    <>
      <Box mt={2}>
        <MaterialReactTable
          table={table}
        />
      </Box>
    </>
  );
};

export default LifecycleSurveysManagement;

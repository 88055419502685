import React from "react";

import { InfoOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import InfoDialog from "components/InfoDialog";
import { BeatLoader } from "react-spinners";
import { useGetLocationOfficeSummaryQuery, useLazyGetLocationOfficeDetailQuery } from "store/slices/locationSlice";

import LocationInsightsHubMatrix from "./LocationInsightsHubMatrix";
import LocationInsightsHubSummary from "./LocationInsightsHubSummary";

const LocationInsightsHub = () => {
  const { data, isLoading } = useGetLocationOfficeSummaryQuery();
  const [getLocationOfficeDetail, hubDetails] = useLazyGetLocationOfficeDetailQuery();
  const [showHubDetails, setShowHubDetails] = React.useState(false);

  const handleRowClick = (id) => {
    getLocationOfficeDetail({ id }, true);
    setShowHubDetails(true);
  };

  const officeSizeInfo = `We use headcount, site square footage and office type to determine the office classifications as follows:<br/>
  <br/><p><b>Large (Hub)</b>: 
  <ul><li>Major operational hub with a significant employee base of 200+ headcount and open requisitions</li>
  <li>Full-scale operations with a wide variety of engineering, sales and corporate functions</li>
  <li>Large office and meeting spaces, and may include ACE spaces and technology centers with lease terms of 5-10 years </li></ul></p>
  <p></p><p><b> Guidance: Directionally sound for headcount investment unless otherwise noted. Refer to the Qualitative Insights in the Office Location Summary.</b> </p>
  <br/><p><b>Medium</b>:
  <ul><li>Offices greater than 10,000 square feet and and employee base of 75-200 headcount and open requisitions</li>
  <li>Full office spaces but not the extensive facilities found in large hubs with lease terms of 3-5 years.</li>
  <li>Typically supporting one primary division (e.g., sales, marketing, engineering) and may offer partial support services (PPL, ESE). </li></ul></p>
  <p></p><p><b>Guidance: Review for relevance for co-located teams. Reflects both maintain and potential growth locations </b></p>
  <br/><p><b>Small</b>: 
  <ul><li>Offices are less than ~10,000 square feet and 5-75 headcount with lease terms of 1-3 years</li>
  <li>Typically focused on one function (e.g., satellite sales, customer support, or specialized R&D).</li>
  <li>Limited in-house support services.</li></ul></p>
  <p></p><p><b>Guidance: Workplace limitations. More research required.</p></b><br/>`;
  
  
  return (
    <div className="px-8 py-6">
      <InfoDialog triggerComponent={<div className="flex items-center gap-2 mb-2 cursor-pointer max-w-fit">
        <InfoOutlined />
        <span className="underline font-medium">How do we classify Autodesk offices?</span>        
      </div>
      } 
      title="Autodesk Office Sizes" 
      description={ 
        <div className="text-sm" dangerouslySetInnerHTML={{ __html: officeSizeInfo }}/>
      }
      />
      {
        isLoading || hubDetails?.isFetching ? 
          <Box className="flex items-center justify-center w-full h-full my-16">
            <BeatLoader color="#5C5470" />
          </Box> : 
          hubDetails?.data && showHubDetails ? 
            <LocationInsightsHubSummary data={hubDetails.data} setShow={setShowHubDetails} /> : 
            <LocationInsightsHubMatrix data={data} onRowClick={handleRowClick} />
      }
    </div>
  ); 
};
  
export default LocationInsightsHub;

import React from "react";

import { Box, Grid, Typography } from "@mui/material";

const TimezoneCompatibility = ({ data }) => {

  if(!data) {
    return (
      <Typography variant="body1" textAlign="center">No data available</Typography>
    );
  }

  return (
    <Grid display="flex" spacing={4} container>
      <Grid item lg={5} xs={12}>
        <Box width="100%" display="flex" height="200px" justifyContent="center" flexDirection="column" border="1px solid grey" mr={2}>
          <Typography variant="h1" textAlign="center" fontSize="32px" mb={2} fontWeight="bold">
            {data.score}
          </Typography>
          <Typography variant="h6" textAlign="center" fontWeight="bold">
            Time zone compatibility within the team
          </Typography>
        </Box>
      </Grid>
      <Grid item lg={7} xs={12} display="flex" alignItems="center">
        <ul>
          {Object.values(data.narrative).map((item, index) => (
            <li key={index} style={{ fontsize: "24px", fontWeight: "bold", marginTop: "12px", marginBottom: "12px" }}>
              {item}
            </li>
          ))}
        </ul>
      </Grid>
    </Grid>
  );
};

export default TimezoneCompatibility;

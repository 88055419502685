import { LinearProgress } from "@mui/material";

export const Loader = ({ title, subtitle }) => {
  return (
    <div className="flex flex-col justify-center items-center h-48 p-10 bg-[#f9f9f9]">
      <p className="text-2xl font-bold mb-8">{title}</p>
      <div className="w-1/2">
        <LinearProgress color="primary" className="mb-2 w-full h-4" />
        <span className="text-sm block self-start">{subtitle}</span>
      </div>
    </div>
  );
};

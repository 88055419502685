import * as React from "react";

import { LoadingButton } from "@mui/lab";
import { Alert, Box, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AutoCompleteFormField from "components/FormComponents/AutocompleteFormField";
import CheckboxFormField from "components/FormComponents/CheckboxFormField";
import RadioFormField from "components/FormComponents/RadioFormField";
import SelectFormField from "components/FormComponents/SelectFormField";
import TextFormField from "components/FormComponents/TextFormField";
import Cookies from "js-cookie";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useGetApplicationFiltersQuery } from "store/slices/commentSlice";
import { usePostFeedbackMutation } from "store/slices/feedbackSlice";

const defaultValues = {
  category: "",
  product: [],
  feedback: "",
};

const FeedbackModal = ({
  open,
  onClose
}) => {
  const { data: options, isLoading } = useGetApplicationFiltersQuery({ page_name: "feedback" });
  const [createFeedback, { isLoading: isCreating }] = usePostFeedbackMutation();
  const location = useLocation();

  const products = options?.product ?? [];

  const { handleSubmit, control, formState: { errors, isValid }, watch, reset, setValue } = useForm({
    defaultValues,
    mode: "all",
  });

  React.useEffect(() => {
    const currentProduct = products.find(product => {
      return location.pathname.includes(product.id) || location.pathname.includes(product.title.toLowerCase().replace(/\s/, "-"));
    });
    if (currentProduct) {
      setValue("product", [currentProduct.title]);
    }
  }, []);

  const selectedCategory = watch("category");
  const showCheckbox = ["Access Issues", "Data Quality Concern", "Bug Report"].includes(selectedCategory);

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = async (data) => {
    const { satisfaction, urgent, ...values } = data;
    try {
      if (selectedCategory === "UI Feedback") {
        await createFeedback({ data: { ...values, satisfaction } }).unwrap();
      }
      if (showCheckbox) {
        await createFeedback({ data: { ...values, urgent } }).unwrap();
      }
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const productTitles = products?.map(product => product.title);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth="md"
      maxWidth="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle variant="h3" fontWeight="bold">Share Feedback with PISD</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Alert severity="info" sx={{ mt: 2 }}>
              <Typography variant="body1" fontWeight="bold">
                NOTE: VPN connection is required to access all People Insights Portal resources. Make sure you're connected to VPN!
              </Typography>
            </Alert>
            <Typography variant="body1" mt={4}>
              Hi {Cookies.get("email")}, when you submit this form, the admin will see your name and email address.
            </Typography>
          </DialogContentText>
          {isLoading ? (
            <Box display="flex" justifyContent="center">
              <BeatLoader color={"#5C5470"} />
            </Box>
          ) : (
            <Grid container spacing={4} mt={1}>
              <Grid item md={12}>
                <SelectFormField
                  fullWidth
                  name="category"
                  label="Category"
                  error={errors.category}
                  control={control}
                  options={options?.category || []}
                />
                {showCheckbox && (
                  <Box mt={1}>
                    <CheckboxFormField
                      name="urgent"
                      label="Is the issue negatively impacting crucial business processes, or otherwise urgent?"
                      //fullWidth
                      control={control}
                    />
                  </Box>
                )}
              </Grid>
              <Grid item md={12}>
                <AutoCompleteFormField
                  fullWidth
                  name="product"
                  label="PISD product"
                  error={errors.product}
                  multiple
                  control={control}
                  options={productTitles}
                  required
                  rules={{
                    validate: value => value.length > 0 || "Please select the PISD product at issue"
                  }}
                />
              </Grid>
              {selectedCategory === "UI Feedback" && (
                <Grid item md={12}>
                  <Typography variant="body1">
                    How satisfied are you with the product(s) you've selected?
                  </Typography>
                  <RadioFormField
                    name="satisfaction"
                    control={control}
                    options={[
                      { label: "5 - Extremely satisfied", value: "5" },
                      { label: "4 - Somewhat satisfied", value: "4" },
                      { label: "3 - Neither satisfied nor dissatisfied", value: "3" },
                      { label: "2 - Somewhat dissatisfied", value: "2" },
                      { label: "1 - Extremely dissatisfied", value: "1" },
                    ]}
                  />
                </Grid>
              )}
              <Grid item md={12}>
                <TextFormField
                  name="feedback"
                  label="Feedback"
                  type="text"
                  fullWidth
                  control={control}
                  error={errors.feedback}
                  multiline
                  minRows={10}
                  rules={{
                    required: "Please enter your feedback."
                  }}
                  required
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isCreating}>Cancel</Button>
          <LoadingButton type="submit" variant="contained" isLoading={isCreating} disabled={!isValid}>Submit</LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FeedbackModal;

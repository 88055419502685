import React, { useLayoutEffect } from "react";


import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Box, Typography } from "@mui/material";
import { ColorTheme6 } from "theme/chart";

const HeadcountTypeBreakdownChart = ({ data }) => {
  const chartData = Object.entries(data).map(([key, value]) => ({
    label: key,
    value: +value
  })).sort((a, b) => b.value - a.value);

  // This code will only run one time
  useLayoutEffect(() => {
    let root = am5.Root.new("label-bar-chart");

    root.setThemes([ColorTheme6.new(root)]);
    root._logo.dispose();

    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      paddingLeft:0,
      paddingRight:1
    }));

    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineY.set("visible", false);


    // // Create axes
    // // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xRenderer = am5xy.AxisRendererX.new(root, { 
      minGridDistance: 30, 
      minorGridEnabled: true,
    });
    xRenderer.labels.template.setAll({
      oversizedBehavior: "wrap",
      textAlign: "center",
      fontSize: 12
    });
    
    let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      maxDeviation: 0.3,
      categoryField: "label",
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(root, {}),
    }));

    xRenderer.labels.template.adapters.add("width", function(width, target) {
      if(xAxis?.dataItems?.length > 0) {
        const x0 = xAxis.getDataItemCoordinateY(xAxis.dataItems[0], "category", 0);
        const x1 = xAxis.getDataItemCoordinateY(xAxis.dataItems[0], "category", 1);
        target.set("maxWidth", x1 - x0 + 10);
        return x1 - x0;
      }
    });

    let yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0.1
    });

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      maxDeviation: 0.3,
      min: 0,
      renderer: yRenderer,
      numberFormat: "#",
      extraMax: 0.05
    }));

    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: "Series 1",
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "value",
      sequencedInterpolation: true,
      categoryXField: "label",
      tooltip: am5.Tooltip.new(root, {
        labelText: "{valueY}"
      }),
      // strokeOpacity: 1,
      // fillOpacity: 0.3
    }));

    series.columns.template.setAll({ 
      // strokeOpacity: 1,
      // fillOpacity: 0.3
    });
    series.columns.template.adapters.add("fill", function (fill, target) {
      return chart.get("colors").getIndex(series.columns.indexOf(target));
    });

    series.columns.template.adapters.add("stroke", function (stroke, target) {
      return chart.get("colors").getIndex(series.columns.indexOf(target));
    });

    xAxis.get("renderer").grid.template.setAll({
      visible: false
    });
    // Add labels on top of bars
    series.bullets.push(function() {
      return am5.Bullet.new(root, {
        locationY: 0.99,
        sprite: am5.Label.new(root, {
          text: "{value}",
          centerY: am5.p100,
          centerX: am5.p50,
          fontSize: 12,
          populateText: true
        })
      });
    });

    xAxis.data.setAll(chartData);
    series.data.setAll(chartData);

    return () => {
      root.dispose();
    };
  }, [chartData]);

  return (
    <Box id="label-bar-chart" style={{ width: "100%", height: "400px" }}></Box>
  );
};

const HeadcountTypeBreakdown = ({ data }) => {

  return (
    <Box mt={8}>
      <Typography variant="h4" fontWeight="bold" mb={4}>
        Headcount Worker Type Breakdown
      </Typography>
      {data ? (
        <HeadcountTypeBreakdownChart data={data} />
      ) : (
        <Typography variant="body1">No data available</Typography>
      )}
    </Box>
  );
};

export default HeadcountTypeBreakdown;

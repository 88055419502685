import React, { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";
import { useGetCommentChartDataMutation } from "store/slices/commentSlice";
import { useDebounceCallback } from "usehooks-ts";
import { publish } from "utils/events";

import FavouribilityChart from "./Chart/FavouribiltyChart";
import CommentsSummary from "./CommentsSummary";
import { Error } from "../components/Error";
import { COMMENTS_APP_DATA_LOADING_EVENT } from "../constants";

const MIN_COMMENTS_COUNT = 10;

const CommentContent = () => {

  const [currentParams] = useSearchParams();
         
  const geoParam = currentParams.get("geo");
  const countryParam = currentParams.get("country");
  const divisionParam = currentParams.get("division");
  const mgmtLvlParam = currentParams.get("mgmtLvl");
  const l2mParam = currentParams.get("l2m");
  const l3mParam = currentParams.get("l3m");
  const favorabilityParam = currentParams.get("favorability");
  const questionParam = currentParams.get("question");
  const searchParam = currentParams.get("search");
  const datesParam = currentParams.get("dates");
    
  const paramString = currentParams.toString();
    
  const [ getChartData, { isLoading: isChartLoading } ] = useGetCommentChartDataMutation();
  const [favorabilityData, setFavorabilityData] = useState(null);
  const [isServerError, setIsServerError] = useState(false); 

  const handleFetchChartData = useDebounceCallback(async () => {
    try {
      setFavorabilityData(null);
      publish(`${COMMENTS_APP_DATA_LOADING_EVENT}:Start`);
      const response = await getChartData({
        dates: [datesParam].filter(Boolean),
        favorability: [favorabilityParam].filter(Boolean),
        question: [questionParam].filter(Boolean),
        geo: [geoParam].filter(Boolean),
        country: [countryParam].filter(Boolean),
        division: [divisionParam].filter(Boolean),
        mgmtLvl: [mgmtLvlParam].filter(Boolean),
        l2m: [l2mParam].filter(Boolean),
        l3m: [l3mParam].filter(Boolean),
        ...(searchParam ? { text: searchParam } : {}),
      }).unwrap();
      setFavorabilityData(response);

      const currentCommentCount = response?.[datesParam]?.total_comments || 0;

      // If not enough comments then we will not load next component
      if (currentCommentCount < MIN_COMMENTS_COUNT) {
        publish(`${COMMENTS_APP_DATA_LOADING_EVENT}:End`);
      }
    } catch (error) {
      console.error({ error });
      setFavorabilityData(null);
      setIsServerError(true);
      publish(`${COMMENTS_APP_DATA_LOADING_EVENT}:End`);
    }
  }, 500);

  useEffect(() => {
    if (datesParam) {
      handleFetchChartData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramString]);

  const currentCommentCount = favorabilityData?.[datesParam]?.total_comments || 0;

  useEffect(() => {
    
  }, [favorabilityData, currentCommentCount]);

  if (isServerError) {
    return <Error title="Something is not working at this moment" subtitle="Please change filters or refresh this page" />;
  }

  return <>
    <div className="mb-6">
      <FavouribilityChart isLoading={isChartLoading} data={favorabilityData} /> 
    </div>
    {
      !isChartLoading && favorabilityData && currentCommentCount < MIN_COMMENTS_COUNT ? <div className="mb-6">
        <Error 
          title="Sorry, your query has less than 10 results" 
          subtitle="We do not show 10 comments due to confidentiality. Please adjust your query and try again" 
        />
      </div> : null
    }
    {
      !isChartLoading && favorabilityData && currentCommentCount > MIN_COMMENTS_COUNT ? <div className="mb-6">
        <CommentsSummary />
      </div> : null 
    }
  </>;
};

export default CommentContent;

export const typography = {
  fontFamily: [
    "ArtifaktElement",
    "ArtifaktLegend",
    "Helvetica Neue",
    "Arial",
    "sans-serif"
  ].join(","),
  h1: {
    fontSize: 32
  },
  h2: {
    fontSize: 28
  },
  h3: {
    fontSize: 24
  },
  h4: {
    fontSize: 20
  },
  h5: {
    fontSize: 18
  },
  h6: {
    fontSize: 16
  },
  body1: {
    fontSize: 14
  },
  body2: {
    fontSize: 12
  }
};

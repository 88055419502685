import React from "react";

import Box from "@mui/material/Box"; 
import Footer from "components/Footer/Footer";
import BlogDetailView from "pages/Blogs/BlogDetailView";
import DashboardDetailView from "pages/Dashboards/DashboardDetailView";
import ResearchDetailView from "pages/Researches/ResearchDetailView";
import { useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useGetEmployeeListeningQuery } from "store/slices/employeeListeningSlice";

const EmployeeListeningDetailPage = () => {
  const { employeeListeningId } = useParams();

  const { data: employeeListening, isLoading } = useGetEmployeeListeningQuery({ id: employeeListeningId }, { skip: !employeeListeningId });

  if(isLoading) {
    return (
      <Box 
        py={8}
        height="calc(100vh - 300px)"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <BeatLoader color={"#5C5470"} />
      </Box>
    );
  }

  if(!employeeListening) {
    return null;
  }

  if(employeeListening.item_type === "research") {
    return (
      <>
        <ResearchDetailView 
          research={employeeListening} 
          isLoading={isLoading}
        />
        <Footer />
      </>
    );
  }

  if(employeeListening.item_type === "dashboard") {
    return (
      <>
        <DashboardDetailView
          dashboard={employeeListening} 
          isLoading={isLoading}
          isEmployeeListening
        />
      </>
    );
  }

  if(employeeListening.item_type === "blog") {
    return (
      <>
        <BlogDetailView 
          blog={employeeListening} 
          isLoading={isLoading}
        />
        <Footer />
      </>
    );
  }

  return null;
};

export default EmployeeListeningDetailPage;

import React from "react";

import { Box, Card, CardContent, Divider, Typography } from "@mui/material";

const LocationInsightCard = ({ children, title, data }) => {
  return (
    <Card sx={{ height: "100%" }}>
      <CardContent sx={{ height: "100%" }}>
        <Typography variant="h5" fontWeight="bold" mb={2}>{title}</Typography>
        <Divider sx={{ mx: -2 }}/>
        <Box pt={4} height="calc(100% - 32px)">
          {data ? (
            data === "No Data" ? (
              <Typography variant="body1">No Data</Typography>
            ):(
              children 
            )
          ) : (
            <Box className="text-center" py={8}>
              <Typography variant="body1">No Data Available</Typography>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default LocationInsightCard;

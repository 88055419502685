import React from "react";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";

const DateFormField = ({
  error,
  name,
  control,
  rules,
  fullWidth,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={null}
      render={({ field: { value, ...field } }) =>
        <DatePicker
          value={dayjs(value, "MM/DD/YYYY")}
          {...field}
          slotProps={{
            textField: {
              error: !!error,
              helperText: error?.message,
              fullWidth: fullWidth
            }
          }}
          {...props}
        />
      }
    />
  );
};

export default DateFormField;

import React from "react";

import { Grid } from "@mui/material";

import AdminMenu from "./AdminMenu";

const AdminLayout = ({ children }) => {
  return (
    <Grid container columnSpacing={4} mb={4}>
      {/* <BreadcrumbSection /> */}
      <Grid item md={3}>
        <AdminMenu />
      </Grid>
      <Grid item md={9}>{children}</Grid>
    </Grid>
  );
};

export default AdminLayout;

import React from "react";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Controller } from "react-hook-form";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AutoCompleteFormField = ({
  error,
  name,
  control,
  rules,
  options,
  fullWidth,
  multiple,
  label,
  required,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={null}
      render={({ field: { value, ...field } }) =>
        <Autocomplete
          multiple={multiple}
          id="checkboxes-tags-demo"
          options={options}
          value={value}
          onChange={(_, data) => field.onChange(data)}
          disableCloseOnSelect
          fullWidth={fullWidth}
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) => {
            const { key, ...optionProps } = props;
            return (
              <li key={key} {...optionProps}>
                {multiple && (
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                )}
                {option}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField {...params} error={!!error} helperText={error?.message} label={`${label}${required ? "*" : ""}`} placeholder={label} />
          )}
          { ...props}
        />
      }
    />
  );
};

export default AutoCompleteFormField;

import React from "react";

// import LabelBarChart from "components/LocationInsights/LabelBarChart";
import { Box, Link } from "@mui/material";
import LocationInsightCard from "components/LocationInsights/LocationInsightCard";
import { useParams, useSearchParams, Link as RouterLink } from "react-router-dom";

import LeadershipDepthOverviewChart from "./LeadershipDepthOverviewChart";

const LeadershipDepthOverview = ({
  data 
}) => {
  const [currentParams] = useSearchParams();
  const { id } = useParams();

  // eslint-disable-next-line no-unused-vars
  const { "Individual Contributors": _individual, "Managers": _manager, ...rest } = data?.value || {};

  const chartData = rest ? [
    {
      category: "Directors",
      value: +rest["Directors"] || 0,
    },
    {
      category: "Senior Directors",
      value: +rest["Senior Directors"] || 0,
    },
    {
      category: "VPs",
      value: +rest["VPs"] || 0,
    },
    {
      category: "SVPs and Above",
      value: +rest["SVPs and above"] || 0,
    }
  ] : [];

  return (
    <LocationInsightCard title="Leadership Depth" data={data?.value}>
      <LeadershipDepthOverviewChart data={chartData} />
      <Box mt={4}>
        <Link 
          component={RouterLink} 
          sx={{ cursor: "pointer", fontSize: "16px", fontWeight: "bold", color:"blue", mb: 2 }} 
          to={`/dashboard/${id}/location-insights/internal-view/detail/leadership-depth?${currentParams.toString()}`}>
            View Leadership Depth Details
        </Link>
      </Box>
    </LocationInsightCard>
  );
};

export default LeadershipDepthOverview;

import React from "react";

import { Typography } from "@mui/material";
import PieChart from "components/LocationInsights/PieChart";
import { ColorTheme4 } from "theme/chart";

const ExperienceRanking = ({ data }) => {
  return (
    <div>
      <Typography variant="h4" fontWeight="bold" my={4}>
        Experience Ranking
      </Typography>
      {data ? (
        <PieChart id="experience-ranking" data={data} valueKey="demandcount" labelKey="name" colorTheme={ColorTheme4} />
      ) : (
        <Typography variant="body1">No data available</Typography>
      )}
    </div>
  );
};

export default ExperienceRanking;

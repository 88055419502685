import React from "react";

import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import { Box, IconButton } from "@mui/material";
import { saveAs } from "file-saver";
import { MaterialReactTable, MRT_ToggleFullScreenButton, useMaterialReactTable } from "material-react-table";
import * as XLSX from "xlsx";

import { columns } from "./column";

const DownloadExcelButton = ({ data }) => {
  const downloadExcel = () => {
    const excelData = data.map(item => {
      return columns.reduce((acc, column) => {
        return {
          ...acc,
          [column.header]: item[column.accessorKey] 
        };
      }, {});
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      XLSX.utils.json_to_sheet(excelData),
      "Sheet 1"
    );
    const arrayBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const blob = new Blob([arrayBuffer], {
      type: "application/octet-stream",
    });
    saveAs(blob, "Internal View Summary.xlsx");
  };

  return (
    <IconButton onClick={downloadExcel}>
      <GetAppOutlinedIcon />
    </IconButton>
  );
};
const ExternalViewTable = ({ data }) => {
  const table = useMaterialReactTable({
    columns,
    data,
    enableExpandAll: false, 
    enableExpanding: false,
    filterFromLeafRows: true, 
    getSubRows: (row) => row.subrows, 
    initialState: { expanded: true }, 
    paginateExpandedRows: false, 
    muiTableBodyCellProps: {
      sx: {
        fontSize: "18px"
      }
    },
    muiTableHeadCellProps: {
      sx: {
        fontSize: "14px"
      }
    },
    paginationDisplayMode: "pages",
    muiPaginationProps: {
      color: "primary",
      shape: "rounded",
      showRowsPerPage: false,
      variant: "outlined",
    },
    renderToolbarInternalActions: ({ table }) => (
      <Box display="flex" alignItems="center">
        <DownloadExcelButton data={data} />
        <MRT_ToggleFullScreenButton table={table} />
      </Box>
    ),
  });

  return (
    <MaterialReactTable
      table={table} 
    />
  );
};

export default ExternalViewTable;

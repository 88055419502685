import React, { useEffect } from "react";

import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { Button, Menu, MenuItem } from "@mui/material";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

export const DateFilter = ({ surveyDates, isFilterLoading }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [currentParams, setCurrentParams] = useSearchParams();
  const dateParam = currentParams.get("dates");
  const dateFilterOptions = [...surveyDates].sort((a, b) => dayjs(b).diff(a));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (date) => {
    currentParams.set("dates", date);
    setCurrentParams(currentParams);
    handleClose();
  };

  useEffect(() => {
    if (dateFilterOptions.length > 0 && !dateParam) {
      currentParams.set("dates", dateFilterOptions[0]);
      setCurrentParams(currentParams);
    }
  }, [JSON.stringify(dateFilterOptions), dateParam]);

  return (
    <>
      <Button
        aria-controls="date-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="contained"
        color="primary"
        disabled={isFilterLoading}
        endIcon={open ? <ExpandLess /> : <ExpandMore />}
      >
        {dateParam ? dayjs(dateParam).format("MMMM - YYYY"): "Dates"}
      </Button>
      <Menu
        id="date-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {dateFilterOptions.map((date) => (
          <MenuItem key={date} onClick={() => handleMenuItemClick(date)} selected={date === dateParam}>
            {dayjs(date).format("YYYY - MMMM")}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

import React, { useEffect, useState } from "react";

import { Stack, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { CustomChip } from "components/CustomChip";
import { useSearchParams } from "react-router-dom";

const GeoSelectOptions = ({ data }) => {
  const [currentParams, setCurrentParams] = useSearchParams();
  const geoParam = currentParams.get("geo");
  const countryParam = currentParams.get("country");

  const [geo, setGeo] = useState(() => geoParam || null);
  const [country, setCountry] = useState(() => countryParam || null);
  
  useEffect(() => {
    if (geoParam) {
      setGeo(geo);
    }
    if (countryParam) {
      setCountry(countryParam);
    }
  }, [geoParam?.toString(), countryParam?.toString()]);

  const handleChange = (geo) => {
    currentParams.set("geo", geo);
    currentParams.delete("country");
    setCurrentParams(currentParams);
    setGeo(geo);
    setCountry(null);
  };

  const handleChangeCountry = (event) => {
    currentParams.set("country", event.target.value);
    setCurrentParams(currentParams);
    setCountry(event.target.value);
  };
  
  const countryOptions = data?.country?.[geo] || [];
  
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Geo & Country
      </Typography>
      <Stack direction="row" spacing={1}>
        {data.geo.map(item => (
          <CustomChip key={item} label={item} isActive={geo === item} onClick={() => handleChange(item)} />
        ))}
      </Stack>
      <FormControl sx={{ maxWidth: "400px", marginTop: 2 }}>
        <InputLabel id="demo-simple-select-label">Country</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={country}
          disabled={countryOptions.length === 0}
          label="Country"
          onChange={handleChangeCountry}
        >
          {countryOptions.map(item => (
            <MenuItem key={item} value={item}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default GeoSelectOptions;

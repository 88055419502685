import React, { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { MyTheme } from "components/LocationInsights/theme";
import { v4 as uuidv4 } from "uuid";

const RatingBarChart = ({ data, labels, axisLabels=[] }) => {
  const chartData = labels.map(label => label.category === data.score.toString() ? { ...label, currentBullet: true } : label);
  const id = uuidv4();
  // This code will only run one time
  useLayoutEffect(() => {
    const root = am5.Root.new(id);

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      MyTheme.new(root)
    ]);
    root._logo.dispose();


    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    const chart = root.container.children.push(am5xy.XYChart.new(root, {
      layout: root.verticalLayout,
      paddingRight: 30
    }));


    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50
      })
    );


    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "category",
      renderer: am5xy.AxisRendererX.new(root, {

      }),
      tooltip: am5.Tooltip.new(root, {})
    }));

    const xRenderer = xAxis.get("renderer");

    xRenderer.grid.template.set("forceHidden", true);
    xRenderer.labels.template.set("forceHidden", true);

    xAxis.data.setAll(chartData);

    const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      min: 0,
      max: 400,
      strictMinMax: true,
      renderer: am5xy.AxisRendererY.new(root, {})
    }));

    const yRenderer = yAxis.get("renderer");

    yRenderer.grid.template.set("forceHidden", true);
    yRenderer.labels.template.set("forceHidden", true);


    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

    const series = chart.series.push(am5xy.ColumnSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "value",
      categoryXField: "category",
      maskBullets: false
    }));

    series.columns.template.setAll({
      width: am5.p100,
      height: am5.percent(10),
      tooltipY: 0,
      strokeOpacity: 1,
      strokeWidth:2,
      stroke:am5.color(0xffffff),
      templateField: "columnSettings"
    });

    series.bullets.push(function(root, target, dataItem) {
      if (dataItem.dataContext.currentBullet) {
        const container = am5.Container.new(root, {});
    
        container.children.push(am5.Graphics.new(root, {
          fill: dataItem.dataContext.columnSettings.fill,
          dy: -5,
          stroke: am5.color(0xffffff),
          strokeWidth: 2,
          centerY: am5.p100,
          centerX: am5.p50,
          svgPath: "M12 12 H108 V108 H66 L60 120 L54 108 H12 Z",
        }));
    
        container.children.push(am5.Label.new(root, {
          text: dataItem.dataContext.label.split(" ").join("\n"),
          dy: -65,
          centerY: am5.p50,
          centerX: am5.p50,
          populateText: true,
          width: 80,
          height: 80,
          textAlign: "center",
          breakWords: true,
          wrap: true,
          paddingTop: 45,
          fontSize: 12,
          paddingRight: 5,
          paddingBottom: 5,
          paddingLeft: 5,
          background: am5.RoundedRectangle.new(root, {
            fill: am5.color(0xffffff),
            cornerRadiusTL: 0,
            cornerRadiusTR: 0,
            cornerRadiusBR: 0,
            cornerRadiusBL: 0,
          })
        }));

        container.children.push(am5.Label.new(root, {
          text: `[bold]${dataItem.get("categoryX")}`,
          dy: -80,
          fontSize: 24,
          centerY: am5.p50,
          centerX: am5.p50,
          populateText: true,
        }));
    
        return am5.Bullet.new(root, {
          locationY: 1,
          sprite: container
        });
      }
      return false;
    });

    series.data.setAll(chartData);

    // Add labels
    function addAxisLabel(category, text) {
      const rangeDataItem = xAxis.makeDataItem({
        category: category
      });
  
      const range = xAxis.createAxisRange(rangeDataItem);

      range.get("label").setAll({
        text: text,
        forceHidden: false
      });

      range.get("grid").setAll({
        strokeOpacity: 1,
        location: 1
      });
    }

    axisLabels.map(({ category, text }) => addAxisLabel(category, text));

    return () => {
      root.dispose();
    };

  }, [chartData]);

  return <div id={id} style={{ width: "100%", height: "200px" }}></div>;
};

export default RatingBarChart;

const colorSets = [
  "#186bff", "#a90c7c", "#e51050", "#ff6b00", "#dbae03", "#000000", "#88A943", "#038c4a", "#37a5cc", "#9670ed"
];

const defaultColor = "#939191";

export const getTopLocationsData = (autodeskData, teamData) => {

  const autodeskChartData = autodeskData ? Object.entries(autodeskData)
    .sort((a, b) => Number(b[1].total_count) - Number(a[1].total_count))
    .map(([key, value]) => ({ category: key, value: Number(value.total_count) }))
    .slice(0, 10) : [];
  
  const teamChartData = teamData ? Object.entries(teamData)
    .sort((a, b) => Number(b[1]) - Number(a[1]))
    .map(([key, value]) => ({ category: key, value: Number(value) }))
    .slice(0, 10) : [];

  const colorMap = new Map();
  let colorIndex = 0;

  autodeskChartData.forEach(item => {
    if (!colorMap.has(item.category)) {
      if (teamChartData.some(teamItem => teamItem.category === item.category)) {
        const color = colorSets[colorIndex % colorSets.length];
        colorMap.set(item.category, color);
        colorIndex++;
      }
    }
  });

  // Assign colors to autodeskChartData
  autodeskChartData.forEach(item => {
    item.color = colorMap.get(item.category) || defaultColor;
  });

  // Assign colors to teamChartData
  teamChartData.forEach(item => {
    item.color = colorMap.get(item.category) || defaultColor;
  });
  
  return { autodeskData: autodeskChartData, teamData: teamChartData };
};

export function createEdges(dataset1, dataset2) {
  const edges = [];

  dataset1.forEach((data1, index) => {
    const matchingData2 = dataset2.find(data2 => data2.category === data1.category);

    if (matchingData2) {
      edges.push({
        id: `edge-${index}`,
        source: `${data1.category}-source`,
        target: `${data1.category}-target`,
      });
    }
  });

  return edges;
}

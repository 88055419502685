import { Typography } from "@mui/material";

export default {
  
  HiringDifficulty: {
    title: "What factors does TalentNeuron use to determine Hiring Difficulty?",
    content: (
      <>
        <Typography variant="body1" gutterBottom mb={1} fontWeight="bold">
          The Hiring Difficulty information from TalentNeuron takes the following factors into account:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              Supply-demand ratio (calculated as supply divided by demand, and only applicable when supply data is available)
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Typical posting duration
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Change in demand
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Competitive concentration
            </Typography>
          </li>
        </ul>
      </>
    )
  },
  talentCompetition: {
    title: "About TalentNeuron's market competition data",
    content: (
      <ul>
        <li>
          <Typography variant="body1" gutterBottom mb={1}>
            A dispersed market (low competitive concentration) means that one is competing with a larger number of more evenly matched organizations.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" gutterBottom mb={1}>
            A concentrated market (high competitive concentration) means that one is competing with a smaller number of more dominant organizations. 
          </Typography>
        </li>
      </ul>
    )
  },
  costOfLabor: {
    title: "Why don't we show actual compensation figures?",
    content: (
      <>
        <Typography variant="body1" gutterBottom mb={1}>
          We categorize compensation into Low to High instead of displaying exact numbers. This ensures that the data is not taken as fixed or definitive as actual compensation amounts can vary widely from market value for many reasons, including the following:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              External sources typically do not take additional costs like commissions, bonuses, RSUs and fringe benefit costs into compensation benchmarks.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Compensation benchmarks, even if they're complete, often lag behind actual market values
            </Typography>
          </li>
        </ul>
        <Typography variant="body1" gutterBottom mt={1}>
          By using categories, we aim to provide a directional and more flexible view of compensation trends for roles across locations that can be used as a point of comparison.
        </Typography>
      </>
    )
  }, 
  supplyDemand: {
    title: "About TalentNeuron's supply and demand data",
    content: (
      <>
        <Typography variant="body1" gutterBottom mb={1}>
          TalentNeuron defines supply as the estimated number of employed people in the workforce that meet the selected criteria (i.e., role, location and if applicable, skills and other keywords). It can be used to approximate the talent pool for the selected role and location. Note that not every location has supply data.


        </Typography>
        
        <Typography variant="body1" gutterBottom mt={1}>
          Demand is the number of job postings that meet the selected criteria. Note that the demand numbers we extract from TalentNeuron include job postings from staffing firms and anonymous employers.
        </Typography>
      </>
    )
  }, 
  diversity: {
    title: "About TalentNeuron's diversity data",
    content: (
      <>
        <Typography variant="body1" gutterBottom mb={1}>
          <b>Gender Diversity</b> is the estimated population of employed people in the workforce that meet the selected criteria, broken down into men and women. TalentNeuron provides this data for United States, South Africa, United Kingdom, Canada, Spain, Netherlands and Mexico.
        </Typography>
        
        <Typography variant="body1" gutterBottom mt={1}>
          <b>Ethnicity Diversity</b> is the estimated population of employed people in the workforce that meet the selected criteria, broken down by the ethnicity categories used in that country. TalentNeuron provides this data for United States, South Africa and the United Kingdom.
        </Typography>
      </>
    )
  }
};

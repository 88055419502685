import React from "react";

import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import DateFilter from "components/DateFilter/DateFilter";

const CardListFilter = ({
  onSearch,
  onDateFilterChange,
  searchKey,
  dateFilter,
  filterValue,
  filterList,
  onFilterChange,
  onClearFilter,
  filterLabel = "Category"
}) => {

  return (
    <section className="flex justify-between">
      <Box display="flex">
        <FormControl sx={{ minWidth: 200 }} size="small">
          <InputLabel id="demo-select-small-label">{filterLabel}</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={filterValue}
            label={filterLabel}
            onChange={(e) => onFilterChange(e.target.value)}
          >
            {filterList.map((option, index) => (
              <MenuItem key={`${option}-${index}`} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* Date Filter */}
        <Box mx={3}>
          <DateFilter
            value={dateFilter}
            onChangeDate={onDateFilterChange}
          />
        </Box>

        {/* Clear Filter */}
        <Button
          variant="contained"
          onClick={onClearFilter}
        >
            Clear Filter
        </Button>
      </Box>
      {/* Search Filter */}
      <TextField
        placeholder="Search"
        size="small"
        id="outlined-start-adornment"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }}
        onChange={e => onSearch(e.target.value)}
        value={searchKey}
      />
    </section>
  );
};

export default CardListFilter;

import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

import { insightApi } from "../../apis/api";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const personaApi = insightApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllPersonas: builder.query({
      query: () => ({
        url: "/persona-admin",
        params: {
          token: Cookies.get("storedAccessToken")
        },
      }),
      providesTags: ["Personas"]
    }),
    getPersona: builder.query({
      query: ({ id }) => ({
        url: "/persona-admin",
        params: {
          token: Cookies.get("storedAccessToken"),
          id
        },
      }),
    }),
    postPersona: builder.mutation({
      query: ({ data }) => ({
        url: "/persona-admin",
        params: {
          token: Cookies.get("storedAccessToken"),
        },
        data,
        method: "POST"
      }),
      invalidatesTags: ["Personas"]
    }),
    updatePersona: builder.mutation({
      query: ({ persona_name, data }) => ({
        url: "/persona-admin",
        params: {
          token: Cookies.get("storedAccessToken"),
          persona_name
        },
        data,
        method: "PUT"
      }),
      invalidatesTags: ["Personas"]
    }),
    deletePersona: builder.mutation({
      query: ({ id }) => ({
        url: "/persona-admin",
        params: {
          token: Cookies.get("storedAccessToken"),
          id,
        },
        method: "DELETE"
      }),
      invalidatesTags: ["Personas"]
    }),
  }),
});

export const personaSlice = createSlice({
  name: "user",
  initialState,
});

export const { 
  useUpdatePersonaMutation, 
  usePostPersonaMutation, 
  useDeletePersonaMutation,
  useGetAllPersonasQuery,
  useGetPersonaQuery,
  useLazyGetPersonaQuery
} = personaApi;

import React from "react";

import { Box, Typography, Grid } from "@mui/material";
import EmployeeListeningCard from "components/EmployeeListening/EmployeeListeningCard";

const EmployeeInsightsPulse = ({ items }) => {
  return (
    <Box py={4}>
      {items.length === 0 ? (
        <Typography variant="body1" textAlign="center">No Employee Insights</Typography>
      ) : (
        <Grid container spacing={3}>
          {items.sort((a, b) => (+(a.display_order || Infinity) - +(b.display_order ||Infinity))).map(item => (
            <Grid md={4} key={item.id} item>
              <EmployeeListeningCard
                type={item.item_type}
                description={item.description}
                image={item.image}
                publishedYear={item.date}
                title={item.title}
                id={item.id}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default EmployeeInsightsPulse;

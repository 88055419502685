import { Chip } from "@mui/material";

const variantColorMap = {
  "default": {
    color: "#EFEFFF",
    activeColor: "#5F60FF",
  },
};

export const CustomChip = ({ label, variant = "default", size, isActive, onClick }) => {
  const { color, activeColor } = variantColorMap[variant];
  return <Chip label={label} size={size}
    onClick={onClick}
    sx={{ 
      backgroundColor: isActive ? activeColor : color,
      border: `1px solid ${activeColor}`,
      color: isActive ? "white" : null,
      cursor: "pointer",
      "&:hover": {
        backgroundColor: isActive ? activeColor : color,
        color: isActive ? "white" : null,
      }
    }} />;
};

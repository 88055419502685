import React, { useState } from "react";

import RecordVoiceOverOutlinedIcon from "@mui/icons-material/RecordVoiceOverOutlined";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import { useCreateEmployeeListeningMutation, useDeleteEmployeeListeningMutation, useGetAllEmployeeListeningsQuery, useLazyGetEmployeeListeningQuery, useUpdateEmployeeListeningMutation } from "store/slices/employeeListeningSlice";
import Swal from "sweetalert2";

import EmployeeInsightsManagement from "./EmployeeInsightsManagement";
import LifecycleSurveysManagement from "./LifecycleSurveysManagement";
import MoreResourcesManagement from "./MoreResourcesManagement";
import BlogEditor from "../BlogManagement/BlogEditor";
import DashboardEditor from "../DashboardManagement/DashboardEditor";
import ResearchEditor from "../ResearchManagement/CurrentResearchManagement/CurrentResearchEditor";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EmployeeListeningManagement = () => {
  const [tab, setTab] = React.useState(0);
  const { data = [], isLoading } = useGetAllEmployeeListeningsQuery();
  const [selectedEmployeeListening, setSelectedEmployeeListening] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [type, setType] = useState(null);

  const [deleteEmployeeListening] = useDeleteEmployeeListeningMutation();
  const [createEmployeeListening] = useCreateEmployeeListeningMutation();
  const [updateEmployeeListening] = useUpdateEmployeeListeningMutation();
  const [getEmployeeListening] = useLazyGetEmployeeListeningQuery();

  const allCategories = data ? data.map(item => item.category) : [];
  const categories = [...new Set(allCategories.flat())];

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleEdit = async (row) => {
    try {
      const employeeListening = await getEmployeeListening({ id: row.id }).unwrap();
      setSelectedEmployeeListening(employeeListening);
      setType(employeeListening.item_type);
    } catch (error) {
      console.error({ error });
    }
  };

  const handleClose = () => {
    setType(null);
  };

  const handleCreate = async (values) => {
    return await createEmployeeListening({
      ...values,
      data: {
        ...values.data,
        item_type: type,
        type: tab === 0 ? "pulse" : "survey"
      }
    }).unwrap();
  };

  const handleUpdate = async (values) => {
    return await updateEmployeeListening({
      ...values,
      data: {
        ...values.data,
        item_type: type,
        type: tab === 0 ? "pulse" : "survey"
      }
    }).unwrap();
  };

  const handleCreateNew = (type) => {
    setSelectedEmployeeListening(null);
    setType(type);
  };

  const handleRemove = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Employee Listening?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      try {
        await deleteEmployeeListening({ id: id }).unwrap();
        enqueueSnackbar("Research has been deleted.", { variant: "success" });
      } catch (error) {
        console.error({ error });
      }
    }
  };

  return (
    <>
      <Typography variant="h2" className=" flex items-center font-bold " mb={2}>
        <RecordVoiceOverOutlinedIcon sx={{ mr: 1 }} /> Employee Listening Management
      </Typography>
      <Box>
        <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Employee Insights (Glint)" {...a11yProps(0)} />
          <Tab label="Employee Experience Listening" {...a11yProps(1)} />
          <Tab label="More Resources" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={tab} index={0}>
        <EmployeeInsightsManagement 
          data={data.filter(v => v.type === "pulse")} 
          isLoading={isLoading}
          onEdit={handleEdit}
          onRemove={handleRemove}
          onCreateNew={handleCreateNew}
        />
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={1}>
        <LifecycleSurveysManagement 
          data={data.filter(v => v.type === "survey")} 
          isLoading={isLoading} 
          onEdit={handleEdit}
          onRemove={handleRemove}
          onCreateNew={handleCreateNew}
        />
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={2}>
        <MoreResourcesManagement 
          data={data.filter(v => v.type === "other")} 
          isLoading={isLoading} 
          onEdit={handleEdit}
          onRemove={handleRemove}
          onCreateNew={handleCreateNew}
        />
      </CustomTabPanel>
      {type === "blog" && (
        <BlogEditor
          open={type === "blog"}
          categories={categories}
          blog={selectedEmployeeListening}
          onClose={handleClose}
          onCreate={handleCreate}
          onUpdate={handleUpdate}
        />
      )}
      {type === "research" && (
        <ResearchEditor 
          open={type === "research"}
          categories={categories}
          research={selectedEmployeeListening}
          onCreate={handleCreate}
          onClose={handleClose}
          onUpdate={handleUpdate}
        />
      )}
      {type === "dashboard" && (
        <DashboardEditor 
          open={type === "dashboard"}
          categories={categories}
          dashboard={selectedEmployeeListening}
          onCreate={handleCreate}
          onClose={handleClose}
          onUpdate={handleUpdate}
        />
      )}
    </>
  );
};

export default EmployeeListeningManagement;

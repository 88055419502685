import React, { useEffect } from "react";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Breadcrumbs, Button, Link } from "@mui/material";
import InfoDialog from "components/InfoDialog";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { useGetApplicationFiltersQuery } from "store/slices/commentSlice";
import { publish } from "utils/events";
import { titleize } from "utils/slug";

import CommentContent from "./CommentContent/CommentContent";
import { COMMENTS_APP_DATA_LOADING_EVENT } from "./constants";
import { DateFilter } from "./filters/DateFilter";
import GeoAndDivisionFilter from "./filters/GeoAndDivisionFilter";
import SearchFilter from "./filters/SearchFilter";

const CommentPage = () => {
  const [currentParams] = useSearchParams();

  const { data: appFilters, isLoading: isFilterLoading } = useGetApplicationFiltersQuery({ page_name: "comments-analysis" });
  const surveyDates = appFilters?.["comments-analysis"]?.survey_date ?? [];
  const dateFilters = appFilters?.["comments-analysis"] ?? {};

  const date = currentParams.get("dates");
  const searchQuery = currentParams.get("search");

  // After 10 mins publish event to end any pending data loading
  useEffect(() => {
    const timer = setTimeout(() => {
      publish(`${COMMENTS_APP_DATA_LOADING_EVENT}:End`);
    }, 5 * 60 * 1000);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  return (
    <Box mx={4}>
      <div className="mb-6 pt-10 mx-10">
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} to="/">
            Home
          </Link>
          <Link component={RouterLink} to={`/${window.location.href.split("/")[3]}`}>
            {titleize(window.location.href.split("/")[3])}
          </Link>
        </Breadcrumbs>
        <div className="flex items-center justify-between">
          <div>
            <span className="text-2xl flex gap-2 font-semibold">
              Employee Insights Survey - Comment Analysis
              <InfoDialog 
                triggerComponent={<span className="text-sm underline cursor-pointer"> Learn more</span>}
                title="More about Employee insight survey - Comment analysis"
                description={<div className="flex flex-col gap-4">
                  <div>Powered by AutodeskGPT, this app semantically analyzes survey comments to identify themes. </div>
                  <div>Semantic analysis in employee surveys uncovers the meaning and sentiment behind comments by examining themes, emotions, and context, rather than just word frequency. This provides valuable insights from open-ended feedback.</div>
                  <div>Read more about how analysis is done <a className="text-blue-500 cursor-pointer">here</a>.</div>
                </div>}
              />
            </span>
            <span className="text-sm block text-[#6b7280]">
              Amplify is developed in house to better help us understand the feedback from employee comments on the Employee Insights (Glint) survey. Powered by PISD Natural Language Processing Engine (NLP) & AutodeskGPT
            </span>
          </div>
          <div className="min-w-fit flex items-center">
            <Button endIcon={<OpenInNewIcon />} variant="outlined" sx={{ marginRight: 2 }} onClick={() => { window.open("https://app.us1.glintinc.com/session/auth", "_blank"); }}>Open Glint</Button>
            <DateFilter surveyDates={surveyDates} isFilterLoading={isFilterLoading} />
          </div>
        </div>
      </div>

      <div className="bg-[#f9f9f9] p-3 rounded-md mb-6">
        <GeoAndDivisionFilter filters={dateFilters[date] ?? {}} isLoading={isFilterLoading} />
      </div>

      <div className="mb-6">
        <SearchFilter dateFilters={dateFilters[date] ?? {}} isLoading={isFilterLoading} searchQuery={searchQuery} />
      </div>
      
      {/* <p className="text-xl font-semibold flex items-center gap-2 mb-6">
        {filteredComments.length > 0 ?<span>{filteredComments.length} comments</span> : null}
        {
          dates.map((date) => {
            return <Chip key={date} label={dayjs(date).format("MMM' YY")} />;
          })
        }
      </p> */}

      {date ? 
        <CommentContent />
        : null
      }
      
    </Box>
  );
};

export default CommentPage;

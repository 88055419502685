import React, { useEffect } from "react";

import { Box, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useGetLocationFiltersQuery, useLazyGetLocationInsightsQuery } from "store/slices/locationSlice";

import TimezoneCompatibility from "./TimezoneCompatibility";
import TimezoneDistribution from "./TimezoneDistribution";
import Question from "../../../components/Question";

const TimezoneDetail = () => {
  const [currentParams] = useSearchParams();
  const [getLocationDetail] = useLazyGetLocationInsightsQuery();
  const { data: filterData } = useGetLocationFiltersQuery();
  const filterOptions = filterData?.["location-insights-internal"] || {};
  // add check for if baseline_timezone is an array 
  const baseTimezoneOptions = Array.isArray(filterOptions.baseline_timezone) 
    ? [...filterOptions.baseline_timezone].sort() : [];

  const { data, isLoading } = useSelector((state) => state.location);

  const getData = async (params) => {
    try {
      getLocationDetail({ params }).unwrap();
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if(currentParams.size > 0) {
      const params = Object.fromEntries(currentParams.entries());
      getData(params);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentParams]);

  const timezoneDistribution = data?.metrics?.find((item) => item.metric_name === "tz_distribution");
  const timezoneCapability = data?.metrics?.find((item) => item.metric_name === "tz_compatibility");

  if(isLoading) {
    return (
      <Box className="flex items-center justify-center w-full">
        <BeatLoader color="#5C5470" />
      </Box>
    );
  }
  return (
    <Box>
      <Question text="Does this location have  a suitable timezone?" />
      <Grid container spacing={4} mt={4}>
        <Grid item lg={6} xs={12}>
          {timezoneDistribution?.value ? (
            <TimezoneCompatibility data={timezoneCapability?.value} />
          ) : (
            <Typography variant="body1" textAlign="center">No data available</Typography>
          )}
        </Grid>
        <Grid item lg={12} xs={12}>
          {(timezoneDistribution && baseTimezoneOptions.length > 0) ? (
            <TimezoneDistribution timezoneDistribution={timezoneDistribution} baseTimezoneOptions={baseTimezoneOptions} />
          ) : (
            <Typography variant="body1" textAlign="center">No data available</Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default TimezoneDetail;

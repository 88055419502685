import React from "react";

import { Box, Typography } from "@mui/material";

const AttritionGlobalBenchmark = ({ data }) => {
  if(!data) {
    return (
      <Typography variant="body1">No data available</Typography>
    );
  }

  const voluntary = data.voluntary;
  const inVoluntary = data.involuntary;
  const total = data.total;
  const label = data.label;

  return (
    <Box display="flex">
      <Box display="flex" width="25%" justifyContent="center" flexDirection="column" border="1px solid grey" mr={2}>
        <Typography variant="h1" textAlign="center" fontSize="32px" fontWeight="bold">
          {(Math.round(total * 10) / 10).toFixed(1)}%
        </Typography>
        <Typography variant="h6" textAlign="center" fontWeight="bold" mt={2}>
          {label}
        </Typography>
      </Box>
      <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="space-between" my={-1}>
        <Box width="400px" border="1px solid grey" my={1} p={2} textAlign="center">
          <Typography variant="body1" fontSize="24px" fontWeight="bold">
            {(Math.round(voluntary * 10) / 10).toFixed(1)}%
          </Typography>
          <Typography variant="body1" fontSize="16px">
             Voluntary Attrition Benchmark
          </Typography>
        </Box>
        <Box width="400px" border="1px solid grey" my={1} p={2} textAlign="center">
          <Typography variant="body1" fontSize="24px" fontWeight="bold">
            {(Math.round(inVoluntary * 10) / 10).toFixed(1)}%
          </Typography>
          <Typography variant="body1" fontSize="16px">
            Involuntary Attrition Benchmark
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AttritionGlobalBenchmark;

import React, { useRef, useState } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, IconButton, FormControl, InputLabel, Select, MenuItem, Button } from "@mui/material";
import JourneyCard from "components/LocationInsights/JourneyCard";
import * as XLSX from "xlsx";

const MatrixColumns = [
  ["office_function", "Primary Office Function(s)"],
  ["office", "Office"],
  ["office_size", "Office Size"],
  ["total_headcount", "Total Headcount"],
  ["total_open_requisitions", "Total Requisitions"],
  ["regular_headcount", "Regular Headcount"],
  ["yoy_hc_change", "YoY Headcount Change"],
  ["yoy_regular_hc_change", "YoY Regular Headcount Change"],
  ["yoy_attrition_rate", "YoY Attrition Rate"],
  ["annual_avg_attendance_rate", "Annual Avg Attendance Rate"],
  ["engagement_score", "Engagement Score"],
];
    
const getColumns = (data) => {
  return MatrixColumns.filter(([key]) => data.some((d) => d[key]));
};

const sortOrder = ["Large (Hub)", "Medium", "Small"];

const LocationInsightsHubMatrix = ({ data, onRowClick }) => {
  const dataColumns = getColumns(data?.value ?? []);
  const [officeFuntion, setOfficeFunction] = useState("");
  const [geo, setGeo] = useState("");
  const [officeSize, setOfficeSize] = useState("");
  const tableRef = useRef(null);
  
  const officeFilterOptions = [...new Set(data?.value?.map((d) => d.office_function.split(", ")).flat() ?? [])];
  const geoFilterOptions = [...new Set(data?.value?.map((d) => d.geo) ?? [])];
  const officeSizeFilterOptions = [...new Set(data?.value?.map((d) => d.office_size) ?? [])];

  const filterData = (data?.value ?? []).filter((d) => {
    return (!officeFuntion || d.office_function.includes(officeFuntion)) && (!geo || d.geo === geo) && (!officeSize || d.office_size === officeSize);
  });

  const handleDownload = () => {
    if(tableRef.current) {
      const wb = XLSX.utils.table_to_book(tableRef.current);
      XLSX.writeFile(wb, `HubMatrix${officeFuntion ? `_${officeFuntion}` : ""}${geo ? `_${geo}` : ""}.xlsx`);
    }
  };

  return (
    <div>
      <br/>
      <h2 className="text-xl font-extrabold my-4">Office Location Matrix</h2>
      <Box className="flex items-center gap-2 mb-4">
        <FormControl sx={{ width: "300px" }}>
          <InputLabel id="office-function" sx={{ fontWeight: "bold" }}>Primary Office Function</InputLabel>
          <Select
            labelId="office-function"
            id="office-function-select"
            value={officeFuntion}
            label="Primary Office Function"
            onChange={(e) => setOfficeFunction(e.target.value)}
            sx={{ fontWeight: "bold" }}
            endAdornment={
              officeFuntion ? (
                <IconButton
                  size="small"
                  onClick={() => setOfficeFunction("")}
                  sx={{ marginRight: "10px" }}
                >
                  <ClearIcon />
                </IconButton>
              ) : null
            }
          >
            {officeFilterOptions.map(item => (
              <MenuItem key={item} value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ width: "300px" }}>
          <InputLabel id="geo" sx={{ fontWeight: "bold" }}>Geo</InputLabel>
          <Select
            labelId="geo"
            id="geo-select"
            value={geo}
            label="Geo"
            sx={{ fontWeight: "bold" }}
            onChange={(e) => setGeo(e.target.value)}
            endAdornment={
              geo ? (
                <IconButton
                  size="small"
                  onClick={() => setGeo("")}
                  sx={{ marginRight: "10px" }}
                >
                  <ClearIcon />
                </IconButton>
              ) : null
            }
          >
            {geoFilterOptions.map(item => (
              <MenuItem key={item} value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ width: "300px" }}>
          <InputLabel id="office-size" sx={{ fontWeight: "bold" }}>Office Size</InputLabel>
          <Select
            labelId="office-size"
            id="office-size-select"
            value={officeSize}
            label="Office Size"
            sx={{ fontWeight: "bold" }}
            onChange={(e) => setOfficeSize(e.target.value)}
            endAdornment={
              officeSize ? (
                <IconButton

                  size="small"
                  onClick={() => setOfficeSize("")}
                  sx={{ marginRight: "10px" }}
                >
                  <ClearIcon />
                </IconButton>
              ) : null
            }
          >
            {officeSizeFilterOptions.map(item => (  
              <MenuItem key={item} value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button variant="outlined" startIcon={<FileDownloadOutlinedIcon />} onClick={handleDownload} className="!ml-auto">
            Download
        </Button>
      </Box>
      { filterData.length > 0 ?
        <TableContainer component={Paper}>
          <Table ref={tableRef}>
            <TableHead>
              <TableRow>
                {dataColumns.map(([key, label]) => (
                  <TableCell key={key}><span className="font-bold text-xl">{label}</span></TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {[...filterData].sort((a, b) => {
                return b.total_headcount - a.total_headcount;
              }).map((row, index) => {
                const isHub = row.office_size.includes("Hub");
                return (
                  <TableRow
                    key={index}
                    onClick={() => onRowClick(row.office)}
                    className={
                      isHub ? 
                        "cursor-pointer transition-shadow duration-300 ease-in-out hover:shadow-md mb-2 bg-gradient-to-r from-transparent from-70% to-blue-300 border-b-2" 
                        : ""
                    }
                  >
                    {
                      dataColumns.map((column, idx) => (
                        <TableCell key={column[0]}>
                          <span className={isHub && idx === 0 ? "text-blue-400": ""}>{row[column[0]]}</span>
                        </TableCell>
                      ))
                    }
                  </TableRow>
                ); })}
            </TableBody>
          </Table> 
        </TableContainer> :
        <Box className="flex items-center justify-center w-full h-full">
                    No data available
        </Box>
      }
      <div className="mt-4">
        <JourneyCard />
      </div>
    </div>
  );
};

export default LocationInsightsHubMatrix;

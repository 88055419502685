import React from "react";

import BiotechOutlinedIcon from "@mui/icons-material/BiotechOutlined";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import Typography from "@mui/material/Typography";

import CurrentResearchManagement from "./CurrentResearchManagement/CurrentResearchManagement";
import UpcomingResearchManagement from "./UpcomingResearchManagement/UpcomingResearchManagement";

const ResearchManagement = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Typography variant="h2" className=" flex items-center font-bold " mb={2}>
        <BiotechOutlinedIcon sx={{ mr: 1 }} /> Research Management
      </Typography>

      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Current Research" value="1" />
            <Tab label="Upcoming Research" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ p: 0 }}>
          <CurrentResearchManagement />
        </TabPanel>
        <TabPanel value="2" sx={{ p: 0 }}>
          <UpcomingResearchManagement />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default ResearchManagement;

import React, { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { ColorTheme4 } from "theme/chart";

const OccupancyChart = ({ data }) => {
  useLayoutEffect(() => {
    // Create root element
    let root = am5.Root.new("occupancy-chart");

    root.setThemes([ColorTheme4.new(root)]);
    root._logo.dispose();

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        layout: root.verticalLayout
      })
    );

    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "none"
    }));
    cursor.lineY.set("visible", false);

    // Create axes
    let xRenderer = am5xy.AxisRendererX.new(root, {
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
      minGridDistance: 30,
      minorGridEnabled: true
    });

    let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
      baseInterval: { timeUnit: "month", count: 1 },
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(root, {})
    }));

    xRenderer.grid.template.setAll({
      location: 1
    });

    xRenderer.labels.template.setAll({
      rotation: -45,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15
    });

    xAxis.data.setAll(data);

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      min: 0,
      renderer: am5xy.AxisRendererY.new(root, {
        strokeOpacity: 0.1
      })
    }));

    // Add y-axis title
    yAxis.children.push(
      am5.Label.new(root, {
        rotation: -90,
        text: "Attendance Rate (%)",
        centerY: am5.p50,
        dx: -100, // Adjust this value as needed
        dy: 300
      })
    );


    // Add series
    // Smoothed line series
    let series = chart.series.push(am5xy.SmoothedXLineSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "attendance",
      valueXField: "month",
      tooltip: am5.Tooltip.new(root, {
        labelText: "{valueY}"
      })
    }));

    series.fills.template.setAll({
      visible: true,
      fillOpacity: 0.2
    });
      
    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationY: 0,
        sprite: am5.Circle.new(root, {
          radius: 4,
          stroke: root.interfaceColors.get("background"),
          strokeWidth: 2,
          fill: series.get("fill")
        })
      });
    });
      

    series.data.setAll(data);

    return () => {
      root.dispose();
    };
  }, [data]);

  return <div id="occupancy-chart" style={{ width: "100%", height: "500px" }} />;
};

export default OccupancyChart;

import React from "react";

import { Box, Link, Typography } from "@mui/material";
import LocationInsightCard from "components/LocationInsights/LocationInsightCard";
import { useParams, useSearchParams, Link as RouterLink } from "react-router-dom";

const HeadcountOverview = ({ data }) => {
  const [currentParams] = useSearchParams();
  const { id } = useParams();
  const { "Total (excl. Assoc & OSP)": totalValue, ...rest } = data?.value || {};

  const Data = {
    "Worker Type": {
      "Employees": rest["Employees"],
      "Contingents (excl. Assoc & OSP)": rest["Contingents (excl. Assoc & OSP)"],
    },
    "Management Level": {
      "Directors and above": rest["Directors and above"],
      "Managers": rest["Managers"],
      "Individual Contributors": rest["Individual Contributors"],
    }
  };

  return (
    <LocationInsightCard title="Headcount" data={data?.value}>
      <Box display="flex" mt={1}>
        <Box width="160px" mr={2} display="flex" flexDirection="column">
          
          <Box display="flex" justifyContent="center" flexDirection="column" border="1px solid grey" flex="1">
            <Typography variant="h1" textAlign="center" fontSize="32px" fontWeight="bold" >
              {totalValue}
            </Typography>
          </Box>
        </Box>
        <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="space-between" my={-1}>
          {Object.entries(Data).map(([key, value]) => (
            <Box key={key}>
              <Box display="flex" flexDirection="column" justifyContent="space-between" my={1} py={1} bgcolor="black" color="white">
                <Typography variant="h6" textAlign="center" fontSize="16px" fontWeight="bold">
                  {key}
                </Typography>
              </Box>
              <Box border="1px solid grey" my={1} p={2}>
                {Object.entries(value).map(([key, value]) => (
                  <Box key={key} display="flex" alignItems="center" my={1} justifyContent="space-between">
                    <Typography variant="body1" fontSize="14px" fontWeight="bold" mr={2}>
                      {key}:
                    </Typography>
                    <Typography variant="body1" fontSize="20px" fontWeight="bold">
                      {value}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box mt={4}>
        <Link 
          component={RouterLink} 
          sx={{ cursor: "pointer", fontSize: "16px", fontWeight: "bold", color:"blue", mb: 2 }} 
          to={`/dashboard/${id}/location-insights/internal-view/detail/headcount?${currentParams.toString()}`}>
            View Headcount Details
        </Link>
      </Box>
    </LocationInsightCard>
  );
};

export default HeadcountOverview;

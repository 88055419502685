import React from "react";

import { Box, Chip, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";

export function getLocation(query) {
  const { city, country, geo, job_title: job } = query;
  
  if(city && city !== "All") {
    return { city, job };
  }
  if(country) {
    return { country, job };
  }

  return { geo: geo || "All", job };
}

const FilterChips = () => {
  const [currentParams] = useSearchParams();
  const values = Object.fromEntries(currentParams.entries());

  const chipData = getLocation(values);

  return (
    <Box mt={2}>
      <Typography variant="h6" fontWeight="bold" display="inline" mr={2}>Filters:</Typography>
      {Object.entries(chipData).map(([key, value]) => (
        <Chip 
          key={key} 
          sx={{ mr: 1 }} 
          label={`${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}`} 
          variant="contained" 
        />
      ))}
    </Box>
  );
};

export default FilterChips;

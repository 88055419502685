import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Box, IconButton } from "@mui/material";
import DateFormField from "components/FormComponents/DateFormField";
import ImageUploadFormField from "components/FormComponents/ImageUploadFormField";
import RichTextFormField from "components/FormComponents/RichTextFormField";
import SelectFormField from "components/FormComponents/SelectFormField";
import TextFormField from "components/FormComponents/TextFormField";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { useLazyGetImageUploadUrlQuery, useUploadImageMutation } from "store/slices/researchSlice";

const BlogEditor = ({ onCreate, onUpdate, onClose, open, categories, blog }) => {
  const [getUploadImageUrl] = useLazyGetImageUploadUrlQuery();
  const [uploadImage] = useUploadImageMutation();
  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, control, formState: { errors, isValid }, watch, setValue } = useForm({
    defaultValues: {
      title: "",
      category: [],
      date: dayjs(),
      content:  "",
      description:  "",
      otherCategory: "",
      image: "",
    },
    mode: "all"
  });

  useEffect(() => {
    if(blog) {
      setValue("title", blog.title);
      setValue("category", blog.category);
      setValue("content", blog.content);
      setValue("description", blog.description);
      setValue("image", blog.image);
      setValue("date", dayjs(blog.date, "YYYY-MM-DD"));
    } else {
      setValue("title", "");
      setValue("category", []);
      setValue("content", "");
      setValue("description", "");
      setValue("image", "");
      setValue("date", dayjs());
    }
  }, [blog]);

  const img = watch("image");

  const bgImage = img 
    ? typeof img === "string" 
      ? img  
      : URL.createObjectURL(img)
    : undefined;

  const onSubmit = async (values) => {
    let uploadImgUrl = values.image, ext;
    try {
      setIsLoading(true);
      if(bgImage && typeof values.image !== "string") {
        const url = await getUploadImageUrl().unwrap();
        await uploadImage({ data: values.image, url }).unwrap();
        uploadImgUrl = url.split("&")[0];
      }
      const { category, otherCategory, ...otherValues } = values;
      if(blog) {
        await onUpdate({
          id: blog.id,
          ext: ext,
          data: {
            ...otherValues,
            date: values.date.format("YYYY-MM-DD"),
            image: uploadImgUrl,
            category: category.includes("Other") ? category.map(c => c === "Other" ? otherCategory : c) : category
          }
        });
      } else {
        await onCreate({
          ext: ext,
          data: {
            ...otherValues,
            date: values.date.format("YYYY-MM-DD"),
            image: uploadImgUrl,
            category: category.includes("Other") ? category.map(c => c === "Other" ? otherCategory : c) : category
          }
        });
      }
      onClose();
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const isOtherCategory = watch("category").includes("Other");

  return (
    <Dialog 
      open={open}
      onClose={onClose}
      maxWidth="lg"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Blog Editor</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box py={4}>
            <Grid container spacing={6}>
              <Grid item md={6}>
                <TextFormField
                  name="title"
                  label="Title"
                  type="text"
                  fullWidth
                  control={control}
                  error={errors.title}
                  rules={{
                    required: "Title is required"
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <DateFormField
                  name="date"
                  label="Date"
                  error={errors.date}
                  control={control}
                  fullWidth
                  rules={{
                    required: "Date is required"
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <SelectFormField
                  fullWidth
                  name="category"
                  label="Category"
                  error={errors.category}
                  control={control}
                  options={[...categories, "Other"]}
                  multiple
                  rules={{
                    required: "Category is required"
                  }}
                />
              </Grid>
              <Grid item md={6} display="flex" alignItems="center" gap={4}>
                <ImageUploadFormField
                  name="image"
                  control={control}
                  rules={{
                    required: "Image is required"
                  }}
                  error={errors.image}
                />
                <Box>
                  <img width={100} height={70} src={bgImage} />
                </Box>
              </Grid>
              {isOtherCategory && (
                <Grid item md={6}>
                  <TextFormField
                    name="otherCategory"
                    label="Other Category"
                    type="text"
                    fullWidth
                    control={control}
                    error={errors.otherCategory}
                    rules={{
                      required: "Other category is required"
                    }}
                  />
                </Grid>
              )}
              <Grid item md={12}>
                <TextFormField
                  name="description"
                  label="Description"
                  type="text"
                  fullWidth
                  control={control}
                  error={errors.description}
                  multiline
                  minRows={10}
                  rules={{
                    required: "Description is required"
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <RichTextFormField 
                  name="content"
                  label="Content"
                  control={control}
                  error={errors.content}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3 }}>
          <Button type="button" onClick={onClose}>Cancel</Button>
          <LoadingButton color="primary" variant="contained" type="submit" disabled={!isValid} loading={isLoading}>
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default BlogEditor;

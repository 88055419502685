export const getCommentsDownloadData = (comments = []) => {
  return comments.map(comment => {
    const { comment_id, query, themes, ...rest } = comment;
    const queryText = query?.split("text=") || "";
    const themeText = Array.isArray(themes) ? themes.join(", ") : themes;
    return {
      ...rest,
      query: queryText[1],
      themes: themeText,
    };
  });
};

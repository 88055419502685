import React, { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Typography } from "@mui/material";
import { MyTheme } from "components/LocationInsights/theme";

const labels = [ {
  category: "Very Dispersed",
  value: 100,
  columnSettings: {
    fill: am5.color(0xcce1ff)
  }
}, {
  category: "Dispersed",
  value: 100,
  // currentBullet: true,
  columnSettings: {
    fill: am5.color(0x679ae7)
  }
}, {
  category: "Slightly Dispersed",
  value: 100,
  columnSettings: {
    fill: am5.color(0x176bff)
  }
}, {
  category: "Slightly Concentrated",
  value: 100,
  columnSettings: {
    fill: am5.color(0x0d3d91)
  }
},
{
  category: "Concentrated",
  value: 100,
  columnSettings: {
    fill: am5.color(0x051b40)
  }
},
{
  category: "Very Concentrated",
  value: 100,
  columnSettings: {
    fill: am5.color(0x031027)
  }
}
];


const CompetitiveConcentration = ({ data }) => {
  return (
    <div>
      {data ? (
        <CompetitiveConcentrationChart data={data} />
      ) : (
        <Typography variant="body1">No data available</Typography>
      )}
    </div>
  );
};

export default CompetitiveConcentration;


const CompetitiveConcentrationChart = ({ data }) => {
  const chartData = labels.map(label => label.category === data ? { ...label, currentBullet: true } : label);
  // This code will only run one time
  useLayoutEffect(() => {
    const root = am5.Root.new("hiring-difficulty-status");

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      MyTheme.new(root)
    ]);
    root._logo.dispose();


    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    const chart = root.container.children.push(am5xy.XYChart.new(root, {
      layout: root.verticalLayout,
    }));


    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50
      })
    );


    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "category",
      renderer: am5xy.AxisRendererX.new(root, {

      }),
      tooltip: am5.Tooltip.new(root, {})
    }));

    const xRenderer = xAxis.get("renderer");

    xRenderer.grid.template.set("forceHidden", true);
    xRenderer.labels.template.set("forceHidden", true);

    xAxis.data.setAll(chartData);

    const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      min: 0,
      max: 800,
      strictMinMax: true,
      renderer: am5xy.AxisRendererY.new(root, {})
    }));

    const yRenderer = yAxis.get("renderer");

    yRenderer.grid.template.set("forceHidden", true);
    yRenderer.labels.template.set("forceHidden", true);


    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

    const series = chart.series.push(am5xy.ColumnSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "value",
      categoryXField: "category",
      maskBullets: false
    }));

    series.columns.template.setAll({
      //tooltipText: "{categoryX}: {valueY}",
      width: am5.p100,
      height: am5.p50,
      tooltipY: 0,
      strokeOpacity: 1,
      strokeWidth:2,
      stroke:am5.color(0xffffff),
      templateField: "columnSettings"
    });

    series.bullets.push(function(root, target, dataItem) {
      if (dataItem.dataContext.currentBullet) {
        const container = am5.Container.new(root, {});
    
        container.children.push(am5.Graphics.new(root, {
          fill: dataItem.dataContext.columnSettings.fill,
          dy: -5,
          stroke: am5.color(0xffffff),
          strokeWidth: 2,
          centerY: am5.p100,
          centerX: am5.p50,
          svgPath: "M14.4 14.4 H129.6 V129.6 H79.2 L72 144 L64.8 129.6 H14.4 Z",
        }));
    
        container.children.push(am5.Label.new(root, {
          text: dataItem.get("categoryX").split(" ").join("\n"),
          dy: -77,
          centerY: am5.p50,
          centerX: am5.p50,
          populateText: true,
          width: 100,
          height: 100,
          textAlign: "center",
          breakWords: true,
          wrap: true,
          paddingTop: 30,
          fontSize: 16,
          paddingRight: 5,
          paddingBottom: 30,
          paddingLeft: 5,
          background: am5.RoundedRectangle.new(root, {
            fill: am5.color(0xffffff),
            cornerRadiusTL: 0,
            cornerRadiusTR: 0,
            cornerRadiusBR: 0,
            cornerRadiusBL: 0,
          })
        }));

        
    
        return am5.Bullet.new(root, {
          locationY: 1,
          sprite: container
        });
      }
      return false;
    });

    series.data.setAll(chartData);

    // Add labels
    function addAxisLabel(category, text) {
      const rangeDataItem = xAxis.makeDataItem({
        category: category
      });
  
      const range = xAxis.createAxisRange(rangeDataItem);

      range.get("label").setAll({
        //fill: am5.color(0xffffff),
        text: text,
        forceHidden: false,
      });

      range.get("grid").setAll({
        //stroke: color,
        strokeOpacity: 1,
        location: 1
      });
    }

    addAxisLabel("Very Dispersed", "Very Dispersed");
    addAxisLabel("Very Concentrated", "Very Concentrated");

    return () => {
      root.dispose();
    };

  }, [data]);

  return <div id="hiring-difficulty-status" style={{ width: "100%", height: "200px" }}></div>;
};


import React from "react";

import SearchIcon from "@mui/icons-material/Search";
import { TextField, InputAdornment, Button } from "@mui/material";
import Box from "@mui/material/Box";
import DateFilter from "components/DateFilter/DateFilter";

const BlogFilter = ({
  onSearch,
  onDateFilterChange,
  onCreateNew,
  searchKey,
  dateFilter
}) => {

  return (
    <Box className="flex justify-between" mt={2} mb={3}>
      <Box>
        <TextField
          id="outlined-start-adornment"
          size="small"
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          onChange={e => onSearch(e.target.value)}
          value={searchKey}
          sx={{ mr: 2 }}
        />
        <DateFilter 
          value={dateFilter}
          onChangeDate={onDateFilterChange}
        />
      </Box>
      <Button
        onClick={onCreateNew}
        variant="contained"
        size="small"
      >
        Create New
      </Button>
    </Box>
  );
};

export default BlogFilter;

import React from "react";

import { Box, Grid, Typography } from "@mui/material";

const LeadershipDepth = ({ leadershipDepthValue, leadershipDepthRatios }) => {
  return (
    <>
      <Grid display="flex" spacing={4} container>
        <Grid item lg={5} xs={12} alignItems="center" display="flex" height="100%">
          <Box width="100%" display="flex" height="200px" justifyContent="center" flexDirection="column" border="1px solid grey" mr={2}>
            <Typography variant="h1" textAlign="center" fontSize="32px" fontWeight="bold">
              {leadershipDepthValue}
            </Typography>
            <Typography variant="h6" textAlign="center" fontWeight="bold" mt={2}>
              Location Leadership Depth
            </Typography>
          </Box>
        </Grid>
        {leadershipDepthRatios && (
          <Grid item lg={7} xs={12} display="flex" alignItems="center">
            <ul>
              {Object.values(leadershipDepthRatios).map((item, index) => (
                <li key={index} style={{ fontsize: "24px", fontWeight: "bold", marginTop: "12px", marginBottom: "12px" }}>
                  {item}
                </li>
              ))}
            </ul>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default LeadershipDepth;

import React, { useState } from "react";

import { Close } from "@mui/icons-material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Chip, InputAdornment, TextField, Autocomplete, IconButton } from "@mui/material";
import InfoDialog from "components/InfoDialog";
import useEventState from "hooks/useEventstate";
import generateAI from "img/generative-ai.svg";
import { useSearchParams } from "react-router-dom";

import { COMMENTS_APP_DATA_LOADING_EVENT } from "../constants";

const suggestedComments = [
  "Trust",
  "Reliability",
  "Capacity",
  "Transparency",
  "Care",
  "Collaboration",
  "Action Taking",
  "Decision Speed",
  "Belonging",
  "Flexible Work",
  "Connection",
  "Performance",
];

const SearchFilter = ({ searchQuery, dateFilters, isLoading }) => {
  const [value, setValue] = useState(() => searchQuery || "");
  const [currentParams, setCurrentParam] = useSearchParams();
  const isDataLoading = useEventState(COMMENTS_APP_DATA_LOADING_EVENT);

  const handleFilterChange = (name, value) => {
    if(!value) {
      currentParams.delete(name);
    } else {
      currentParams.set(name, value);
    }
    setCurrentParam(currentParams);
  };

  const handleSearchChange = (value, immediate) => {
    setValue(value);
    if (immediate) {
      if (value) {
        currentParams.set("search", value);
      } else {
        currentParams.delete("search");
      }
      setCurrentParam(currentParams);
    }
  };

  const handleSearch = () => {
    if (value) {
      currentParams.set("search", value);
    } else {
      currentParams.delete("search");
    }
    setCurrentParam(currentParams);
  };

  const filters = [
    {
      label: "Favorability",
      name: "favorability",
      options: dateFilters?.favorability ?? [],
      //value: getValues(currentParams, "favorability"),
      value: currentParams.get("favorability")
    },
    {
      label: "Question",
      name: "question",
      options: dateFilters?.question ?? [],
      value: currentParams.get("question")?.split("_") || []
    }
  ];

  return (
    <div>
      <Box display="flex" alignItems="center" gap={1}>
        <TextField
          value={value}
          type="text"
          onChange={(e) => { handleSearchChange(e.target.value); }}
          placeholder="Search for a topic or a phrase"
          sx={{ width: "500px", borderRadius: "5px" }}
          size="small"
          disabled={isLoading || isDataLoading}
          InputProps={{
            sx: {
              borderRadius: "5px"
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {value && (
                  <IconButton
                    size="small"
                    onClick={() => handleSearchChange("", true)}
                  >
                    <Close />
                  </IconButton>
                )}
                <img src={generateAI} alt="ai" />
              </InputAdornment>
            )
          }}
        />
        <IconButton color="primary" onClick={handleSearch}>
          <ArrowForwardIcon />
        </IconButton>
        <div className="flex items-center gap-2 ml-auto">
          {
            filters.map((filter) => (
              <Autocomplete
                key={filter.name}
                multiple={filter.name === "question"}
                options={filter.options}
                getOptionLabel={(option) => option}
                value={filter.value}
                size="small"
                disabled={isLoading || isDataLoading}
                sx={{ minWidth: 250 }}
                onChange={(_e, values)=>handleFilterChange(filter.name, values)}
                ChipProps={{
                  variant: "contained",
                  color: "primary",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={filter.label}
                    placeholder={filter.label}
                  />
                )}
              />
            ))
          }
          
        </div>
      </Box>
      <span className="text-sm flex gap-2 text-[#6b7280] mt-1">
      Customize the narratives by using the filters or search bar.
        <InfoDialog 
          triggerComponent={<span className="text-sm underline cursor-pointer"> Learn more</span>}
          title={"Search Topics"}
          description="Enter the topic you would to understand better, or choose from one of the pre built topics below.  The application will search through comments to build narrative themes based on your search"
        />
      </span>
      <Box pt={1}>
        {suggestedComments.map((comment, index) => (
          <Chip 
            variant={value === comment ? "contained" : "outlined"}
            key={index} 
            label={comment} 
            color="primary"
            sx={{ m: 0.5 }}
            disabled={isLoading}
            onClick={() => handleSearchChange(comment, true)}
          />
        ))}
      </Box>
    </div>
  );
};

export default SearchFilter;

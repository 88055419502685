import React from "react";

import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";

const TextFormField = ({
  control,
  name,
  rules,
  error,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) =>
        <TextField
          {...field}
          error={!!error}
          helperText={error?.message}
          {...props}
        />
      }
    />
  );
};

export default TextFormField;

import React, { useEffect } from "react";

// import ModeStandbyIcon from "@mui/icons-material/ModeStandby";
import { Box, Grid, Typography } from "@mui/material";
import Information from "components/LocationInsights/Information";
import MoreInformationPopover from "components/MoreInformationPopover/MoreInformationPopover";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useLazyGetExternalLocationInsightsQuery } from "store/slices/locationSlice";

import ActiveEmployerRanking from "./ActiveEmployerRanking";
import CompetitiveConcentration from "./CompetitiveConcentration";
import Competitors from "./Competitors";
import Question from "../../../components/Question";
import helpText from "../helpText";


const TalentCompetitionDetail = () => {
  const [currentParams] = useSearchParams();
  const [getLocationDetail] = useLazyGetExternalLocationInsightsQuery();
  const { externalData, isLoading } = useSelector((state) => state.location);

  useEffect(() => {
    const params = Object.fromEntries(currentParams.entries());
    getLocationDetail({ params });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentParams]);

  if(isLoading) {
    return (
      <Box className="flex items-center justify-center w-full">
        <BeatLoader color="#5C5470" />
      </Box>
    );
  }

  if(!externalData || externalData.length !== 1) {
    return (
      <Box mt={20}>
        <Typography variant="body1" textAlign="center" fontWeight="bold" fontSize="24px">
          No data available. Try selecting or changing the location and job title filters
        </Typography>
      </Box>
    );
  } 

  const activeEmployerRanking = externalData[0].details_actively_hiring;
  const competitors = externalData[0].details_competition;
  const competitiveConcentrationData = externalData[0].competitiveConcentration;
  
  return (
    <div>
      <Question text="What is the competitive landscape for talent in this location?" />
      <Information>
        <b>TalentNeuron</b> defines competitive concentration as to the level of competition one faces in trying to hire a candidate in a particular location. {" "}
        <br />
        <br />
        <MoreInformationPopover helpText={helpText.talentCompetition}/>
      </Information>
      <Grid container spacing={4} mt={2}>
        <Grid item xs={12} lg={12}>
          <Box>
            <Typography variant="h4" fontWeight="bold" my={4}>
              Competitive Concentration
            </Typography>
            <CompetitiveConcentration data={competitiveConcentrationData} />
          </Box>
        </Grid>
        <Grid item lg={6}>
          <Competitors data={competitors} />
        </Grid>
        <Grid item lg={6}>
          <ActiveEmployerRanking data={activeEmployerRanking} />
        </Grid>
      </Grid>
    </div>
  );
};

export default TalentCompetitionDetail;

import { AUTH, REDIRECT_URL } from "constants/env";

import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";  
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { getRoutes } from "route";
import { setPages, useLazyGetCurrentUserQuery } from "store/slices/currentUserSlice";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

const redirectToAuthPage = () => {
  window.location.href = AUTH;
};


const storedAccessToken = Cookies.get("storedAccessToken");

function AuthPage() {
  const [getUser] = useLazyGetCurrentUserQuery();
  const [code, setCode] = useState("");
  const storedCode = Cookies.get("code");
  const [, setToken] = useState("");
  const codeParam = new URL(window.location.href).searchParams.get("code");
  const path = Cookies.get("path");
  const cookiePages = Cookies.get("pages");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const pages = useSelector((state) => state.currentUser.pages);

  useEffect(() => {
    if(cookiePages) {
      dispatch(setPages(cookiePages.split(",")));
    }
  }, [cookiePages]);
  
  useEffect(() => {
    if(!path) {
      Cookies.set("path", window.location.href.replace(REDIRECT_URL, ""));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (code) {
      Cookies.set("code", code);
      setCode(code);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserInfo = async (authCode) => {
    try {
      setIsLoading(true);
      const data = await getUser({
        type: "authCode",
        code: authCode,
      }).unwrap();
      // triggering new rerendering to get the updated cookie
      setToken(data.access_token);
      Cookies.set("storedAccessToken", data.access_token);
      Cookies.set("pages", data.pages);
      Cookies.set("email", data.email);
      dispatch(setPages(data.pages));
      setIsLoading(false);
      // removing code query param from url
      window.history.replaceState({}, document.title, path || REDIRECT_URL);
    } catch (error) {
      console.error({ error });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!storedCode) {
      if (codeParam) {
        Cookies.set("code", codeParam);
        setCode(codeParam);
      } else {
        redirectToAuthPage();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (storedCode && !storedAccessToken) {
      getUserInfo(storedCode);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedCode, storedAccessToken]);

  const content = useRoutes(getRoutes(pages));

  if (isLoading) {
    return (
      <Box className="flex items-center justify-center h-full">
        <BeatLoader color="#5C5470" />
      </Box>
    );
  }

  return (
    <div>
      {content}
    </div>
  );
}

export default AuthPage;


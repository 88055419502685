import React, { useState } from "react";

import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import { Box, Grid } from "@mui/material";
import { Pagination } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import BeatLoader from "react-spinners/BeatLoader";
import { useGetAllDashboardsQuery, useDeleteDashboardMutation, useUpdateDashboardMutation, usePostDashboardMutation, useLazyGetDashboardQuery } from "store/slices/dashboardSlice";
import Swal from "sweetalert2";
import { getCurrentPageData, getPageCount } from "utils/pagination";

import DashboardEditor from "./DashboardEditor";
import DashboardFilter from "./DashboardFilter";
import Tile from "./Tile";

const ITEM_PER_PAGE = 16;

const DashboardManagement = () => {
  const [searchKey, setSearchKey] = useState("");
  const [page, setPage] = useState(1);
  const [selectedDashboard, setSelectedDashboard] = useState(null);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [deleteDashboard] = useDeleteDashboardMutation();
  const [updateDashboard] = useUpdateDashboardMutation();
  const [createDashboard] = usePostDashboardMutation();
  const [getDashboard] = useLazyGetDashboardQuery();

  const { data: dashboards = [], isLoading } = useGetAllDashboardsQuery();

  const searchedDashboards = dashboards.filter(dashboard => dashboard.title.toLowerCase().includes(searchKey.toLowerCase()));

  const pageData = getCurrentPageData(searchedDashboards, ITEM_PER_PAGE, page);

  const handleEdit = async (id) => {
    try {
      const dashboard = await getDashboard({ id }).unwrap();
      setSelectedDashboard(dashboard);
      setOpen(true);
    } catch (error) {
      console.log({ error });
    }
  };

  const handleRemove = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Dashboard",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      await deleteDashboard({ id: id }).unwrap();
      enqueueSnackbar("Dashboard has been deleted.", { variant: "success" });
    }
  };

  const handleCreateNew = () => {
    setSelectedDashboard(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedDashboard(null);
  };

  const handleSearch = (query) => {
    setSearchKey(query);
  };

  const handleChangePage = (_event, page) => {
    setPage(page);
  };

  const handleCreate = async (values) => {
    await createDashboard(values).unwrap();
  };

  const handleUpdate = async (values) => {
    await updateDashboard(values).unwrap();
  };

  return (
    <>
      <Typography variant="h2" className="font-mono text-gray-700 font-bold" mb={2}>
        <DashboardOutlinedIcon sx={{ mr: 1 }} /> Dashboard Management
      </Typography>
      <div>
        {/* Filter */}
        <DashboardFilter
          onCreateNew={handleCreateNew}
          searchKey={searchKey}
          onSearch={handleSearch}
        />
      </div>
      {isLoading ? (
        <Box className="text-center" py={8}>
          <BeatLoader color="#5C5470" />
        </Box>
      ) : (
        <Grid container spacing={2} minHeight="624px">
          {pageData.map((tile) => (
            <Grid key={tile.id} md={3} item>
              <Tile
                dashboard={tile}
                onClickDelete={handleRemove}
                onClickEdit={handleEdit}
              />
            </Grid>
          ))}
        </Grid>
      )}
      <Box display="flex" justifyContent="center" width="100%" py={8}>
        <Pagination 
          count={getPageCount(searchedDashboards.length, ITEM_PER_PAGE)} 
          variant="outlined" 
          shape="rounded" 
          onChange={handleChangePage}
        />
      </Box>
      <DashboardEditor
        open={open}
        dashboard={selectedDashboard}
        onClose={handleClose}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
      />
    </>
  );
};

export default DashboardManagement;


export const ENV = "prod"; // this can be 'dev', 'stage', 'prod'

let BASE_URL, AUTH, REDIRECT_URL;

if(ENV === "prod") {
  BASE_URL="https://76clz1n9xk.execute-api.us-east-2.amazonaws.com/production";
  REDIRECT_URL="https://people-insights.autodesk.com";
  AUTH="https://people-insights.auth.us-east-2.amazoncognito.com/oauth2/authorize?client_id=486jdp55loudfoo165o9ple2ir&response_type=code&scope=email+openid&redirect_uri=https%3A%2F%2Fpeople-insights.autodesk.com";
} else if (ENV === "stage") {
  BASE_URL="https://76clz1n9xk.execute-api.us-east-2.amazonaws.com/stage_20230629_v2";
  REDIRECT_URL="https://d1ovk7y7rzi297.cloudfront.net";
  AUTH="https://people-insights.auth.us-east-2.amazoncognito.com/oauth2/authorize?client_id=486jdp55loudfoo165o9ple2ir&response_type=code&scope=email+openid&redirect_uri=https%3A%2F%2Fd1ovk7y7rzi297.cloudfront.net";
} else {
  BASE_URL="https://76clz1n9xk.execute-api.us-east-2.amazonaws.com/stage_20230629_v2";
  REDIRECT_URL="http://localhost:3000";
  AUTH="https://people-insights.auth.us-east-2.amazoncognito.com/oauth2/authorize?client_id=486jdp55loudfoo165o9ple2ir&response_type=code&scope=email+openid&redirect_uri=http://localhost:3000";
}

export {
  BASE_URL,
  AUTH,
  REDIRECT_URL
};

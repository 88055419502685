import React, { useEffect, useRef, useState } from "react";

import DownloadIcon from "@mui/icons-material/Download";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, Tooltip, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { convertToTitleCase } from "./utils";

const ManagementFilter = ({ filterOptions, onDownload }) => {
  const [currentParams, setCurrentParams] = useSearchParams();
  const { divisionOptions, outcomeOptions } = filterOptions;

  const scrollContainerRef = useRef(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(false);
  const selectedDivision = currentParams.get("division") || "";

  useEffect(() => {
    const updateScrollButtons = () => {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setShowLeftButton(scrollLeft > 0);
      setShowRightButton(scrollLeft + clientWidth < scrollWidth);
    };

    const container = scrollContainerRef.current;
    container.addEventListener("scroll", updateScrollButtons);
    updateScrollButtons();

    return () => container.removeEventListener("scroll", updateScrollButtons);
  }, [selectedDivision]);

  const scrollLeft = () => {
    scrollContainerRef.current.scrollBy({ left: -300, behavior: "smooth" });
  };

  const scrollRight = () => {
    scrollContainerRef.current.scrollBy({ left: 300, behavior: "smooth" });
  };

  useEffect(() => {
    if(!currentParams.get("division") && divisionOptions.length) {
      currentParams.set("division", divisionOptions.includes("Autodesk") ? "Autodesk" : divisionOptions[0]);
    }
    if(!currentParams.get("outcome") && outcomeOptions.length) {
      currentParams.set("outcome", outcomeOptions[0]);
    } 
    setCurrentParams(currentParams);
  }, [currentParams, divisionOptions, outcomeOptions, setCurrentParams]);
  
  const handleChangeDivision = (event) => {
    if (event.target.value === "") {
      currentParams.delete("division");
    } else {
      currentParams.set("division", event.target.value);  
    }
    setCurrentParams(currentParams);
  };

  const handleChangeOutcome = (value) => {
    if (value === "") {
      currentParams.delete("outcome");
    } else {
      currentParams.set("outcome", value);
    }
    setCurrentParams(currentParams);
  };

  const division = currentParams.get("division") || "";
  const outcome = currentParams.get("outcome") || "";

  return (
    <Box>
      <Box>
        <Box display="flex" gap={4} alignItems="center" justifyContent="space-between">
          <Box>
            <FormControl size="small" sx={{ minWidth: 160 }}>
              <InputLabel id="demo-select-small-label">Division</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Division"
                onChange={handleChangeDivision}
                value={division}
              >
                {divisionOptions.sort((a, b) => a.localeCompare(b)).map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Tooltip title="Download JPG" arrow>
              <IconButton onClick={onDownload}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Typography variant="body1" mt={2} mb={1} gutterBottom>({outcomeOptions.length} {`${outcomeOptions.length > 1 ? "Outcomes": "Outcome"}`})</Typography>
        </Box>
        <Typography variant="body1" mt={2} mb={1} gutterBottom>Select an outcome variable to visualize its decision tree and predicted scores:</Typography>
        <Box position="relative">
          {showLeftButton && (
            <IconButton onClick={scrollLeft} sx={leftButtonStyle}>
              <KeyboardArrowLeftOutlinedIcon />
            </IconButton>
          )}
          <Box overflow="auto" display="flex" pb={2} gap={2} ref={scrollContainerRef}>
            {outcomeOptions.sort((a, b) => a.localeCompare(b)).map((item) => (
              <Box key={item} flexGrow={1} flex="0 0 200px">
                <Button fullWidth sx={{ height: "100%", width: "100%", textTransform: "capitalize" }} flex={1} onClick={() => handleChangeOutcome(item)} color="success" variant={outcome === item ? "contained" : "outlined"}>
                  <Box>
                    <Typography variant="h6" color={item === outcome ? "white" : "primary"} fontWeight="bold">{convertToTitleCase(item)} Decision Tree</Typography>
                  </Box>
                </Button>
              </Box>
            ))}
          </Box>
          {showRightButton && (
            <IconButton onClick={scrollRight} sx={rightButtonStyle}>
              <KeyboardArrowRightOutlinedIcon />
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ManagementFilter;

const leftButtonStyle = {
  position: "absolute",
  left: "-40px",
  top: "40%",
  transform: "translateY(-50%)",
  zIndex: 1,
};

const rightButtonStyle = {
  position: "absolute",
  right: "-40px",
  top: "40%",
  transform: "translateY(-50%)",
  zIndex: 1,
};


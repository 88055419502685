import React, { useEffect } from "react";

import { Box, Grid, Link, Typography } from "@mui/material";
import Information from "components/LocationInsights/Information";
import { useSelector } from "react-redux";
import { Link as RouterLink, useParams, useSearchParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useLazyGetLocationInsightsQuery } from "store/slices/locationSlice";

import HeadcountFlexElection from "./HeadcountFlexElection";
import HeadcountTypeBreakdown from "./HeadcountTypeBreakdown";
import OtherLocations from "./OtherLocations";
import TeamLevelHeadcount from "./TeamLevelHeadcount";
import Question from "../../../components/Question";

const HeadcountDetail = () => {
  const [currentParams] = useSearchParams();
  const [getLocationDetail] = useLazyGetLocationInsightsQuery();
  const { data, isLoading } = useSelector((state) => state.location);
  const { id } = useParams();

  useEffect(() => {
    const params = Object.fromEntries(currentParams.entries());
    getLocationDetail({ params });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentParams]);

  const headCountSummaryData = data?.metrics?.find((item) => item.metric_name === "headcount_summary");
  const headcountTypeBreakdownData = data?.metrics?.find((item) => item.metric_name === "headcount_worker_sub_type");
  const headcountFlexElectionData = data?.metrics?.find((item) => item.metric_name === "headcount_flex_election");
  const autodeskHeadcountData = data?.adsk_location_metrics?.find((item) => item.metric_name === "headcount_summary");
  const otherLocationsData = data?.metrics?.find((item) => item.metric_name === "other_locations_team");

  if(isLoading) {
    return (
      <Box className="flex items-center justify-center w-full">
        <BeatLoader color="#5C5470" />
      </Box>
    );
  }

  return (
    <Box>
      <Question text="What is the headcount distribution for this and all teams at this location?" />
      
      <Typography my={2}>
        This page is intended to show a subset of insights. For a deeper-dive, please review the {" "}
        <Link 
          component={RouterLink} 
          sx={{ color: "blue", cursor: "pointer" }} 
          to={`/dashboard/${id}/location-insights/tableau?url=https://people-insights-tableau.autodesk.com/views/HeadcountDashboard-New/Summary`}>
        headcount dashboard
        </Link>
        &nbsp;(requires Corporate VPN connection).
      </Typography>
      <Information>
        <Box>
          
          <Typography variant="body1" color="#353535">
            All headcount numbers exclude workers in the Associates and Outside Service Providers subtypes unless indicated otherwise.
          </Typography>          
          
        </Box>
      </Information>

      <Grid container spacing={4}>
        <Grid item xs={12} lg={12}>
          <TeamLevelHeadcount location={headCountSummaryData?.value} team={autodeskHeadcountData?.value} />
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              <HeadcountTypeBreakdown data={headcountTypeBreakdownData?.value} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <HeadcountFlexElection data={headcountFlexElectionData?.value} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <OtherLocations data={otherLocationsData?.value} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default HeadcountDetail;

import React from "react";

import CheckboxTreeView from "components/CheckboxTreeView/CheckboxTreeView";

const getFormattedTreeData = (treeDataObj, values) => {
  if (values.includes(treeDataObj.id.toString())) { 
    return treeDataObj;
  } else {
    if(treeDataObj.children && treeDataObj.children.length > 0) {
      const children = treeDataObj.children.map(child => getFormattedTreeData(child, values)).filter(Boolean);
      if(children.length > 0) {
        return {
          ...treeDataObj,
          children
        };
      }
    }
  }
};

const PermissionCheckList = ({
  value,
  treeStructure, 
  onChange,
  blockedValues = []
}) => {
  const blockedIds = Object.values(blockedValues).flat().map(value => value.id);
  const treeData = Object.entries(treeStructure).map(([key, values], index) => 
    ({ 
      id: index,  
      title: key, children: values
    })); 

  const checkedValues = Object.entries(value).map((
    [, values]) => values.map(v => v.id)).flat();

  const handleChange = (values) => {
    const overrideIds = values.filter(value => !blockedIds.includes(value));
    const updatedValue = treeData.map(treeDataObj => getFormattedTreeData(treeDataObj, overrideIds)).filter(Boolean);
    const formattedValue = updatedValue.reduce((acc, value) => (
      { ...acc, [value.title]: value.children }
    ), {});
    onChange(formattedValue);
  };

  return (
    <CheckboxTreeView 
      data={treeData} 
      defaultValue={checkedValues} 
      onChange={handleChange}
      blockedIds={blockedIds}
    />
  );
};

export default PermissionCheckList;

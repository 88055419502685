import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useSearchParams } from "react-router-dom";
import { useGetLocationFiltersQuery } from "store/slices/locationSlice";
// import { getUniqueArray } from "utils/getUniqueArray";

const LocationFilter = () => {
  const [currentParams, setCurrentParams] = useSearchParams();

  const { data: filterData } = useGetLocationFiltersQuery();
  const filterOptions = filterData?.["location-insights-internal"] || [];

  const handleChangeGeo = (event) => {
    if(event.target.value === "All") {
      currentParams.delete("geo");
    } else {
      currentParams.set("geo", event.target.value);
    }
    currentParams.delete("country");
    currentParams.delete("city");
    currentParams.delete("site");
    setCurrentParams(currentParams);
  };

  const handleChangeCountry = (value) => {
    if(!value) {
      currentParams.delete("country");
    } else {
      currentParams.set("country", value);
    }
    currentParams.delete("city");
    currentParams.delete("site");
    setCurrentParams(currentParams);
  };

  const handleChangeOfficeLocation = (value) => {
    if(!value) {
      currentParams.delete("city");
    } else {
      currentParams.set("city", value);
    }
    currentParams.delete("site");
    setCurrentParams(currentParams);
  };

  const handleChangeSite = (value) => {
    if(!value) {
      currentParams.delete("site");
    } else {
      currentParams.set("site", value);
    }
    setCurrentParams(currentParams);
  };

  const geo = currentParams.get("geo") || "All";
  const country = currentParams.get("country") || "";
  const city = currentParams.get("city") || ""; 
  const site = currentParams.get("site") || "";

  const geoOptions = filterOptions?.geo ? filterOptions?.geo : [];
  const countryOptions = filterOptions?.work_location_country && geo !== "All" ? filterOptions.work_location_country[geo] : [];
  const cityOptions = filterOptions?.work_location_city && country ? filterOptions.work_location_city[country] : [];
  const siteOptions = filterOptions?.city_site && city && city !== "All" ? filterOptions.city_site[city] : [];

  const handleClickClear = () => {
    currentParams.delete("geo");
    currentParams.delete("country");
    currentParams.delete("city");
    currentParams.delete("site");
    setCurrentParams(currentParams);
  };

  return (
    <Box>
      <Typography variant="body1" gutterBottom>Location</Typography>
      <Box sx={{ display: "flex", gap: 2 }}>
        <FormControl sx={{ minWidth: 120 }} size="small">
          <InputLabel id="geo-select-small-label">Geo</InputLabel>
          <Select
            labelId="geo-select-small-label"
            id="geo-select-small"
            label="Geo"
            value={geo}
            onChange={handleChangeGeo}
          >
            {[...geoOptions]?.sort((a, b) => a.localeCompare(b)).map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: 120 }}>
          <Autocomplete
            label="Country"
            value={country}
            onChange={(_e, values)=>handleChangeCountry(values)}
            size="small"
            ChipProps={{
              variant: "contained",
              color: "primary",
            }}
            options={[...countryOptions]?.sort((a, b) => a.localeCompare(b)) || []}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Country"
                placeholder="Country"
              />
            )}
            disabled={countryOptions.length === 0}
          />
        </FormControl>
        <FormControl size="small" sx={{ minWidth: 160 }}>
          <Autocomplete
            label="Office Location"
            value={city}
            onChange={(_e, values)=>handleChangeOfficeLocation(values)}
            size="small"
            ChipProps={{
              variant: "contained",
              color: "primary",
            }}
            options={[...cityOptions]?.sort((a, b) => a.localeCompare(b)) || []}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Office Location"
                placeholder="Office Location"
              />
            )}
            disabled={cityOptions.length === 0}
          />
        </FormControl>
        <FormControl size="small" sx={{ minWidth: 160 }}>
          <Autocomplete
            label="Site"
            value={site}
            onChange={(_e, values)=>handleChangeSite(values)}
            size="small"
            ChipProps={{
              variant: "contained",
              color: "primary",
            }}
            options={[...siteOptions]?.sort((a, b) => a.localeCompare(b)) || []}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Site"
                placeholder="Site"
              />
            )}
            disabled={siteOptions.length === 0}
          />
        </FormControl>
        {(geo.length >0 || country.length >0 || city.length >0) && (
          <IconButton size="small" onClick={handleClickClear}>
            <CloseIcon size="small" />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default LocationFilter;

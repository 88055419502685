import React from "react";

import { ListItemText, MenuList, MenuItem } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const pages = [
  {
    label: "Dashboard Management",
    path: "/admin/dashboard-management",
  },
  {
    label: "Research Management",
    path: "/admin/research-management",
  },
  {
    label: "Blog Management",
    path: "/admin/blog-management",
  },
  {
    label: "Employee Listening Management",
    path: "/admin/employee-listening-management",
  },
  {
    label: "Permission Management",
    path: "/admin/permission-management",
  },
  {
    label: "Feedback Management",
    path: "/admin/feedback-management",
  }
];

const AdminMenu = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleCloseNavMenu = (path) => {
    navigate(path);
  };

  return (
    <MenuList sx={{ mt: -4 }}>
      {pages.map((page, index) => (
        <MenuItem 
          key={index} 
          onClick={() => handleCloseNavMenu(page.path)}
          selected={pathname === page.path}
          sx={{ my: 2 }}
        >
          <ListItemText>{page.label}</ListItemText>
        </MenuItem>
      ))}
    </MenuList>
  );
};

export default AdminMenu;

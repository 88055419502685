import React from "react";

import { Typography } from "@mui/material";
import PieChart from "components/LocationInsights/PieChart";
import { ColorTheme3 } from "theme/chart";

const EducationRanking = ({ data }) => {
  return (
    <div>
      <Typography variant="h4" fontWeight="bold" my={4}>
        Education Ranking
      </Typography>
      {data ? (
        <PieChart data={data} id="education-ranking" valueKey="demandcount" labelKey="name" colorTheme={ColorTheme3} />
      ) : (
        <Typography variant="body1">No data available</Typography>
      )}
    </div>
  );
};

export default EducationRanking;

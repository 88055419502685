import * as React from "react";

import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ForestOutlinedIcon from "@mui/icons-material/ForestOutlined";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Box, Fade, IconButton, Tooltip } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Footer from "components/Footer/Footer";
import Header from "components/Header/Header";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

const getItems = (id) => ([
  {
    title: "Overview",
    icon: <ArticleOutlinedIcon />,
    to: `/employee-listening/${id}/decision-tree/overview`
  },
  {
    title: "Glint Decision Tree",
    icon: <AccountTreeOutlinedIcon sx={{ width: "20px", ml: "2px" }} />,
    to: `/employee-listening/${id}/decision-tree/glint-decision-tree`
  },
  {
    title: "Example",
    icon: <ForestOutlinedIcon sx={{ width: "20px", ml: "2px" }} />,
    to: `/employee-listening/${id}/decision-tree/example`
  },
]) ;

const GlintDecisionLandingPage = () => {
  const [expanded, setExpanded] = React.useState(true);
  const { id } = useParams();
  const items = id ? getItems(id) : [];

  const handleExpand = () => {
    setExpanded(prev => !prev);
  };

  return (
    <Box>
      <Header pageName="Glint Decision Tree" />
      <Box className="flex">
        <Paper
          sx={{
            width: expanded ? 200 : 56,
            transition: "width 0.3s",
            maxWidth: "100%",
            height: "calc(100vh - 56px)",
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            pt: 4,
            pb: 4,
            borderRadius: 0,
            flexShrink: 0,
          }}
        >
          <MenuList>
            <Box>
              <SideNavItem
                title="Back"
                icon={<ArrowBackIcon />}
                isExpanded={expanded}
                to="/employee-listening"
                sx={{ mb: 12 }}
              />
              {items.map((item, index) => (
                <SideNavItem
                  key={index}
                  title={item.title}
                  icon={item.icon}
                  isExpanded={expanded}
                  to={item.to}
                />
              ))}
            </Box>
          </MenuList>
          <SideNavExpandItem isExpanded={expanded} onClickToggleExpand={handleExpand} />
        </Paper>
        <Box height="calc(100vh - 56px)" overflow="auto">
          <Outlet />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

export default GlintDecisionLandingPage;


const SideNavExpandItem = ({
  isExpanded,
  onClickToggleExpand,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        cursor: "initial",
        alignItems: "center",
      }}
    >
      <Tooltip
        title={isExpanded ? "Collapse Navigation" : "Expand Navigation"}
        arrow
        placement="right"
      >
        <IconButton
          onClick={onClickToggleExpand}
          sx={{ mr: 0.5 }}
        >
          <ListItemIcon sx={{ minWidth: "28px !important" }}>
            {isExpanded ? (
              <KeyboardDoubleArrowLeftIcon />
            ) : (
              <KeyboardDoubleArrowRightIcon />
            )}
          </ListItemIcon>
        </IconButton>
      </Tooltip>
    </Box>
  );
};

const SideNavItem = ({ title, icon, isExpanded, to, ...props }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(to);
  };
  const { pathname } = useLocation();
  const content = (
    <MenuItem
      sx={{ my: 2 }}
      onClick={handleClick}
      selected={to === pathname}
      {...props}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <Fade in={isExpanded} timeout={500}>
        <ListItemText>{title}</ListItemText>
      </Fade>
    </MenuItem>
  );

  if(!isExpanded) {
    return (
      <Tooltip
        title={title}
        arrow
        placement="right"
      >
        {content}
      </Tooltip>
    );
  }

  return content;
};

import React, { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Typography } from "@mui/material";
import { ColorTheme10 } from "theme/chart";

function convertStringToNumber(obj) {
  // Helper function to determine if a value is numeric
  const isNumeric = value => !isNaN(value) && !isNaN(parseFloat(value));

  // Recursively traverse the object and convert strings to numbers
  function traverseAndConvert(currentObj) {
    for (const key in currentObj) {
      // eslint-disable-next-line no-prototype-builtins
      if (currentObj.hasOwnProperty(key)) {
        if (typeof currentObj[key] === "object" && currentObj[key] !== null) {
          traverseAndConvert(currentObj[key]);
        } else if (isNumeric(currentObj[key])) {
          currentObj[key] = Number(currentObj[key]);
        }
      }
    }
  }

  // Create a copy of the object to avoid modifying the original
  const newObj = JSON.parse(JSON.stringify(obj));
  traverseAndConvert(newObj);
  return newObj;
}


const TeamDistributionChart = ({ data: distributionData }) => {

  const data = convertStringToNumber(distributionData);

  useLayoutEffect(() => {
    const root = am5.Root.new("treemap-chart");
    const myTheme = am5.Theme.new(root);

    myTheme.rule("Tooltip").setAll({
      forceHidden: true
    });

    root.setThemes([
      ColorTheme10.new(root),
      myTheme
    ]);
    root._logo.dispose();

    // Create wrapper container
    const container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.verticalLayout
      })
    );

    // Create series
    // https://www.amcharts.com/docs/v5/charts/hierarchy/#Adding
    const series = container.children.push(
      am5hierarchy.Treemap.new(root, {
        sort: "descending",
        singleBranchOnly: true,
        downDepth: 1,
        upDepth: 0,
        initialDepth: 1,
        valueField: "value",
        categoryField: "name",
        childDataField: "children",
        nodePaddingOuter: 0,
        nodePaddingInner: 0
      })
    );
    series.get("colors").set("step", 1);
    
    series.labels.template.setAll({
      text: "{name}\n[bold]{sum}[/]",
      textAlign:"center",
      fill: am5.color(0x000000),
      fontSize: 20,
    });

    series.rectangles.template.setAll({
      strokeWidth: 3,
      cornerRadiusTL: 4,
      cornerRadiusTR: 4,
      cornerRadiusBL: 4,
      cornerRadiusBR: 4,
    });

    container.children.moveValue(
      am5hierarchy.BreadcrumbBar.new(root, {
        series: series,
      }), 0
    );

    series.labels.template.adapters.add("fill", function(fill, target) {
      if (target.dataItem) {
        return am5.Color.alternative(
          target.dataItem.get("rectangle").get("fill"),
          am5.color(0xffffff),
          am5.color(0x000000)
        );
      }
      return fill;
    });

    function processData(data) {
      const treeData = [];

      am5.object.eachOrdered(
        data,
        (brand) => {
          const brandData = { name: brand, children: [] };

          for (let mod in data[brand]) {
            if (typeof data[brand][mod] === "object") {
              brandData.children.push({ 
                name: mod, 
                children: Object.entries(data[brand][mod]).map(([key, value]) => ({ name: key, value })) 
              });
            } else {
              brandData.children.push({ 
                name: mod, 
                value: data[brand][mod] 
              });
            }
          }

          treeData.push(brandData);
        },
        (a, b) => {
          let aval = 0;
          let bval = 0;
          am5.object.each(data[a], (key, val) => (aval += val));
          am5.object.each(data[b], (key, val) => (bval += val));
          if (aval > bval) return -1;
          if (aval < bval) return 1;
          return 0;
        }
      );

      return [{
        name: "Team Distribution",
        children: treeData
      }];
    }

    series.data.setAll(processData(data));
    series.set("selectedDataItem", series.dataItems[0]);

    return () => {
      root.dispose();
    };
  }, [data]);

  return (
    <Box>
      <Typography variant="body1" mt={8} color="grey" textAlign="right" mb={-8}>
        <InfoOutlinedIcon sx={{ mr:1 }} />Click the map to view details.
      </Typography>
      <Box id="treemap-chart" sx={{ width: "100%", height: "600px", mt: 4 }}></Box>
    </Box>
  );
};

export default TeamDistributionChart;

export const getLocationFromQueryParams = (queryParams) => {
  const geo = queryParams.get("geo");
  const country = queryParams.get("country");
  const city = queryParams.get("city");

  if(!geo && !country && !city) {
    return "All Locations";
  }
  return `${city ? `${city}, ` : ""}${country ? `${country}, ` : ""}${geo ? geo : ""}`;
};

export const getTeamFromQueryParams = (queryParams) => {
  const division = queryParams.get("division");
  const l1 = queryParams.get("l1");
  const l2 = queryParams.get("l2");
  const l3 = queryParams.get("l3");
  const l4 = queryParams.get("l4");

  const teamName = l4 || l3 || l2 || l1 || division;
  return teamName || "Team";
};

export const getLocationNameFromQueryParams = (queryParams) => {
  const geo = queryParams.get("geo");
  const country = queryParams.get("country");
  const city = queryParams.get("city");
  const site = queryParams.get("site");

  const locationName = site || city || country || geo;
  return locationName || "All Locations";
};

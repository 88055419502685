import React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, ToggleButton, ToggleButtonGroup, Typography, Paper, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Tab } from "@mui/material";
import { DownloadResources } from "components/LocationInsights/DownloadResources";
import { useGetLocationInsightsHelpQuery } from "store/slices/locationSlice";

const fagqs = [
  {
    label: "General Questions",
    questions: [
      {
        question: "What is the purpose of this tool?",
        answer: "This tool is designed to provide insights on location based on our internal workforce data and external benchmarks. It can help PPL leaders, PBPs, PCs and others understand workforce distribution, and advise informed decisions related to geographical strategy. Please see the consultation tool for more information."
      },
      {
        question: "Who is the intended audience for this tool?",
        answer: "This tool is designed for decision-makers involved in real estate, workforce planning, and People operations to support decisions related to resource distribution and operational efficiency."
      },
      {
        question: "What is the purpose of this tool?",
        answer: "This tool is designed to provide insights on location based on our internal workforce data and external benchmarks. It helps leaders, PBPs and PCs understand their workforce distribution, and make informed decisions related to geographical strategy. Please see the consultation tool for more information."
      },      
      {
        question: "What kind of insights can this tool provide?",
        answer: "This tool can provide a wide range of insights including, but not limited to, workforce location and timezone distribution, attrition, site leadership and office information, and external job market data including talent supply, demand, cost, and diversity."
      },  
      {
        question: "Does the application allow for benchmarking across locations?",
        answer: "Currently, we provide the Analysis Worksheet (downloadable from the Guide page) to help users plug in data from multiple locations for easy comparison. As the tool evolves and we better understand how users interact with these insights, we plan to integrate this functionality within the application - watch this space!"
      },    
      {
        question: "What is the Qualitative Insights feature, and how do you obtain that data?",
        answer: "Qualitative insights provides directional information on locations, such as regulatory factors, workplace, employee sentiment and other factors that may impact location decisions. It is sourced from a cross-section of subject matter experts including but not limited to People Consultants as well as Talent Acquisition and Corporate Real Estate team members with deep knowledge of that location."
      },
      {
        question: "Why can't I find a country in the External Market section?",
        answer: "One reason is that TalentNeuron, our market intelligence provider, provides data for roughly 40 countries. This means that there are a handful of countries with an Autodesk presence (e.g. Serbia) for which there is no market intelligence data."
      },  
      {
        question: "What is TalentNeuron?",
        answer: "TalentNeuron is a platform that provides data-driven insights on global labor market trends, roles, skills, competitors etc. We extract data from TalentNeuron on a monthly basis for a pre-determined set of roles, and use that data as the basis for the External View of this application."
      }
    ]
  },
  {
    label: "Technical and Support",
    questions: [
      {
        question: "Why am I not seeing any data in the dashboards?",
        answer: "There are a few reasons to consider. If you're in the Internal View, ensure that the filters you have applied are not too narrowly defined; adjusting these filters may help reveal the data you are looking for. In the External View, there might be instances where there is no relevant data for the combination of location and job title available from TalentNeuron. If you continue to encounter issues, please contact the People Insights & Solutions Design team using the Feedback link at the top right corner of this page."
      },
      {
        question: "What are the sources of data?",
        answer: "The data used by this tool comes from two main sources: internal workforce data from Workday, and external market intelligence from TalentNeuron. Other data sources include: Anaplan for TBH (To be hired) data, site attendance data from Lenel via Workplace & Travel (WPT) reporting, site leaders from the WPT SharePoint site, english proficiency data from EF's English Proficiency Index (EPI), Technology industry attrition benchmarks from PwC Saratoga, among others. See the Data Sources & Definitions tab for more information."
      },
      {
        question: "How often is the tool updated?",
        answer: "Data updates for External View are generally rolled out monthly but may occur more frequently if necessary. Internal data from Anaplan is also updated monthly, while data from Workday is updated weekly. Data from other sources such as EF EPI and PwC Saratoga are updated annually."
      },
      {
        question: "How do I request new features or report a bug or data issue?",
        answer: "Please use the portal's Feedback form to get in contact with members of the People Insights & Solution Design team. A link can be found at the topmost right corner of every page."
      },
      {
        question: "Who can I contact for help using the application, or conducting scenario planning?",
        answer: "Please contact any of the core Location Strategy team: Jenny Diani (Project Lead), Niles Bess & Roger van Overbeek (SMEs), Claudya Mercure & Michelle Berry (PBP/PC), Jinhee Kuhl (Exec Sponsor)."
      },
      {
        question: "How can I export data from the application?",
        answer: "Data from many tables within the application can be exported into CSV format for further analysis. A planned future enhancement will also allow users export charts as images for easy use in PowerPoint presentations."
      },    
      {
        question: "Why can't I find a city in the External Market section?",
        answer: "TalentNeuron, our market intelligence provider, provides city-level (or Metropolitan Statistical Area (MSA)) data for only 6 countries: Germany, United Kingdom, India, United States, Canada and Mexico. Further, for the countries where city location is available, we prioritized cities with a larger population and/or a significant Autodesk presence for version 1 of this tool. If you would like to see data for a specific city or country, please request it using the feedback form located at the topmost right corner of this page."
      },
      {
        question: "Will there be future enhancements to this tool?",
        answer: "Absolutely! Some planned enhancements include: Insight Genie integration to download insights into decks, Dashboard Redesign-ADSK branding, Cost of hire metrics, Attendance rate benchmark by location, Office Vibrancy rates: ACE & Conference room utilization"
      },  
      {
        question: "Where can I see the full list of job titles you explored for the External Market section?",
        answer: "Coming soon to this Help section"
      },
      {
        question: "Where can I see the full list of countries and cities you explored for the External Market section?",
        answer: "Coming soon to this Help section"
      },
    ]
  },
  {
    label: "Decision Support",
    questions: [
      {
        question: "How does this tool support location decision-making?",
        answer: "Location Insights combines quantitative data, qualitative insights, and benchmarking capabilities, making it easier to compare locations and anticipate workforce needs."
      },
      {
        question: "Can I use the application for scenario planning?",
        answer: "Yes, the app includes tools for scenario analysis to help analyze the impact of different strategies, like investing in or divesting from a location, or redistributing resources across regions. The tool also provides links to the Analysis Worksheet and Consultation Tool (downloadable from the Guide page) that can help streamline the scenario planning process."
      },
    ]
  },
];

const dataSourceColumns = ["Metric", "Definition", "Data Source", "Appears On", "Refresh Frequency", "Calculated"]; 

const LocationInsightsFAQs = () => {
  const { data } = useGetLocationInsightsHelpQuery();
  const [help, setHelp] = React.useState("faq");
  return (
    <Box bgcolor="#f6f6f6">
      <Box display="flex" alignItems="center">
        <ToggleButtonGroup sx={{ m: 4 }} value={help}>
          <ToggleButton value="faq" onClick={() => setHelp("faq")}>FAQs</ToggleButton>
          <ToggleButton value="data" onClick={() => setHelp("data")}>Data Sources & Definitions</ToggleButton>
        </ToggleButtonGroup>
        <Box ml="auto" mr="24px">
          <DownloadResources />
        </Box>
      </Box>
      
      {
        data && help === "data" ? <TableContainer component={Paper} sx={{ ml: "32px", maxWidth: "calc(100% - 64px)", mb: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                {dataSourceColumns.map(column => (
                  <TableCell key={column}><span className="font-bold">{column}</span></TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                data[0].content.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{row.Metric}</TableCell>
                      <TableCell><span dangerouslySetInnerHTML={{ __html: row.Definition }} /></TableCell>
                      <TableCell>{row["Data Source"]}</TableCell>
                      <TableCell>{row["Appears on"]}</TableCell>
                      <TableCell>{row["Refresh Frequency"]}</TableCell>
                      <TableCell>{row["Calculated Value (Y/N)"]}</TableCell>
                    </TableRow>
                  ); })
              }
            </TableBody>
          </Table>
        </TableContainer> : null
      }
      {
        help === "faq" ? 
          <Box >
            <Container maxWidth="lg" sx={{ pt:6, pb:12, minHeight: "calc(100vh - 588px)", px: 8 }}>
              {fagqs.map((faq, index) => (
                <Box key={index} sx={{ mt: 8, mb: 4 }}>
                  <Typography variant="h3" sx={{ mb: 4 }} className="text-center">
                    {faq.label}
                  </Typography>
                  {faq.questions.map((faq, index) => (
                    <Accordion key={index}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index + 1}-content`}
                        id={`panel${index + 1}-header`}
                      >
                        <Box display="flex" alignItems="center">
                          <HelpOutlineIcon sx={{ mr: 3, ml: 2 }} /> {faq.question}
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography variant="body1" ml={8} mr={4} mb={4} mt={2}>
                          {faq.answer}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Box>
              ))}
            </Container>
          </Box>
          : null
      }
    </Box>
  );
};

export default LocationInsightsFAQs;


import React from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Typography } from "@mui/material";

const Information = ({ children }) => {
  return (
    <Box display="flex" mb={4} mt={2}>
      <InfoOutlinedIcon sx={{ mr: 1, color:"#353535", fontSize: 20 }} />
      <Box>
        <Typography variant="body1" color="#353535">
          {children}
        </Typography>
      </Box>
    </Box>
  );
};

export default Information;

import React from "react";

import { Box, Typography } from "@mui/material";

const SiteLeader = ({ data }) => {
  return (
    <Box width="400px" display="flex" height="200px" justifyContent="center" flexDirection="column" border="1px solid grey" mr={2} px={4}>
      <Typography variant="h1" textAlign="center" fontSize="32px" fontWeight="bold">
        {data}
      </Typography>
      <Typography variant="h6" textAlign="center" fontWeight="bold" mt={2}>
        Site Leader
      </Typography>
    </Box>
  );
};

export default SiteLeader;

import React from "react";


import { Box, Typography } from "@mui/material";
import GroupedPieChart from "components/LocationInsights/GroupedPieChart";

const TeamTbhJobFunctions = ({ data, location }) => {
  return (
    <Box>
      <Typography variant="h4" fontWeight="bold" mb={4}>
        Autodesk-Level TBH Job Functions at {location || "Location"}
      </Typography>
      <TeamTbhJobFunctionsChart id="team-tbh-job-functions" data={data} />
    </Box>
  );
};

export default TeamTbhJobFunctions;

export const TeamTbhJobFunctionsChart = ({ id, data }) => {
  if(!data) {
    return (
      <Typography variant="body1">No data available</Typography>
    );
  }
  const chartData = Object.entries(data).map(([key, value]) => ({ label: key, value: +value }));
  return (
    <GroupedPieChart id={id} data={chartData} labelKey="label" valueKey="value" />
  );
};

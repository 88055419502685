import React, { useState } from "react";

import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import { Box, Rating, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useGetAllFeedbacksQuery } from "store/slices/feedbackSlice";

import FeedbackDetail from "./FeedbackDetail";
import FeedbackFilter from "./FeedbackFilter";

const FeedbackManagement = () => {
  const [searchKey, setSearchKey] = useState("");
  const [dateFilter, setDateFilter] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedFeedback, setSelectedFeedback] = useState(null);

  const { data: feedbacks = [], isLoading } = useGetAllFeedbacksQuery();

  const handleRowClick = ({ row }) => {
    setSelectedFeedback(row);
  };

  const searchedFeedbacks = feedbacks
    .filter(feedback => feedback.feedback ? feedback.feedback.toLowerCase().includes(searchKey.toLowerCase()) : false)
    .filter(feedback => selectedCategory ? feedback.category === selectedCategory : true)
    .filter(feedback => selectedProduct ? feedback.product.includes(selectedProduct) : true);
    
  const searchFilteredFeedbacks = dateFilter && dateFilter !== "all" ? searchedFeedbacks.filter(feedback => dayjs(feedback.added_on).isAfter(dateFilter, "day") || dayjs(feedback.added_on).isSame(dateFilter, "day")) : searchedFeedbacks;

  const columns = [
    { field: "urgent", 
      headerName: "Urgent", 
      width: 90,
      renderCell: ({ value }) => {
        return (
          <Box>
            <Typography variant="body1" color={value ? "error" : "textPrimary"}>{value ? "Urgent" : "Normal"}</Typography>
          </Box>
        );
      },
    },
    {
      field: "category",
      headerName: "Category",
      width: 200,
      editable: true,
    },
    {
      field: "feedback",
      headerName: "Feedback",
      width: 300,
    },
    {
      field: "product",
      headerName: "Product",
      width: 300,
      editable: false,
      renderCell: (params) => (
        <Box>
          <ul>
            {params.row.product.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </Box>
      )
    },
    {
      field: "satisfaction",
      headerName: "Satisfaction",
      width: 150,
      renderCell: ({ value, row }) => {
        return (
          <Box>
            {row.category === "UI Feedback" ? (
              <Rating value={value} readOnly />
            ): null}
          </Box>
        );
      }
    },
    {
      field: "added_by",
      headerName: "User",
      width: 150,
      editable: true,
    },
    {
      field: "added_on",
      headerName: "Created Date",
      width: 300,
      editable: false,
      renderCell: (params) => (
        <Box>
          {dayjs(params.row.added_on).format("YYYY-MM-DD")}
        </Box>
      )
    },
  ];

  const handleSearch = (query) => {
    setSearchKey(query);
  };

  const handleDateFilterChange = (df) => {
    setDateFilter(df);
  };

  const selectCategory = (category) => {
    setSelectedCategory(category);
  };

  const selectProduct = (product) => {
    setSelectedProduct(product);
  };

  return (
    <>
      <Typography variant="h2" className=" flex items-center font-bold " mb={2}>
        <StickyNote2OutlinedIcon sx={{ mr: 1 }} /> Feedback Management
      </Typography>
      <div>
        <FeedbackFilter
          searchKey={searchKey}
          onSearch={handleSearch}
          dateFilter={dateFilter}
          onDateFilterChange={handleDateFilterChange}
          selectedCategory={selectedCategory}
          selectedProduct={selectedProduct}
          selectCategory={selectCategory}
          selectProduct={selectProduct}
        />
      </div>
      {/* Data Grid */}
      <Box sx={{ height: 800, width: "100%", backgroundColor: "white" }}>
        <DataGrid
          rows={searchFilteredFeedbacks}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 12,
              },
            },
          }}

          loading={isLoading}
          pageSizeOptions={[12]}
          onRowClick={handleRowClick}
          // checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
      <FeedbackDetail feedback={selectedFeedback} onClose={() => setSelectedFeedback(null)} />
    </>
  );
};

export default FeedbackManagement;

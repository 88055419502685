import React from "react";

import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useGetResearchQuery } from "store/slices/researchSlice";

import ResearchDetailView from "./ResearchDetailView";

const ResearchDetailPage = () => {
  const { researchId } = useParams();

  const { data: research, isLoading } = useGetResearchQuery({ id: researchId }, { skip: !researchId });

  if (isLoading) {
    return (
      <Box
        height="calc(100vh - 300px)"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <BeatLoader color={"#5C5470"} />
      </Box>
    );
  }

  return (
    <ResearchDetailView 
      research={research} 
    />
  );
};

export default ResearchDetailPage;

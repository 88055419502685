import React from "react";

import SearchIcon from "@mui/icons-material/Search";
import { TextField, InputAdornment, FormControl, InputLabel, MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import DateFilter from "components/DateFilter/DateFilter";
import { useGetApplicationFiltersQuery } from "store/slices/commentSlice";

const FeedbackFilter = ({
  onSearch,
  onDateFilterChange,
  searchKey,
  selectedCategory,
  selectCategory,
  selectedProduct,
  selectProduct,
  dateFilter
}) => {
  const { data: options } = useGetApplicationFiltersQuery({ page_name: "feedback" });
  const productOptions = options?.product?.map(product => product.title) || [];

  return (
    <Box className="flex justify-between" gap={4} mt={2} mb={3}>
      <TextField
        id="outlined-start-adornment"
        size="small"
        placeholder="Search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }}
        onChange={e => onSearch(e.target.value)}
        value={searchKey}
        sx={{ mr: 2 }}
        fullWidth
      />
      <DateFilter 
        value={dateFilter}
        onChangeDate={onDateFilterChange}
      />
      <FormControl variant="outlined" size="small" fullWidth>
        <InputLabel id="demo-simple-select-label">Category</InputLabel>
        <Select
          name="category"
          label="Category"
          value={selectedCategory}
          onChange={e => selectCategory(e.target.value)}
        >
          <MenuItem value="">All</MenuItem>
          {options?.category?.map((category, index) => (
            <MenuItem value={category} key={index}>
              {category}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" size="small" fullWidth>
        <InputLabel id="demo-simple-select-label">Product</InputLabel>
        <Select
          name="product"
          label="Product"
          value={selectedProduct}
          onChange={e => selectProduct(e.target.value)}
        >
          <MenuItem value="">All</MenuItem>
          {productOptions.map((product, index) => (
            <MenuItem value={product} key={index}>
              {product}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default FeedbackFilter;

import React from "react";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Controller } from "react-hook-form";

const SelectFormField = ({
  name,
  label,
  control,
  defaultValue,
  options=[],
  error,
  multiple,
  ...props
}) => {
  const labelId = `${name}-label`;
  return (
    <FormControl error={error} {...props}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        render={({ field }) => (
          <Select labelId={labelId} label={label} multiple={multiple} {...field}>
            {options.map((option, index) => (
              <MenuItem value={option} key={`${option}-${index}`} >{option}</MenuItem>
            ))}
          </Select>
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
      {error && (
        <FormHelperText error>{error.message}</FormHelperText>
      )}
    </FormControl>
  );
};
export default SelectFormField;

import React, { useState } from "react";

import { Autocomplete, Grid, TextField } from "@mui/material";
import useEventState from "hooks/useEventstate";
import { useSearchParams } from "react-router-dom";

import { COMMENTS_APP_DATA_LOADING_EVENT } from "../constants";

const GeoAndDivisionFilter = ({ filters, isLoading }) => {
  const [currentParams, setCurrentParams] = useSearchParams();
  const [currentFilters, setCurrentFilters] = useState(() => {
    const geoParam = currentParams.get("geo");
    const countryParam = currentParams.get("country");
    const divisionParam = currentParams.get("division");
    const mgmtLvlParam = currentParams.get("mgmtLvl");
    const l2mParam = currentParams.get("l2m");
    const l3mParam = currentParams.get("l3m");

    return {
      geo: geoParam,
      country: countryParam,
      division: divisionParam,
      mgmtLvl: mgmtLvlParam,
      l2m: l2mParam?.split("_") || [],
      l3m: l3mParam?.split("_") || [],
    };
  });

  const isDataLoading = useEventState(COMMENTS_APP_DATA_LOADING_EVENT);

  const geoFilterOptions = Object.keys(filters?.geo ?? {});
  const countryFilterOptions = filters?.geo?.[currentFilters.geo]?.country ?? [];

  const mgmtLvlFilterOptions = filters?.mgmtLvl?.filter(Boolean) ?? [];

  const divisionFilterOptions = Object.keys(filters?.division ?? {});
  const l2mFilterOptions = Object.keys(filters?.division?.[currentFilters.division]?.l2m ?? {});
  const l3mFilterOptions = filters?.division?.[currentFilters.division]?.l2m?.[currentFilters.l2m]?.l3m ?? [];
  
  const divisionFilters = [
    {
      label: "Division",
      name: "division",
      options: divisionFilterOptions,
      value: currentFilters.division,
    },
    {
      label: "Management Level",
      name: "mgmtLvl",
      options: mgmtLvlFilterOptions,
      value: currentFilters.mgmtLvl,
    },
    {
      label: "Manager L2",
      name: "l2m",
      options: l2mFilterOptions,
      value: currentFilters.l2m,
    },
    {
      label: "Manager L3",
      name: "l3m",
      options: l3mFilterOptions,
      value: currentFilters.l3m,
    },
  ];

  const geoFilters = [
    {
      label: "Geo",
      name: "geo",
      options: geoFilterOptions,
      value: currentFilters.geo,
    },
    {
      label: "Country",
      name: "country",
      options: countryFilterOptions,
      value: currentFilters.country,
    }
  ];
  
  const handleChange = (name, value) => {
    const newFilters = { ...currentFilters, [name]: value };
    if (name === "geo") {
      newFilters.country = null;
    }

    if (name === "division") {
      newFilters.l2m = [];
      newFilters.l3m = [];
    }

    if (name === "l2m") {
      newFilters.l3m = [];
    }

    setCurrentFilters(newFilters);
    handleApplyFilters(newFilters);
  };

  const handleApplyFilters = (filters) => {
    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        if (Array.isArray(value)) {
          value = value.join("_");
        }
        currentParams.set(key, value);
      } else {
        currentParams.delete(key);
      }
    });
    setCurrentParams(currentParams);
  };

  return (
    <Grid container>
      <Grid item md={8} className="pr-6">
        <p className="mb-1 font-bold">Division and organisation levels</p>
        <Grid container spacing={2}>
          {divisionFilters.map((filter) => (
            <Grid item key={filter.name} md={3}>
              <Autocomplete
                multiple={filter.name === "l2m" || filter.name === "l3m"}
                options={filter.options}
                getOptionLabel={(option) => option}
                value={filter.value}
                size="small"
                disabled={isLoading || isDataLoading}
                onChange={(_e, values)=>handleChange(filter.name, values)}
                ChipProps={{
                  variant: "contained",
                  color: "primary",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={filter.label}
                    placeholder={filter.label}
                  />
                )}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item md={4} className="pl-6 border-l border-gray-200">
        <p className="mb-1 font-bold">Geo and country</p>
        <Grid container spacing={2}>
          {geoFilters.map((filter) => (
            <Grid item key={filter.name} md={6}>
              <Autocomplete
                // multiple
                options={filter.options}
                getOptionLabel={(option) => option}
                value={filter.value}
                size="small"
                disabled={isLoading || isDataLoading}
                onChange={(_e, values)=>handleChange(filter.name, values)}
                ChipProps={{
                  variant: "contained",
                  color: "primary",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={filter.label}
                    placeholder={filter.label}
                  />
                )}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      {/* <div className="mt-2 w-full flex justify-end">
        <Button variant="contained" color="primary" disabled={isLoading} onClick={() => handleApplyFilters()}>
          Apply Filters 
        </Button>
      </div> */}
    </Grid>
  );
};

export default GeoAndDivisionFilter;

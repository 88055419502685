import React from "react";

import EmployeeListeningManagement from "pages/Admin/EmployeeListeningManagement/EmployeeListeningManagement";
import FeedbackManagement from "pages/Admin/FeedbackManagement/FeedbackManagement";
import PermissionManagement from "pages/Admin/PermissionManagement/PermissionManagement";
import EmployeeListeningDetailPage from "pages/EmployeeListening/EmployeeListeningDetailPage/EmployeeListeningDetailPage";
import EmployeeListeningPage from "pages/EmployeeListening/EmployeeListeningPage/EmployeeListeningPage";
import GlintDecisionLandingPage from "pages/EmployeeListening/GlintDecisionLandingPage/GlintDecisionLandingPage";
import GlintDecisionTree from "pages/EmployeeListening/GlintDecisionLandingPage/GlintDecisionTree/GlintDecisionTree";
import GlintDecisionOverview from "pages/EmployeeListening/GlintDecisionLandingPage/GlintDeicionOverview/GlintDecisionOverview";
import GlintDecisionExample from "pages/EmployeeListening/GlintDecisionLandingPage/GlintDeicisionExample/GlintDecisionExample";
import InsightGenie from "pages/InsightGenie/InsightGenie";
import ExternalViewDetailLayout from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsExternalView/ExternalViewDetailLayout";
import CostOfLabor from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsExternalView/LocationInsightsExternalDetail/Details/CostOfLaborDetail/CostOfLabor";
import DiversityDetail from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsExternalView/LocationInsightsExternalDetail/Details/DiversityDetail/DiversityDetail";
import HiringDifficultyDetail from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsExternalView/LocationInsightsExternalDetail/Details/HiringDifficultyDetail/HiringDifficultyDetail";
import RoleAnalysisDetail from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsExternalView/LocationInsightsExternalDetail/Details/RoleAnalysisDetail/RoleAnalysisDetail";
import SupplyAndDemandDetail from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsExternalView/LocationInsightsExternalDetail/Details/SupplyAndDemandDetail/SupplyAndDemandDetail";
import TalentCompetitionDetail from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsExternalView/LocationInsightsExternalDetail/Details/TalentCompetitionDetail/TalentCompetitionDetail";
import LocationInsightsExternalView from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsExternalView/LocationInsightsExternalView";
import LocationInsightsFAQs from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsFAQs/LocationInsightsFAQs";
import LocationInsightsHub from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsHubPage/LocationInsightsHub";
import InternalViewDetailLayout from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsInternalView/InternalViewDetailLayout";
import AttritionDetail from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsInternalView/LocationInsightsInternalDetail/Tabs/Attrition/AttritionDetail/AttritionDetail";
import HeadcountDetail from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsInternalView/LocationInsightsInternalDetail/Tabs/Headcount/HeadcountDetails/HeadcountDetail";
import LeadershipDepthDetail from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsInternalView/LocationInsightsInternalDetail/Tabs/LeadershipDepth/LeadershipDepthDetail/LeadershipDepthDetail";
import RecruitmentDetail from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsInternalView/LocationInsightsInternalDetail/Tabs/Recruitment/RecruitmentDetail/RecruitmentDetail";
import TeamDistributionDetail from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsInternalView/LocationInsightsInternalDetail/Tabs/TeamDistribution/TeamDistributionDetail/TeamDistributionDetail";
import TimezoneDetail from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsInternalView/LocationInsightsInternalDetail/Tabs/Timezone/TimezoneDetail/TimezoneDetail";
import LocationInsightsInternalView from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsInternalView/LocationInsightsInternalView";
import LocationInsightsLandingPage from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsLandingPage";
import LocationInsightsOverviewPage from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsOverviewPage/LocationInsightsOverviewPage";
import Tableau from "pages/LocationInsights/LocationInsightsLandingPage/Tableau/Tableau";
import { Navigate } from "react-router-dom";

import Blog from "./components/Blog/Blog";
import Research from "./components/Research/Research";
import MainLayout from "./layouts/MainLayout";
import PageLayout from "./layouts/PageLayout";
import AdminLanding from "./pages/Admin/AdminLanding";
import AdminPanel from "./pages/Admin/AdminPanel";
import BlogManagement from "./pages/Admin/BlogManagement/BlogManagement";
import DashboardManagement from "./pages/Admin/DashboardManagement/DashboardManagement";
import ResearchManagement from "./pages/Admin/ResearchManagement/ResearchManagement";
import BlogDetailPage from "./pages/Blogs/BlogDetailPage";
import DashboardDetailPage from "./pages/Dashboards/DashboardDetailPage";
import Dashboards from "./pages/Dashboards/Dashboards";
import ForbiddenRoute from "./pages/ForbiddenRoute/ForbiddenRoute";
// import NotFound from "./pages/NotFound/NotFound";
import ResearchDetailPage from "./pages/Researches/ResearchDetailPage";

export const getRoutes = (pages) => {

  return [
    {
      path: "/",
      element: <Navigate to="dashboard" />
    },
    ...(pages?.includes("dashboards") ? 
      [
        {
          path: "/dashboard",
          element: (
            <PageLayout>
              <Dashboards />
            </PageLayout>
          ),
        },
        {
          path: "/dashboard/:id/location-insights/",
          element: (
            <LocationInsightsLandingPage />
          ),
          children: [
            {
              path: "",
              element: <Navigate to="overview" />,
            },
            {
              path: "overview",
              element: <LocationInsightsOverviewPage />
            },
            {
              path: "faqs",
              element: <LocationInsightsFAQs />,
            },
            {
              path: "internal-view",
              element: <LocationInsightsInternalView />,
            },
            {
              path: "internal-view/detail/",
              element: <InternalViewDetailLayout />,
              children: [
                {
                  path: "",
                  element: <Navigate to="headcount" />,
                },
                {
                  path: "headcount",
                  element: <HeadcountDetail />
                },
                {
                  path: "attrition",
                  element: <AttritionDetail />
                },
                {
                  path: "timezone",
                  element: <TimezoneDetail />
                },
                {
                  path: "leadership-depth",
                  element: <LeadershipDepthDetail />
                },
                {
                  path: "recruitment",
                  element: <RecruitmentDetail />
                },
                {
                  path: "team-distribution",
                  element: <TeamDistributionDetail />
                }
              ]
            },
            {
              path: "external-view",
              element: <LocationInsightsExternalView />,
            },
            {
              path: "external-view/detail/",
              element: <ExternalViewDetailLayout />,
              children: [
                {
                  path: "",
                  element: <Navigate to="supply-and-demand" />,
                },
                {
                  path: "supply-and-demand",
                  element: <SupplyAndDemandDetail />
                },
                {
                  path: "hiring-difficulty",
                  element: <HiringDifficultyDetail />
                },
                {
                  path: "talent-competition",
                  element: <TalentCompetitionDetail />
                },
                {
                  path: "diversity",
                  element: <DiversityDetail />
                },
                {
                  path: "role-analysis",
                  element: <RoleAnalysisDetail />
                },
                {
                  path: "cost-of-labor",
                  element: <CostOfLabor />
                }
              ]
            },
            {
              path: "tableau",
              element: <Tableau />,
            },
            {
              path: "hub-summary",
              element: <LocationInsightsHub />,
            }
          ]
        },
        {
          path: "/dashboard/:id/:page",
          element: <DashboardDetailPage />,
        },
      ]
      :[]
    ),
    ...(pages?.includes("research") ?
      [{
        path: "/research",
        element: (
          <PageLayout>
            <Research />
          </PageLayout>
        ),
      },
      {
        path: "/research/:researchId/:title",
        element: (
          <MainLayout>
            <ResearchDetailPage />
          </MainLayout>
        ),
      }]
      :[]
    ),
    ...(pages?.includes("blogs") ?
      [{
        path: "/blog",
        element: (
          <PageLayout>
            <Blog />
          </PageLayout>
        ),
      },
      {
        path: "/blog/:blogId/:title",
        element: (
          <MainLayout>
            <BlogDetailPage />
          </MainLayout>
        ),
      }]
      :[]
    ),
    ...(pages?.includes("listening") ?
      [{
        path: "/employee-listening",
        element: (
          <PageLayout>
            <EmployeeListeningPage />
          </PageLayout>
        ),
      },
      {
        path: "/employee-listening/:id/decision-tree/",
        element: (
          <GlintDecisionLandingPage />
        ),
        children: [
          {
            path: "",
            element: <Navigate to="overview" />,
          },
          {
            path: "overview",
            element: <GlintDecisionOverview />
          },
          {
            path: "glint-decision-tree",
            element: <GlintDecisionTree />,
          },
          {
            path: "example",
            element: <GlintDecisionExample />,
          }
        ]
      },
      {
        path: "/employee-listening/:employeeListeningId/:title",
        element: (
          <MainLayout>
            <EmployeeListeningDetailPage />
          </MainLayout>
        ),
      }]
      :[]
    ),
    
    ...(pages?.includes("insights-genie") ?
      [{
        path: "/insights-genie",
        element: (
          <PageLayout>
            <InsightGenie />
          </PageLayout>
        ),
      }]: []),
    
    ...(pages?.includes("admin") ?
      [{
        path: "/admin",
        element: (
          <PageLayout>
            <AdminPanel />
          </PageLayout>
        ),
        children: [
          {
            path: "",
            element: <AdminLanding />,
          },
          {
            path: "research-management",
            element: <ResearchManagement />,
          },
          {
            path: "blog-management",
            element: <BlogManagement />,
          },
          {
            path: "dashboard-management",
            element: <DashboardManagement />,
          },
          {
            path: "employee-listening-management",
            element: <EmployeeListeningManagement />,
          },
          {
            path: "permission-management",
            element: <PermissionManagement />,
          },
          {
            path: "feedback-management",
            element: <FeedbackManagement />,
          }
        ],
      }]
      :[]
    ),
    {
      path: "/forbidden",
      element: <ForbiddenRoute />,
    },
    // {
    //   path: "*",
    //   element: <NotFound />,
    // },
  ];
};

import React from "react";

import { Box, Typography } from "@mui/material";

const LocationSummary = ({ data }) => {
  if(!data) {
    return (
      <Typography variant="body1" color="grey">No data available</Typography>
    );
  }

  return (
    <Box mt={10}>
      <Box border="1px solid black" p={4}>
        <Typography variant="h3" fontWeight="bold" textAlign="center">
          {data.tbh_count}
        </Typography>
        <Typography variant="body1" mt={2} color="grey" textAlign="center">
        FY25 TBHs for All Teams in Location
        </Typography>
      </Box>
      <Box border="1px solid black" p={4} mt={2}>
        <Typography variant="h3" fontWeight="bold" textAlign="center">
          {data.requisition_count}
        </Typography>
        <Typography variant="body1" mt={2} color="grey" textAlign="center">
          Open Requisitions for All Teams in Location
        </Typography>
      </Box>
    </Box>
  );
};

export default LocationSummary;

import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { blogSlice } from "./slices/blogSlice";
import { commentSlice } from "./slices/commentSlice";
import { currentUserSlice } from "./slices/currentUserSlice";
import { dashboardSlice } from "./slices/dashboardSlice";
import { decisionTreeSlice } from "./slices/decisionTreeSlice";
import { employeeSlice } from "./slices/employeeListeningSlice";
import { feedbackSlice } from "./slices/feedbackSlice";
import { insightsGenie } from "./slices/insightsGenieSlice";
import { locationSlice } from "./slices/locationSlice";
import { personaSlice } from "./slices/personaSlice";
import { userSlice } from "./slices/userSlice";
import { insightApi } from "../apis/api";

const rootReducer = combineReducers({
  [insightApi.reducerPath]: insightApi.reducer,
  currentUser: currentUserSlice.reducer,
  dashboard: dashboardSlice.reducer,
  blog: blogSlice.reducer,
  comment: commentSlice.reducer,
  location:locationSlice.reducer,
  employee: employeeSlice.reducer,
  permission: personaSlice.reducer,
  user: userSlice.reducer,
  insightsGenie: insightsGenie.reducer,
  decisionTree: decisionTreeSlice.reducer,
  userFeedback: feedbackSlice.reducer
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // turn off warning about non-serializable values
    }).concat(insightApi.middleware),
});

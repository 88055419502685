import React, { useEffect } from "react";

import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";

import DashboardTile from "../../components/DashboardTile/DashboardTile";
import { useGetAllDashboardsQuery } from "../../store/slices/dashboardSlice";

const DashboardTiles = () => {
  const navigate = useNavigate();
  const pages = useSelector((state) => state.currentUser.pages);

  const path = Cookies.get("path");

  useEffect(() => {
    if(path) {
      Cookies.remove("path");
      navigate(path);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data = [], isLoading } = useGetAllDashboardsQuery();
  const tileItems = [...data].sort((a, b) => +a.display_order - b.display_order);

  return (
    <section className="mb-10">
      {pages?.includes("dashboards") ? (
        <>
          <p className="caption text-lg mb-2 text-center mt-8">
            Click on a dashboard to launch it (<a href="#popup1">Not working?</a>)
          </p>
          <p className="caption text-lg text-center mb-16">
            Please connect to{" "}
            <Link
              className="text-sky-500 "
              to="https://one.autodesk.com/pages/virtual-private-network"
            >
              VPN
            </Link>{" "}
            to access dashboards
          </p>
        </>
      ) : (
        <p className="caption mb-10">
          Oh no! You don't have access to view anything on the People Insights
          Portal. If you believe you're seeing this message in error, please
          reach out to the (
          <a href="mailto:pex.people.insights@autodesk.com?subject=Requesting%20Access%20to%20People%20Insights%20Portal">
            People Insights team.
          </a>
          )
        </p>
      )}

      <div className="mt-10">
        {isLoading ? (
          <div className="text-center">
            <BeatLoader size={15} color="#5C5470" />
          </div>
        ) : (
          <section
            id="dashboards-grid "
            className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 place-items-center gap-x-10 gap-y-32 mb-5"
          >
            {tileItems?.length !== 0 &&
              tileItems?.map((d, idx) => {
                return (
                  <DashboardTile
                    key={idx}
                    title={d.title}
                    image={d.image}
                    description={d.description}
                    comingSoon={d.coming_soon}
                    link={d.is_external ? d.link : `${d.id}/${d.link}`}
                    isExternal={d.is_external}
                  />
                );
              })}
          </section>
        )}
      </div>
      {/* <Pagination data={tiles} getPaginatedItems={getPaginatedItems} noOfItems={9}/> */}
    </section>
  );
};

export default DashboardTiles;

import React, { useEffect } from "react";

import { Box, Grid, Typography } from "@mui/material";
import Information from "components/LocationInsights/Information";
import MoreInformationPopover from "components/MoreInformationPopover/MoreInformationPopover";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useLazyGetLocationInsightsQuery } from "store/slices/locationSlice";

import LocationForecastedHeadcount from "./LocationForecastedHeadcount";
// import LocationTbhJobFunctions from "./LocationTbhJobFunctions";
import LocationSummary from "./LocationSummary";
import LocationTbhJobFunctions from "./LocationTbhJobFunctions";
import TeamForecastedHeadcount from "./TeamForecastedHeadcount";
import TeamSummary from "./TeamSummary";
import TeamTbhJobFunctions from "./TeamTbhJobFunctions";
// import TopLocationsAutodesk, { TopLocationsChart } from "./TopLocationsAutodesk";
// import TopLocationsTeam from "./TopLocationsTeam";
import TopLocations from "./TopLocations/TopLocations";
import Question from "../../../components/Question";
import helpText from "../../../helpText";
import { getLocationNameFromQueryParams, getTeamFromQueryParams } from "../../../utils";

const RecruitmentDetail = () => {
  const [currentParams] = useSearchParams();
  const [getLocationDetail] = useLazyGetLocationInsightsQuery();
  const { data, isLoading } = useSelector((state) => state.location);
  const locationName = getLocationNameFromQueryParams(currentParams);
  const teamName = getTeamFromQueryParams(currentParams);

  useEffect(() => {
    const params = Object.fromEntries(currentParams.entries());
    getLocationDetail({ params });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentParams]);

  
  if(isLoading) {
    return (
      <Box className="flex items-center justify-center w-full">
        <BeatLoader color="#5C5470" />
      </Box>
    );
  }
  
  const teamForecastedHeadcount = data?.adsk_location_metrics?.find((item) => item.metric_name === "tbh_forecasted_headcount");
  const locationForecastedHeadcount = data?.metrics?.find((item) => item.metric_name === "tbh_forecasted_headcount");
  const teamTbhJobFunctions = data?.adsk_location_metrics?.find((item) => item.metric_name === "tbh_job_function_distribution");
  const locationTbhJobFunctions = data?.metrics?.find((item) => item.metric_name === "tbh_job_function_distribution");
  const locationTbhReqSummary = data?.metrics?.find((item) => item.metric_name === "tbh_req_summary");
  const teamTbhReqSummary = data?.adsk_location_metrics?.find((item) => item.metric_name === "tbh_req_summary");
  const topTbhLocationAutodesk = data?.metrics?.find((item) => item.metric_name === "top_tbh_locations_fy");
  const topTbhLocationTeam = data?.metrics?.find((item) => item.metric_name === "team_tbhs_all_locations");

  return (
    <Box>
      <Question text="What are the budgeted Employee headcount growth and roles at this location?" />
      <Box display="flex" mb={8} mt={2}>
        <Information>
          <MoreInformationPopover helpText={helpText.openBudgetedRoles}/>        
        </Information>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={2}>
          <TeamSummary data={teamTbhReqSummary?.value} />
        </Grid>
        <Grid item xs={5}>
          <TeamForecastedHeadcount data={teamForecastedHeadcount?.value} location={locationName}/>
        </Grid>
        <Grid item xs={5}>
          <TeamTbhJobFunctions data={teamTbhJobFunctions?.value} location={locationName} />
        </Grid>
        <Grid item xs={2}>
          <LocationSummary data={locationTbhReqSummary?.value} />
        </Grid>
        <Grid item xs={5}>
          <LocationForecastedHeadcount data={locationForecastedHeadcount?.value} location={locationName} team={teamName} />
        </Grid>
        <Grid item xs={5}>
          <LocationTbhJobFunctions data={locationTbhJobFunctions?.value} location={locationName} team={teamName} />
        </Grid>
        <Grid item xs={12}>
          <TopLocations autodeskData={topTbhLocationAutodesk?.value} teamData={topTbhLocationTeam?.value} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default RecruitmentDetail;

export const columns = [
  { 
    accessorKey: "geo",
    header: "Geo",
  },
  { 
    accessorKey: "country",
    header: "Country",
  },
  { 
    accessorKey: "city", 
    header: "City", 
  },
  { 
    accessorKey: "team", 
    header: "Team", 
  },
  { 
    accessorKey: "regular_hc", 
    header: "Regular HC", 
    muiTableBodyCellProps: {
      align: "right",
    },
  },
  { 
    accessorKey: "mgr_hc", 
    header: "Manager HC", 
    muiTableBodyCellProps: {
      align: "right",
    },
  },
  { 
    accessorKey: "ic_hc", 
    header: "Individual Contributor HC", 
    size: 250,
    muiTableBodyCellProps: {
      align: "right",
    },
  },
  { 
    accessorKey: "fixed_intern_hc", 
    header: "Fixed Intern HC",
    muiTableBodyCellProps: {
      align: "right",
    },
  },
  { 
    accessorKey: "employee_hc", 
    header: "Employee HC", 
    muiTableBodyCellProps: {
      align: "right",
    },
  },
  { 
    accessorKey: "director_plus_hc", 
    header: "Director Plus HC", 
    muiTableBodyCellProps: {
      align: "right",
    },
  },
  { 
    accessorKey: "associate_osp_hc", 
    header: "Assoc & OSP HC", 
    muiTableBodyCellProps: {
      align: "right",
    },
  },
  { 
    accessorKey: "agency_independent_contractor_hc", 
    header: "Agency Independent Contractor HC", 
    muiTableBodyCellProps: {
      align: "right",
    },
  }
];

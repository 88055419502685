import { useState } from "react";

import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import PersonaManagement from "./PersonaManagement/PersonaManagement";
import UserManagement from "./UserManagement/UserManagement";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PermissionManagement = () => {
  const [tab, setTab] = useState(0);
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  return (
    <>
      <Typography variant="h2" className=" flex items-center font-bold " mb={2}>
        <AdminPanelSettingsOutlinedIcon sx={{ mr: 1 }} /> Permission Management
      </Typography>
      <Box display="flex" justifyContent="space-between">
        <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Persona" {...a11yProps(0)} />
          <Tab label="User" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={tab} index={0}>
        <PersonaManagement />
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={1}>
        <UserManagement />
      </CustomTabPanel>
    </>
  );
};

export default PermissionManagement;

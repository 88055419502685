import React, { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { ColorTheme3 } from "theme/chart";

import { getLocationNameFromQueryParams, getTeamFromQueryParams } from "../../../utils";

function convertStringNumbersToNumbers(obj) {
  if (Array.isArray(obj)) {
    return obj.map(convertStringNumbersToNumbers);
  } else if (typeof obj === "object" && obj !== null) {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      acc[key] = convertStringNumbersToNumbers(value);
      return acc;
    }, {});
  } else if (typeof obj === "string" && !isNaN(obj)) {
    return Number(obj);
  }
  return obj;
}

const OtherLocations = ({ data }) => {
  const [currentParams] = useSearchParams();
  const locationName = getLocationNameFromQueryParams(currentParams);
  const teamName = getTeamFromQueryParams(currentParams);
  return (
    <Box>
      <Typography variant="h4" fontWeight="bold" mb={4}>
        {teamName} Headcount {locationName}
      </Typography>
      {data ? (
        <OtherLocationsChart data={data} />
      ) : (
        <Typography variant="body1">No data available</Typography>
      )}
    </Box>
  );
};

export default OtherLocations;

const OtherLocationsChart = ({ data }) => {
  const chartData = data.map(item => convertStringNumbersToNumbers(item));

  // This code will only run one time
  useLayoutEffect(() => {
    
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    let root = am5.Root.new("other-locations");
    
    
    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      ColorTheme3.new(root)
    ]);
    root._logo.dispose();
    
    // Create wrapper container
    let container = root.container.children.push(am5.Container.new(root, {
      width: am5.p100,
      height: am5.p100,
      layout: root.horizontalLayout
    }));
    
    
    // ==============================================
    // Column chart
    // ==============================================
    
    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let columnChart = container.children.push(am5xy.XYChart.new(root, {
      width: am5.p50,
      panX: false,
      panY: false,
      wheelX: "none",
      wheelY: "none",
      layout: root.horizontalLayout
    }));
    
    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let yRenderer = am5xy.AxisRendererY.new(root, {
      minGridDistance: 10,
      minorGridEnabled: true
    });
    let yAxis = columnChart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "category",
      renderer: yRenderer,
    }));
    
    yRenderer.grid.template.setAll({
      location: 1,
    });

    yRenderer.labels.template.setAll({
      fontSize: 14
    });
    
    let xAxis = columnChart.xAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererX.new(root, {
        strokeOpacity: 0.1
      })
    }));
    
    
    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let columnSeries = columnChart.series.push(am5xy.ColumnSeries.new(root, {
      name: name,
      xAxis: xAxis,
      yAxis: yAxis,
      valueXField: "value",
      categoryYField: "category"
    }));
    
    columnSeries.columns.template.setAll({
      tooltipText: "{categoryY}: {valueX}"
    });

    columnSeries.bullets.push(function() {
      return am5.Bullet.new(root, {
        locationX: 1,
        locationY: 0.5,
        sprite: am5.Label.new(root, {
          text: "{valueX}",
          centerX: am5.percent(0),
          centerY: am5.percent(50),
          populateText: true
        })
      });
    });

    columnSeries.columns.template.adapters.add("fill", function (fill, target) {
      return columnChart.get("colors").getIndex(columnSeries.columns.indexOf(target));
    });
    columnSeries.columns.template.adapters.add("stroke", function (stroke, target) {
      return columnChart.get("colors").getIndex(columnSeries.columns.indexOf(target));
    });

    columnSeries.data.setAll(chartData);
    yAxis.data.setAll(chartData);
    
    //series.data.setAll(data);
    
    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    columnChart.appear(1000, 100);
    
    
    // ==============================================
    // Column chart
    // ==============================================
    
    let pieChart = container.children.push(
      am5percent.PieChart.new(root, {
        width: am5.p50,
        innerRadius: am5.percent(50),
        layout: root.horizontalLayout
      })
    );
    
    // Create series
    let pieSeries = pieChart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        layout: root.horizontalLayout,
        legendLabelText: "{category}",
        legendValueText: null,
      })
    );
    
    pieSeries.slices.template.setAll({
      templateField: "sliceSettings",
      strokeOpacity: 0
    });
    
    // let currentSlice;
    pieSeries.slices.template.events.on("click", function(ev) {
      const slice = ev.target;
      const active = slice.get("active");

      pieSeries.slices.each(function(slice) {
        if(slice != ev.target && slice.get("active")) {
          slice.set("active", false);
        }
      });

      if (active) {
        columnSeries.columns.template.adapters.add("fill", function (fill, target) {
          return columnChart.get("colors").getIndex(columnSeries.columns.indexOf(target));
        });
        columnSeries.columns.template.adapters.add("stroke", function (stroke, target) {
          return columnChart.get("colors").getIndex(columnSeries.columns.indexOf(target));
        });
        columnSeries.data.setAll(chartData);
        yAxis.data.setAll(chartData);
      } else {
        let color = slice.get("fill");
  
        label1.setAll({
          fill: color,
          text: root.numberFormatter.format(
            slice.dataItem.get("valuePercentTotal"),
            "#.#'%'"
          ),
        });
  
        label2.set("text", slice.dataItem.get("category"));
        columnSeries.columns.template.adapters.remove("fill");
        columnSeries.columns.template.adapters.remove("stroke");
  
        columnSeries.columns.template.setAll({
          fill: slice.get("fill"),
          stroke: slice.get("fill"),
        });
  
        columnSeries.data.setAll(slice.dataItem.dataContext.breakdown);
        yAxis.data.setAll(slice.dataItem.dataContext.breakdown);
      }
    });
    
    pieSeries.labels.template.set("forceHidden", true);
    pieSeries.ticks.template.set("forceHidden", true);
 
    pieSeries.data.setAll(chartData);

    const legend = pieSeries.children.push(am5.Legend.new(root, {
      centerY: am5.percent(40),
      centerX: am5.percent(60),
      y: am5.percent(45),
      x: am5.percent(10),
      marginTop: 15,
      marginBottom: 15,
    }));
    
    legend.data.setAll(pieSeries.dataItems);
    
    // Add label
    let label1 = pieChart.seriesContainer.children.push(am5.Label.new(root, {
      text: "",
      fontSize: 35,
      fontweight: "bold",
      centerX: am5.p50,
      centerY: am5.p50
    }));
    
    let label2 = pieChart.seriesContainer.children.push(am5.Label.new(root, {
      text: "",
      fontSize: 12,
      centerX: am5.p50,
      centerY: am5.p50,
      dy: 30
    }));
  
    return () => {
      root.dispose();
    };
  }, [chartData]);

  return (
    <Box id="other-locations" style={{ width: "100%", height: "600px" }}></Box>
  );
};

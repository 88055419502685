import React from "react";

import { KeyboardArrowDown, Description, ListAlt, Download } from "@mui/icons-material";
import { Button, ListItemIcon, ListItemText, MenuItem, MenuList, Popover, Typography } from "@mui/material";
import { useGetAdditionalResourcesQuery } from "store/slices/locationSlice";

export const DownloadResources = ({ variant }) => {
  const consultationTool = useGetAdditionalResourcesQuery({ params: { portal_resource: "location-insights", name: "consultation_tool" } });
  const analysisworksheet = useGetAdditionalResourcesQuery({ params: { portal_resource: "location-insights", name: "worksheet" } });
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const open = Boolean(anchorEl);
  const id = open ? "download-resources" : undefined;
  
  return <>
    <Button
      variant="contained" 
      color="primary"
      disabled={consultationTool.isLoading || analysisworksheet.isLoading} 
      startIcon={<Download />}
      endIcon={<KeyboardArrowDown />}
      onClick={handleClick}
      sx={variant === "dark" ? { backgroundColor: "white", color: "black", ":hover": { background: "white" } } : null }
    >
        Toolkit 
    </Button>
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      sx={{ mt: 2, maxWidth: "800px" }}
    >
      <MenuList>
        <MenuItem>
          <ListItemIcon>
            <Description fontSize="small" />
          </ListItemIcon>
          <a href={consultationTool.data} target="_blank" rel="noreferrer" >
            <ListItemText>
              CONSULTATION TOOL
            </ListItemText>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
                How-to guide
            </Typography>
          </a>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <ListAlt fontSize="small" />
          </ListItemIcon>
          <a href={analysisworksheet.data} target="_blank" rel="noreferrer" >
            <ListItemText>
                ANALYSIS WORKSHEET  
            </ListItemText>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Easily capture analysis
            </Typography>
          </a>
        </MenuItem>
      </MenuList>
    </Popover>
  </>;
};

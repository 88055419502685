import * as React from "react";

import { Box, Link, Popover } from "@mui/material";

const MoreInformationPopover = ({ helpText }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <React.Fragment>
      <Link aria-describedby={id} sx={{ color: "black", cursor: "pointer" }} variant="contained" onClick={handleClick}>
        {helpText.title || "See More Detail"}
      </Link>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{ mt: 2, maxWidth: "800px" }}
      >
        <Box p={2}>
          {helpText.content}
        </Box>
      </Popover>
    </React.Fragment>
  );
};

export default MoreInformationPopover;

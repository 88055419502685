import * as React from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import dayjs from "dayjs";

const today = dayjs();

const options = [
  {
    id: 4,
    period: "All Time",
    value: "all",
  },
  {
    id: 0,
    period: "Today",
    value: today,
  },
  {
    id: 1,
    period: "Last 7 Days",
    value: today.subtract(6, "day"),
  },
  {
    id: 2,
    period: "Last Month",
    value: today.subtract(1, "month"),
  },
  {
    id: 3,
    period: "Last 12 Months",
    value: today.subtract(1, "year"),
  },
];

const DateFilter = ({ value, onChangeDate }) => {
  return (
    <FormControl sx={{ minWidth: 200 }} size="small">
      <InputLabel id="demo-select-small-label">Choose Period</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={value}
        label="Choose Period"
        onChange={(e) => onChangeDate(e.target.value)}
      >
        {options.map(option => (
          <MenuItem key={option.id} value={option.value}>
            {option.period}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DateFilter;

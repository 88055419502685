import React from "react";

import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const EmployeeListeningCreate = ({
  onCreateNew
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className="flex items-center justify-between">
      <Box>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          variant="contained"
          size="small"
        >
          Create New
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => onCreateNew("dashboard")}>Dashboard</MenuItem>
          <MenuItem onClick={() => onCreateNew("research")}>Research</MenuItem>
          <MenuItem onClick={() => onCreateNew("blog")}>Blog</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default EmployeeListeningCreate;

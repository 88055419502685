import React from "react";

import { Box } from "@mui/material";
import { useParams } from "react-router-dom";

const TableauViz = (props) => {
  const { page } = useParams();
  return (
    <Box height="calc(100vh - 160px)" width="100%" display="flex" justifyContent="center">
      <tableau-viz
        id="tableau-viz"
        src={props.val}
        width="1400"
        height={page === "flex-forward-overview" ? 1800 : 777}
        hide-tabs
        toolbar="bottom"
      ></tableau-viz>
    </Box>
  );
};

export default TableauViz;
